import Footer from "../common/footer";
import Header from "../common/header";
import Ticker from "../common/ticker";
import '../../assets/css/main.scss';
import { Tab, Col, Nav, Row, Modal } from "react-bootstrap";
import investors from '../../assets/img/investors/bg.jpg';
import missionimg from '../../assets/img/investors/mission_img.png';
import venkatraju from '../../assets/img/investors/venkat-raju.png'
import indianretailer from '../../assets/img/media/indian-retailer.png';
import travelworld from '../../assets/img/media/travel-world.png';
import yourstory from '../../assets/img/media/your-story.png';
import rightarrowyellow from '../../assets/img/right-arrow-yellow.png';
import c_1 from '../../assets/img/careers/c_1.png';
import c_2 from '../../assets/img/careers/c_2.jpg';
import c_3 from '../../assets/img/careers/c_13.jpg';
import c_4 from '../../assets/img/careers/c_4.jpg';
import c_5 from '../../assets/img/careers/c_5.png';
import c_6 from '../../assets/img/careers/c_6.jpg';
import c_7 from '../../assets/img/careers/c_7.jpg';
import c_8 from '../../assets/img/careers/c_8.jpg';
import c_9 from '../../assets/img/careers/c_9.jpg';
import c_10 from '../../assets/img/careers/c_10.jpg';
import c_11 from '../../assets/img/careers/c_11.jpg';
import c_12 from '../../assets/img/careers/c_12.jpg';
import gall_2 from '../../assets/img/careers/sciative_2024.jpg';
import bg2 from '../../assets/img/careers/bg_2.png';
import { Button } from 'react-bootstrap';
import ClientBusSlider from "../travel/clientBusSlider";
import Leadership from "../retail/leadership";
import FounderVideo from "../retail/founderVideo";
import Recoginition from "../retail/recognition";
import ProductNumber from "../retail/productnumber";
import RaTalkSingle from "../retail/ratalksingle";
import TestimonialSlider from "../retail/testimonialslider";
import TravelBusInsights from "../travel/travelBusinsights";
import SmilesAtSciative from "../common/smilesAtSciative";
import Accordion from 'react-bootstrap/Accordion';
import TravelProductNumber from "../travel/travelproductnumber";
import TestimonialSliderTravel from "../travel/testimonialslidertravel";
import CapteraAchievements from "../travel/capterra-achievvement";
import AllClientSlider from "../common/allClientSlider";
import AllRoutes from "../../AllRoutes";
import { GlobalUrl, GlobalImg } from "../../global";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../assets/css/careers-slider.scss";
import AOS from "aos";
import { useEffect, useState } from "react";
import "aos/dist/aos.css";

import { Helmet, HelmetProvider } from "react-helmet-async";
import CareersForm from "../Forms/CareersForm";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';

const Careers = ({ }) => {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])

  const [formModal, setFormModal] = useState({
    show: false,
    role: ""
  });

  const handleApplyNow = (role) => {
    setFormModal({ show: true, role: role });
  }

  const handleApiResponse = (response) => {
    toast.dismiss();
    if (response.status === "success") {
      toast.success('Form submitted successfully!');
      setFormModal({ show: false, role: "" });
    }
    else {
      if (response.data != undefined){
        toast.error(response.data)
      } else {
        toast.error('Something went wrong! Please try again.');
      }
    }
  }

  return (
    <HelmetProvider>
      <Helmet>
        <title>Careers & Job Opportunities for SaaS | Sciative</title>
        <link rel="canonical" href={`${GlobalUrl}${AllRoutes.careers}`} />
        <meta charset="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="csrf_token" content="" />
        <meta property="type" content="website" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="_token" content="" />
        <meta name="robots" content="noodp" />
        <meta name='description' content="Propel your career job opportunites with one of the world's top providers of advanced business analytics and pricing intelligence solutions. " />
        {/* <meta name='keywords' content="Sciative Careers" /> */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:title" content="Careers & Job Opportunities for SaaS | Sciative" />
        <meta property="og:description" content="Propel your career job opportunites with one of the world's top providers of advanced business analytics and pricing intelligence solutions. " />
        <meta property="og:hashtag" content="#ai" />
        <meta content="image/*" property="og:image:type" />
        <meta property="og:url" content={`${GlobalUrl}${AllRoutes.careers}`}/>
        <meta property="og:site_name" content="Sciative Solutions - We Price Right" />
        <meta property="og:image" content={GlobalImg} />
        <meta property="og:type" content="website" />
      </Helmet>

      <section className="smallBanner career_bg" id="career_page">
        <div className="container" data-aos="new-animation">

          <h1 className="about_para">Discover Your Next Career Opportunity and Unlock Your Potential With Our Diverse Range of Job Listings</h1>
          <p className="about_para_2">
          Join us and shape the future of dynamic pricing with a team of passionately driven and curious minds.
          </p>

          <div>
            {/*<button className="transparent-button">Discover more! </button> */}
            {/* <Link src="#current-openings"> */}
            <a className="yellow-button" href="#current-openings">Explore Jobs</a>
            {/* </Link> */}
          </div>



        </div>
      </section>
      <section className="img_1">
        <div className="container">
          <img src={bg2} alt="Collage" />
        </div>
      </section>
      <section className="section_video_career bus_video">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <h2 className="bigHead text-center mb-5 mt-5">
              Experience the Passionate Energy as Our Team Members Open Up About Their Inspirations and Impact
              </h2>
            <iframe src="https://www.youtube.com/embed/4nILNQT9V-g?si=XJpwhdwQdeowjBo_?controls=1&amp;mute=1&amp;loop=1&amp;playlist=4nILNQT9V-g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
          </div>
        </div>

      </section>

      <section className="career_img">
        <div className="container" data-aos="new-animation">
          <div className="row div_car" >
            <div className="col-xl-10 offset-xl-1  text-center">
              <h2 className="bigHead">
                The Sciative Culture
              </h2>
              <p>
                At Sciative, we're creating a culture where curiosity meets innovation, fostering an environment that embraces diversity in thought and approach.
              </p>
              <p>
                Our ethos is rooted in a passion for excellence, driven by the desire to develop cutting-edge AI tools that revolutionize pricing and competition intelligence. We value creativity, encourage risk-taking, and support continuous learning to stay ahead in a rapidly evolving tech landscape.
              </p>
            </div>
            {/* <div className="col-lg-6 col-xl-5 div_car_2">
              <div className="row">
                <div className="col-md-6 c_gal">
                  <img src={c_12} alt="Sciative Culture" className="c_gal_1" />
                  <img src={c_3} alt="Sciative Culture" className="c_gal_2" />
                </div>
                <div className="col-md-6 cgal2">
                  <img src={c_2} alt="Sciative Culture" />
                  <img src={c_4} alt="Sciative Culture" />
                </div>


              </div>

            </div> */}
          </div>
        </div>
      </section>
      <section className="investor_edge">
        <div className="container" data-aos="new-animation">
          <h2 className="bigHead text-center mb-5">The Sciative Edge</h2>
          <Tab.Container defaultActiveKey="first">
            <Row>
              <Col xl={5}>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="first">Quest for Excellence

                      <p>We will be passionate about achieving the highest standards of quality, always promoting meritocracy.</p>
                      <div className="investor_edge_div res_img">
                        <img src={c_6} alt="Quest for Excellence" />
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">Customer Success
                      <p>We will always keep the customer at the heart of the business and deliver quality and extraordinary experience.</p>
                      <div className="investor_edge_div res_img">
                        <img src={c_7} alt="Customer Success" />
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="third">Integrity
                      <p>We will be fair, honest, transparent and ethical in our conduct; everything we do must stand the test of public scrutiny.</p>
                      <div className="investor_edge_div res_img">
                        <img src={c_8} alt="Customer Success" />
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="fourth">Respect for Work and Peers
                      <p>We will treat all work, clients and team members with courtesy, politeness and kindness.</p>
                      <div className="investor_edge_div res_img">
                        <img src={c_10} alt="Customer Success" />
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="fifth">Sense of Urgency
                      <p>To understand problems, deliver solutions and add value.</p>
                      <div className="investor_edge_div res_img">
                        <img src={c_11} alt="Customer Success" />
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col xl={7} className="second_div">
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <div className="investor_edge_div">
                      <img src={c_6} alt="Quest for Excellence" />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <div className="investor_edge_div">
                      <img src={c_7} alt="Customer Success" />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    <div className="investor_edge_div">
                      <img src={c_8} alt="Integrity" />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="fourth">
                    <div className="investor_edge_div">
                      <img src={c_10} alt="Respect for Work and Peers" />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="fifth">
                    <div className="investor_edge_div">
                      <img src={c_11} alt="Sense of Urgency" />
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </section>
      {/* <section className="slider" data-aos="fade-up" data-aos-duration="400" data-aos-offset="150" data-aos-easing="ease-in-sine">
        <h2 className="bigHead text-center mb-5">Life @ Sciative</h2>
        <section className=" d-flex justify-content-center">
          <div class="slider-section  d-flex justify-content-center">
            <CarrerSlider />
          </div>
        </section>
      </section> */}

      <SmilesAtSciative />


      <section className="openings" id="current-openings" >
        <div className="container" data-aos="new-animation">
          <h2 className="bigHead text-center mb-5">Current Openings</h2>
          <Accordion >
            <Accordion.Item eventKey="0">
              <Accordion.Header>Sr. Software Development Engineer (SDE-III) - AI SaaS Products</Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  <div className="col-md-9 col-xl-10">
                    <p>The primary role of Sr. Software Development Engineer (SDE-III) will be to carry out a variety software/web application development activities to support internal and external projects, including but not limited to:

                    </p>
                    <h3 className="job_subhead">
                      Job Responsibilities
                    </h3>
                    <ul>
                      <li>Hands on (Full Stack) Web application design and development concentrating on Artificial intelligence using big data technologies.</li>
                      <li>Facilitate solution efficiencies, scalability and technology stack leadership.</li>
                      <li>Ensure fool proof and robust applications through unit tests and other quality control measures. Follow an agile development process, and enable rapid solutions to business challenges.</li>
                      <li>Take inputs from internal and external clients and constantly strive to improve solutions.</li>
                      <li>Setup and following software design, development, testing and documentation best practices.</li>
                      <li>Extract and parse data from online and local data sources; Clean up data, audit data for accuracy, consistency and completeness.</li>
                      <li>Apply analytics and artificial intelligence techniques and extract valuable and actionable insights. Data processing and visualization: Summarize insights in simple yet powerful charts, reports, slides, etc.</li>
                      <li>For internal and external client projects, use our proprietary tools for performing data engineering, analytics and visualization activities. Responsible for project deliveries, escalation, continuous improvement, and customer success.</li>

                    </ul>
                    <h3 className="job_subhead">
                    Candidate Profile
                    </h3>
                    <ul>
                     <li>Strong knowledge of Python, Django, React, Flask, Flutter, Bootstrap, HTML,CSS, Javascript, SQL.</li>
                     <li>Expertise in Full Stack Web Application development is required.</li>
                     <li>Proficiency in software development: Conceptualizing, Designing, Coding, debugging.</li>
                     <li>Prior Background in AI, ML, Deep Learning, Image processing and computer vision is preferred.</li>
                     <li>Strong in algorithm design, database programming (RDBMS), and text analytics.</li>
                     <li>Knowledge of MongoDB, Redis, Docker, Containerization, Kubernetes, AWS, Kafka is preferred.</li>
                     <li>High problem-solving skills: able to logically breakdown problems into incremental milestones,
prioritize high impact deliverables first, identify bottlenecks and work around them.</li>
                     <li>Self-learner: highly curious, self-starter and can work with minimum supervision and
guidance.</li>
                     <li>Entrepreneurial mind set with a positive attitude is a must.</li>
                     <li>Track record of excellence in academics or non-academic areas, with significant
accomplishments.</li>

                    </ul>
                    <h3 className="job_subhead">Qualifications</h3>
                    <ul>
                      <li>B.E / B.Tech in Computer Science / IT</li>
                      <li>3 to 6 years of relevant experience</li>
                    </ul>
                  </div>
                  <div className="col-md-3 col-xl-2">
                    <Link to="/learning-materials/software-developer" target="_blank">
                      <button className="white-button mb-2">Learning Materials</button>
                    </Link>
                    <Link to="https://apti.sciative.com/practice_test/" target="_blank">
                      <button className="white-button mb-2">Test Your Readiness</button>
                    </Link>
                    <div className="btn_div">
                      <button onClick={() => handleApplyNow("Sr. Software Development Engineer (SDE-III) - AI SaaS Products")} className="yellow-button">Apply Now </button>
                    </div>
                  </div>
                </div>


              </Accordion.Body>
            </Accordion.Item>
            {/*  */}
        
            <Accordion.Item eventKey="2">
              <Accordion.Header>Jr. Software Developer</Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  <div className="col-md-9 col-xl-10">
                    <p>The primary role of Jr. Software Developer will be to carry out a variety software/web application development activities to support internal and external projects, including but not limited to:

                    </p>
                    <h3 className="job_subhead">
                      Job Responsibilities
                    </h3>

                    <ul>
                      <li>Facilitate solution efficiencies, scalability and technology stack leadership.</li>
                      <li>Ensure fool proof and robust applications through unit tests and other quality control measures.</li>
                      <li>Follow an agile development process, and enable rapid solutioning to business challenges.</li>
                      <li>Take inputs from internal and external clients and constantly strive to improve solutions.</li>
                      <li>Follow software design, development, testing and documentation best practices.</li>
                      <li>Data engineering: Extract and parse data from online and local data sources; Clean up data, audit data for accuracy, consistency and completeness.</li>
                     
                      <li>Data processing and visualization: Summarize insights in a simple yet powerful charts, reports, slides, etc.</li>
                      <li>Data storage and management: MySQL (AWS RDS) MongoDB.</li>

                      <li>Application frameworks: React, React native, Django.</li>
                      <li>Data Integration technologies: RESTful APIs, AWS S3 and UI data uploads.</li>
                      <li>Project operations: For internal and external client projects, use our proprietary tools for performing data engineering, analytics and visualization activities.</li>
                      <li>Responsible for project deliveries, escalation, continuous improvement, and customer success.</li>
                      <li>Modify software to fix errors, adapt it to new hardware, improve its performance, or upgrade interfaces.</li>
                      <li>Follow the system testing and validation procedures.</li>


                    </ul>
                    <h3 className="job_subhead">Qualifications</h3>
                    <ul>
                      <li>Toppers and Tech Geeks who are BTech / BE in Computer Science/Engineering.</li>
                      <li>0 to 3 years work experience</li>
                    </ul>
                  </div>
                  {/* col-9 end */}
                  <div className="col-md-3 col-xl-2">
                    <Link to="/learning-materials/software-developer" target="_blank">
                      <button className="white-button mb-2">Learning Materials</button>
                    </Link>
                    {/* <Link to="https://apti.sciative.com/practice_test/" target="_blank">
                      <button className="white-button mb-2">Test Your Readiness</button>
                    </Link> */}
                    <div className="btn_div">
                      <button onClick={() => handleApplyNow("Jr. Software Developer")} className="yellow-button">Apply Now </button>

                    </div>
                  </div>
                  {/* col-3 end */}
                </div>


              </Accordion.Body>
            </Accordion.Item>
            
            <Accordion.Item eventKey="3">
              <Accordion.Header>HR Associate</Accordion.Header>
              <Accordion.Body>
              <div className="row">
                <div className="col-md-9 col-xl-10">
                  <p>
                    As an HR Associate you will be a hands-on professional supporting the HR function, in executing and
                    developing day to day operations associated with HR practices and processes across all functional
                    areas including recruiting, on-boarding, training and development, employee engagement, employee
                    relations, performance management, compensations and benefits and internal communication. The
                    role is critical in executing our people initiatives, providing great internal customer support and driving
                    HR functional excellence and process improvement. Ultimately, you will ensure our company attracts,
                    hires, develops and retails qualified employees.
                  </p>
                  <h3 className="job_subhead">
                    Job Responsibilities
                  </h3>
                  <ul>
                    <li>
                      Build a robust organization: You will work alongside the HR Manager for end-to-end
                      recruitment process for junior, mid and senior level leadership team members in both Tech
                      and Business roles.
                    </li>
                    <li>
                      Understand organizational needs: You will support building and driving processes, policies,
                      and workflows that are compliant, efficient, scalable, and reflect Sciative values.
                    </li>
                    <li>
                      Design and define relevant content for Job Descriptions with close collaboration with the
                      hiring managers and roll-out the job descriptions on hiring portals
                    </li>
                    <li>
                      Support employee life cycle: Strategize, reinvent, and execute on-boarding, performance
                      management , workforce planning and development and off boarding.
                    </li>
                    <li>
                      Support set-up of HR Technology stack: Research and identify the right technology vendors to
                      implement best in class HR tech tools, keep HR tools up to date and organized, including
                      during on-boarding and off-boarding.
                    </li>
                  </ul>
                  <h3 className="job_subhead">
                    Candidate Profile
                  </h3>
                  <ul>
                    <li>
                    0-2 years as an HR professional with people and technology .
                    </li>
                    <li>
                    Willing to work in highly agile and evolving startup environment and drive complete HR
                    revolution with the HR Manager.
                    </li>
                    <li>
                    Strong working knowledge of all HR functional area primarily - recruiting, on-boarding, training
and development, employee relations, performance management, compensation and
benefits.
                    </li>
                    <li>Excellent persuasion and motivational skills.</li>
                  </ul>
                </div>
                <div className="col-md-3 col-xl-2">
                    {/* <Link to="/learning-materials/full-stack-developer" target="_blank">
                      <button className="white-button mb-2">Learning Materials</button>
                    </Link> */}
                    {/* <Link to="https://apti.sciative.com/practice_test/" target="_blank">
                      <button className="white-button mb-2">Test Your Readiness</button>
                    </Link> */}
                    <div className="btn_div">
                      <button onClick={() => handleApplyNow("HR Associate")} className="yellow-button">Apply Now </button>
                    </div>
                  </div>
              </div>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4">
              <Accordion.Header>Jr. Full Stack Developer</Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  <div className="col-md-9 col-xl-10">
                    <p>The primary role of Jr. Full Stack Developer will be to carry out a variety software/web application development activities to support internal and external projects, including but not limited to:

                    </p>
                    <h3 className="job_subhead">
                      Job Responsibilities
                    </h3>

                    <ul>
                     <li>Hands on (Full Stack) SaaS application design and development concentrating on Artificial
intelligence using big data technologies.</li>
                     <li>Facilitate solution efficiencies, scalability and technology stack leadership.</li>
                     
                     <li>Ensure fool proof and robust applications through unit tests and other quality control
measures. Follow an agile development process, and enable rapid solutions to business
challenges.</li>
                     
                     <li>Take inputs from internal and external clients and constantly strive to improve solutions.</li>
                     
                     <li>Setup and following software design, development, testing and documentation best practices.</li>
                     
                     <li>Extract and parse data from online and local data sources; Clean up data, audit data for
accuracy, consistency and completeness.</li>
                     
                     <li>Apply analytics and artificial intelligence techniques and extract valuable and actionable
insights. Data processing and visualization: Summarize insights in simple yet powerful charts,
reports, slides, etc.</li>
                     <li>Use our proprietary tools for performing data engineering, analytics and visualization
activities. Responsible for project deliveries, escalation, continuous improvement, and
customer success.</li>
                     


                    </ul>
                    <h3 className="job_subhead">
                    Candidate Profile
                    </h3>
                    <ul>
                      <li>Strong knowledge of Python, Django, React, Flask, Flutter, Bootstrap, HTML,CSS, Javascript,
SQL.</li>
                      <li>Expertise in Full Stack Web Application development is required.</li>
                      <li>Proficiency in software development: Conceptualizing, Designing, Coding, debugging.</li>
                      <li>Prior Background in AI, ML, Deep Learning, Image processing and computer vision is
preferred.</li>
                      <li>Strong in algorithm design, database programming (RDBMS), and text analytics.</li>
                      <li>Knowledge of MongoDB, Redis, Docker, Containerization, Kubernetes, Devops, AWS, Kafka
is preferred.</li>
                      <li>High problem-solving skills: able to logically break down problems into incremental
milestones, prioritize high impact deliverables first, identify bottlenecks and work around
them.</li>
                      <li>Self-learner: highly curious, self-starter and can work with minimum supervision and
guidance.</li>
                      <li>Entrepreneurial mind set with a positive attitude is a must.</li>
                      <li>Track record of excellence in academics or non-academic areas, with significant
accomplishments.</li>
                   
                      
                    </ul>
                    <h3 className="job_subhead">Qualifications</h3>
                    <ul>
                      <li>B.E / B.Tech in Computer Science / IT</li>
                      <li>0 to 3 years of relevant experience</li>
                    </ul>
                  </div>
                  {/* col-9 end */}
                  <div className="col-md-3 col-xl-2">
                    <Link to="/learning-materials/full-stack-developer" target="_blank">
                      <button className="white-button mb-2">Learning Materials</button>
                    </Link>
                    {/* <Link to="https://apti.sciative.com/practice_test/" target="_blank">
                      <button className="white-button mb-2">Test Your Readiness</button>
                    </Link> */}
                    <div className="btn_div">
                      <button onClick={() => handleApplyNow("Jr. Full Stack Developer")} className="yellow-button">Apply Now </button>
                    </div>
                  </div>
                  {/* col-3 end */}
                </div>


              </Accordion.Body>
            </Accordion.Item>
        
            <Accordion.Item eventKey="5">

              <Accordion.Header>Product Analyst</Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  <div className="col-md-9 col-xl-10">
                    <p>As a Product Analyst, you will play a crucial role in understanding and gathering business requirements from our clients and effectively communicating them to our technical team for successful implementation. The Product Analyst will be responsible for conducting market research, analysing product performance data, and providing valuable insights to support our product development and management efforts.

                    </p>
                    <h3 className="job_subhead">
                      Job Responsibilities
                    </h3>

                    <ul>
                      <li>Collaborate with clients and internal stakeholders to understand business objectives, product vision, and user needs for our products.</li>
                      <li>Cooperate with multi-disciplinary teams including development, QA and business teams to identify issues, potential challenges and bottlenecks in the product to address and resolve them promptly.</li>
                      <li>Maintain accurate and up-to-date project documentation, including project plans, progress reports, meeting minutes, product requirements, user stories, use cases, and change requests.</li>
                      <li>Conduct thorough reviews and validation of technical deliverables to ensure they align with the specified requirements and meet quality standards.</li>
                      <li>Work as part of the product team to support delivery of our product requirements and contribute to long-term product roadmaps based on data-driven insights, business goals, and customer needs.</li>
                      <li>You will work closely with Project Manager during end-to-end project execution working on project plans, dependencies, risks, issues management and tracking benefits realization.</li>

                    </ul>
                    <h3 className="job_subhead">
                      Qualifications & Experience
                    </h3>

                    <ul>
                      <li>Minimum 2-5 years of proven experience in product analysis or similar roles, preferably in the software and IT industry.</li>
                      <li>Strong understanding of business analysis principles, methodologies, and practices.</li>
                      <li>Proficiency in eliciting and documenting business requirements and technical specifications.</li>
                      <li>Strong analytical and problem-solving skills, with a keen attention to detail, emphasis on accuracy and quality.</li>
                      <li>Excellent communication skills, both verbal and written, with the ability to articulate complex technical concepts to non-technical stakeholders.</li>

                    </ul>

                  </div>
                  {/* col-9 end */}
                  <div className="col-md-3 col-xl-2">
                    <Link to="/learning-materials/product-analyst" target="_blank">
                      <button className="white-button mb-2">Learning Materials</button>
                    </Link>
                    {/* <Link to="https://apti.sciative.com/practice_test/" target="_blank">
                      <button className="white-button mb-2">Test Your Readiness</button>
                    </Link> */}
                    <div className="btn_div">
                      <button onClick={() => handleApplyNow("Product Analyst")} className="yellow-button">Apply Now </button>
                    </div>
                  </div>
                  {/* col-3 end */}
                </div>


              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>Digital Marketing Specialist (AI SaaS Products - Dynamic Pricing)</Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  <div className="col-md-9 col-xl-10">
                    <p>As a Digital Marketing Specialist, you will be responsible for designing and executing comprehensive digital marketing strategies to promote our AI dynamic pricing product in the travel, retail, and entertainment verticals. Your primary focus will be to build brand awareness, generate customer interest in the form of inbound leads, through leveraging marketing automation, social media marketing, email marketing, website, SEO and SEM, and other relevant channels.

                    </p>
                    <h3 className="job_subhead">
                      Job Responsibilities
                    </h3>

                    <ul>
                      <li>Develop and execute digital marketing strategies to drive brand awareness, lead generation, and customer acquisition for our B2B SaaS products.</li>
                      <li>Plan, create, and manage engaging content for various digital platforms, including LinkedIn, Facebook, Twitter, Instagram, and other relevant social media channels.</li>
                      <li>Build a strong content-lead inbound leads funnel, by creative and effective use of SEO, relevant blog and whitepaper posts on website, inbound links through blogsites such as medium.com, etc.</li>
                      <li>Apply performance marketing strategies, and manage and optimize paid advertising campaigns on Search Engines (SEM), and Social media platforms like LinkedIn, Facebook, Twitter, Instagram and others to reach target audiences and achieve campaign objectives.</li>
                      <li>Execute email marketing campaigns, including creating compelling email content, managing subscriber lists, and monitoring campaign effectiveness.</li>
                      <li>Collaborate with cross-functional teams, including sales, product, and design, to align marketing efforts with overall business goals and ensure consistent messaging across all channels.</li>
                      <li>Stay up-to-date with the latest digital marketing trends, tools, and best practices, and make recommendations for incorporating new techniques into marketing strategies.</li>
                      <li>Conduct market research and competitor analysis to identify new opportunities and potential areas for growth.</li>
                      <li>Analyze our website traffic and user engagement, and build data driven strategies to improve discovery, engagement, and conversions.</li>
                      <li>Track and report on key performance indicators (KPIs) to evaluate the effectiveness of digital marketing initiatives and provide regular reports to management.</li>
                      <li>Work closely with internal and external stakeholders, including design and content teams, to create compelling marketing materials and collateral.</li>

                    </ul>
                    <h3 className="job_subhead">
                      Qualifications
                    </h3>

                    <ul>
                      <li>Bachelor’s degree in Marketing, Business, or a related field. A relevant master’s degree is a plus.</li>
                      <li>Proven work experience as a Digital Marketing related role or a similar role within the B2B SaaS industry, preferably in the travel, retail, or entertainment sectors.</li>
                      <li>Strong understanding of marketing automation platforms and tools, with experience in creating and implementing lead generation and nurturing campaigns.</li>
                      <li>Proficient in using LinkedIn for B2B lead generation and social media marketing.</li>
                      <li>Solid knowledge of email marketing best practices, segmentation, and automation.</li>
                      <li>Excellent written and verbal communication skills, with the ability to create compelling content for different channels and target audiences.</li>
                      <li>Experience in managing and optimizing blogs and website content.</li>
                      <li>Strong analytical skills and the ability to use data to measure campaign performance, identify trends, and make data-driven decisions.</li>
                      <li>Familiarity with SEO, SEM, and SMM best practices and tools for optimizing website traffic and performance.</li>
                      <li>Ability to work in a fast-paced, deadline-driven environment with excellent project management and organizational skills.</li>

                    </ul>

                  </div>
                  {/* col-9 end */}
                  <div className="col-md-3 col-xl-2">
                    <Link to="/learning-materials/marketing" target="_blank">
                      <button className="white-button mb-2">Learning Materials</button>
                    </Link>
                    {/* <Link to="https://apti.sciative.com/practice_test/" target="_blank">
                      <button className="white-button mb-2">Test Your Readiness</button>
                    </Link> */}
                    <div className="btn_div">
                      <button onClick={() => handleApplyNow("Digital Marketing Specialist (AI SaaS Products - Dynamic Pricing)")} className="yellow-button">Apply Now </button>
                    </div>
                  </div>
                  {/* col-3 end */}
                </div>


              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header>Content Writing Specialist</Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  <div className="col-md-9 col-xl-10">
                    <p>We are looking for a talented and experienced Content Writer to join our team. The ideal candidate will have a minimum of 1 year of professional experience in content writing and a passion for creating engaging and compelling written content. The role will involve writing blogs, branding emails, LinkedIn posts, and coordinating content production. Additionally, proficiency in using ChatGPT creatively and effectively is highly desirable.
                    </p>
                    <h3 className="job_subhead">
                      Job Responsibilities
                    </h3>

                    <ul>
                      <li>Create high-quality and engaging content for various platforms, including blogs, articles, whitepapers, social media posts, and website copy. Knowledge and prior experience in SEO will be a plus.</li>
                      <li>Develop a deep understanding of our brand voice, target audience, and industry to create content that aligns with our brand and resonates with our audience.</li>
                      <li>Write compelling branding emails, SMS or WhatsApp marketing collateral that effectively communicate our message and drive engagement with our subscribers and customers.</li>
                      <li>Craft engaging LinkedIn posts to promote our brand, products, and thought leadership in the industry.</li>
                      <li>Collaborate with the marketing team and subject matter experts to research and gather information for content creation.</li>
                      <li>Conduct thorough research on industry trends, competitor activities, and relevant topics to ensure our content stays fresh, informative, and valuable to our audience.</li>
                      <li>Coordinate content production, including collaborating with designers, developers, and other team members to ensure timely delivery of content assets.</li>
                      <li>Edit and proofread content to ensure accuracy, clarity, grammar, and adherence to brand guidelines.</li>
                      <li>Utilize ChatGPT creatively and effectively to enhance content creation and brainstorm new ideas for engaging and innovative content.</li>
                    </ul>
                    <h3 className="job_subhead">
                      Qualifications
                    </h3>

                    <ul>
                      <li>Minimum of 1 year of professional experience in content writing, preferably in a digital marketing or similar role.</li>
                      <li>Proven ability to write clear, concise, and engaging content for various platforms and target audiences.</li>
                      <li>Excellent written and verbal communication skills with a strong command of grammar and attention to detail.</li>
                      <li>Familiarity with SEO principles and best practices for optimizing content for search engines.</li>
                      <li>Experience in writing compelling branding emails that drive engagement and conversion.</li>
                      <li>Proficiency in crafting effective LinkedIn posts to promote brand awareness and thought leadership.</li>
                      <li>Strong coordination skills to work effectively with cross-functional teams and meet content production deadlines.</li>
                      <li>Creative thinking and the ability to generate unique and innovative content ideas.</li>
                      <li>Experience in using ChatGPT or similar AI language models to enhance content creation is highly desirable.</li>
                      <li>Bachelor’s degree in English, Journalism, Communications, or a related field is preferred, but not compulsory.</li>

                    </ul>

                  </div>
                  {/* col-9 end */}
                  <div className="col-md-3 col-xl-2">
                    {/* <Link to="/learning-materials/content" target="_blank">
                      <button className="white-button mb-2">Learning Materials</button>
                    </Link> */}
                    {/* <Link to="https://apti.sciative.com/practice_test/" target="_blank">
                      <button className="white-button mb-2">Test Your Readiness</button>
                    </Link> */}
                    <div className="btn_div">
                      <button onClick={() => handleApplyNow("Content Writing Specialist")} className="yellow-button">Apply Now </button>
                    </div>
                  </div>
                  {/* col-3 end */}
                </div>


              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header>Sales Development Representatives</Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  <div className="col-md-9 col-xl-10">
                    <p>We are seeking highly motivated and results-driven Sales Development Representatives to join our dynamic sales team. The ideal candidate will have a strong background in customer, market, and competition research, as well as experience in lead nurturing and utilizing CRM tools to manage and track interactions. The role will involve identifying decision makers, reaching out to them through various channels including LinkedIn, phone calls, WhatsApp messages, and emails, and build an interest for our products and services.
                    </p>
                    <h3 className="job_subhead">
                      Job Responsibilities
                    </h3>

                    <ul>
                      <li>Conduct thorough customer, market, and competition research to identify potential prospects and generate leads.</li>
                      <li>Build and maintain a robust pipeline of leads by effectively nurturing relationships through multiple channels, including LinkedIn, phone calls, WhatsApp messages, and emails.</li>
                      <li>Utilize CRM tools to manage and track customer interactions, update lead status, and maintain accurate records of sales activities and progress.</li>
                      <li>Identify decision makers within target organizations and develop targeted outreach strategies to engage with them.</li>
                      <li>Reach out to decision makers through LinkedIn connections, phone calls, WhatsApp messages, and emails, effectively communicating the value proposition of our products and services.</li>
                      <li>Collaborate with the inside sales team to hand over warm leads, providing them with comprehensive information to facilitate a smooth transition.</li>
                      <li>Prepare and deliver persuasive sales presentations, proposals, and demonstrations to potential clients, addressing their specific needs and pain points.</li>
                      <li>Negotiate and close sales deals, ensuring customer satisfaction and achieving revenue targets.</li>
                      <li>Stay updated with industry trends, market developments, and competitor activities to identify new business opportunities and maintain a competitive edge.</li>
                      <li>Conduct cold calling campaigns when necessary to generate new leads and expand the customer base.</li>

                    </ul>
                    <h3 className="job_subhead">
                      Qualifications
                    </h3>

                    <ul>
                      <li>Proven track record of success in B2B sales, with a minimum of 1 year of experience in a similar role.</li>
                      <li>Strong research skills to identify potential customers, conduct market analysis, and stay informed about industry trends and competitor activities.</li>
                      <li>Experience in lead nurturing, with the ability to build strong relationships and move leads through the sales funnel.</li>
                      <li>Proficiency in using CRM tools to manage and track customer interactions, as well as to generate reports and analyze data.</li>
                      <li>Excellent communication and interpersonal skills, with the ability to engage with decision makers and articulate the value of our products and services effectively.</li>
                      <li>Results-driven mindset with a strong drive to achieve and exceed sales targets.</li>
                      <li>Ability to work independently and collaborate effectively with cross-functional teams.</li>
                      <li>Familiarity with cold calling techniques and the ability to handle objections and overcome challenges.</li>
                      <li>Strong networking skills with the ability to leverage LinkedIn and other platforms to connect with decision makers.</li>
                      <li>Bachelor’s degree in Business, Marketing, or a related field is preferred, but not compulsory.</li>
                    </ul>

                  </div>
                  {/* col-9 end */}
                  <div className="col-md-3 col-xl-2">
                    {/* <Link to="/learning-materials/sales" target="_blank">
                      <button className="white-button mb-2">Learning Materials</button>
                    </Link> */}
                    {/* <Link to="https://apti.sciative.com/practice_test/" target="_blank">
                      <button className="white-button mb-2">Test Your Readiness</button>
                    </Link> */}
                    <div className="btn_div">
                      <button onClick={() => handleApplyNow("Sales Development Representatives")} className="yellow-button">Apply Now </button>

                    </div>
                  </div>
                  {/* col-3 end */}
                </div>


              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
              <Accordion.Header>Marketing Manager (AI SaaS Products - Dynamic Pricing)</Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  <div className="col-md-9 col-xl-10">
                    <p>We are seeking a talented and experienced Marketing Manager to join our team. As a Marketing Manager for our AI SaaS products, you will be responsible for overseeing branding communications and implementing various marketing strategies, including digital marketing, email marketing, social media marketing, LinkedIn marketing, website marketing, SEO, SEM, and more. Your role will be instrumental in driving brand awareness, generating leads, and achieving marketing objectives for our innovative AI-driven pricing solutions.
                    </p>
                    <h3 className="job_subhead">
                      Job Responsibilities
                    </h3>

                    <ul>
                      <li>Develop and execute comprehensive marketing strategies to promote our AI SaaS products for dynamic pricing, ensuring brand consistency and market positioning.</li>
                      <li>Oversee and coordinate branding communications, including messaging, visual identity, and tone of voice, to create a compelling and cohesive brand image.</li>
                      <li>Plan, execute, and optimize digital marketing campaigns across various channels, including email marketing, social media marketing, LinkedIn marketing, and website marketing.</li>
                      <li>Collaborate with the content team to create engaging and informative content that resonates with our target audience, including blog posts, whitepapers, case studies, and more.</li>
                      <li>Implement effective SEO strategies to improve organic search rankings and increase website traffic, ensuring visibility and discoverability of our products and solutions.</li>
                      <li>Manage paid advertising campaigns, including search engine marketing (SEM), display advertising, and retargeting, to drive qualified traffic and generate leads.</li>
                      <li>Monitor and analyze marketing metrics, including website traffic, conversion rates, and campaign performance, to make data-driven decisions and optimize marketing initiatives.</li>
                      <li>Collaborate with the sales team to develop marketing collateral, sales enablement materials, and presentations that effectively communicate the value proposition of our AI SaaS products.</li>
                      <li>Stay up-to-date with industry trends, competitive landscape, and emerging technologies in AI and dynamic pricing, incorporating relevant insights into marketing strategies.</li>
                      <li>Manage and build relationships with external partners, agencies, and vendors to support marketing initiatives and ensure effective execution of campaigns.</li>

                    </ul>
                    <h3 className="job_subhead">
                      Qualifications
                    </h3>

                    <ul>
                      <li>Proven experience as a Marketing Manager, preferably in the AI or SaaS industry, with a strong focus on digital marketing and branding communications.
                      </li>
                      <li>In-depth knowledge of marketing strategies and tactics, including email marketing, social media marketing, LinkedIn marketing, website marketing, SEO, and SEM.</li>
                      <li>Familiarity with AI concepts and technologies, particularly in the field of dynamic pricing, is highly desirable.</li>
                      <li>Demonstrated ability to develop and execute successful marketing campaigns, driving brand awareness, lead generation, and customer acquisition.</li>
                      <li>Strong analytical skills with the ability to interpret data, identify trends, and make data-driven decisions to optimize marketing efforts.</li>
                      <li>Proficiency in using marketing automation tools, CRM systems, and digital analytics platforms.</li>
                      <li>Excellent written and verbal communication skills, with the ability to create compelling content and effectively communicate ideas.</li>
                      <li>Strong project management skills, with the ability to prioritize tasks, meet deadlines, and manage multiple projects simultaneously.</li>
                      <li>Creative mindset with the ability to think strategically and generate innovative marketing ideas.</li>
                      <li>Master’s degree in Marketing, Business Administration, or a related field. Additional certifications in digital marketing or AI would be a plus.</li>
                    </ul>

                  </div>
                  {/* col-9 end */}
                  <div className="col-md-3 col-xl-2">
                    {/* <Link to="/learning-materials/marketing" target="_blank">
                      <button className="white-button mb-2">Learning Materials</button>
                    </Link> */}
                    {/* <Link to="https://apti.sciative.com/practice_test/" target="_blank">
                      <button className="white-button mb-2">Test Your Readiness</button>
                    </Link> */}
                    <div className="btn_div">
                      <button onClick={() => handleApplyNow("Marketing Manager (AI SaaS Products - Dynamic Pricing)")} className="yellow-button">Apply Now </button>
                    </div>

                  </div>
                  {/* col-3 end */}
                </div>


              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="10">
              <Accordion.Header>Marketing Analyst (Lead Generation)</Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  <div className="col-md-9 col-xl-10">
                    <p>We are seeking a highly motivated and results-driven Marketing Analyst (Lead Generation) to join our dynamic team. The ideal candidate will have a strong background in customer, market, and competition research, as well as experience in lead nurturing and utilizing CRM tools to manage and track interactions. The role will involve identifying decision makers, reaching out to them through various channels including LinkedIn, phone calls, WhatsApp messages, and emails, and building an interest for our products and services.
                    </p>
                    <h3 className="job_subhead">
                      Job Responsibilities
                    </h3>

                    <ul>
                      <li>Conduct thorough customer, market, and competition research to identify potential prospects and generate leads.</li>
                      <li>Build and maintain a robust pipeline of leads by effectively nurturing relationships through multiple channels, including LinkedIn, phone calls, WhatsApp messages, and emails.</li>
                      <li>Utilize CRM tools to manage and track customer interactions, update lead status, and maintain accurate records of daily activities and progress.</li>
                      <li>Identify decision makers within target organizations and develop targeted outreach strategies to engage with them.</li>
                      <li>Reach out to decision makers through LinkedIn connections, phone calls, WhatsApp messages, and emails, effectively communicating the value proposition of our products and services.</li>
                      <li>Collaborate with the inside sales team to hand over warm leads, providing them with comprehensive information to facilitate a smooth transition.</li>
                      <li>Prepare and deliver persuasive sales presentations, proposals, and demonstrations to potential clients, addressing their specific needs and pain points.</li>
                      <li>Negotiate and close marketing deals, ensuring customer satisfaction and achieving revenue targets.</li>
                      <li>Stay updated with industry trends, market developments, and competitor activities to identify new business opportunities and maintain a competitive edge.</li>


                    </ul>
                    <h3 className="job_subhead">
                      Qualifications
                    </h3>

                    <ul>
                      <li>Strong research skills to identify potential customers, conduct market analysis, and stay informed about industry trends and competitor activities.</li>
                      <li>Skill in lead nurturing, with the ability to build strong relationships and move leads through the marketing funnel.</li>
                      <li>Excellent communication and interpersonal skills, with the ability to engage with decision makers and articulate the value of our products and services effectively.</li>
                      <li>Results-driven mindset with a strong drive to achieve and exceed targets.</li>
                      <li>Ability to work independently and collaborate effectively with cross-functional teams.</li>
                      <li>Familiarity with cold calling techniques and the ability to handle objections and overcome challenges.</li>
                      <li>Strong networking skills with the ability to leverage LinkedIn and other platforms to connect with decision makers.</li>
                      <li>Bachelor’s degree in Business, Marketing, or a related field is preferred, but not compulsory.</li>

                    </ul>

                  </div>
                  {/* col-9 end */}
                  <div className="col-md-3 col-xl-2">
                    {/* <Link to="/learning-materials/marketing" target="_blank">
                      <button className="white-button mb-2">Learning Materials</button>
                    </Link> */}
                    {/* <Link to="https://apti.sciative.com/practice_test/" target="_blank">
                      <button className="white-button mb-2">Test Your Readiness</button>
                    </Link> */}
                    <div className="btn_div">
                      <button onClick={() => handleApplyNow("Marketing Analyst (Lead Generation)")} className="yellow-button">Apply Now </button>
                    </div>
                  </div>
                  {/* col-3 end */}
                </div>

              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="11">
              <Accordion.Header>Business Development Manager (AI SaaS Company - AI Dynamic Pricing)</Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  <div className="col-md-9 col-xl-10">
                    <p>As a Business Development Manager, you will be responsible for managing and leading a high-performing inside sales team. Your primary focus will be on driving sales, closing deals, and meeting or exceeding sales targets. You will be responsible for value selling, educating customers about our AI dynamic pricing solution, delivering presentations and product demonstrations, and collaborating closely with marketing and senior leadership to achieve sales objectives.
                    </p>
                    <h3 className="job_subhead">
                      Job Responsibilities
                    </h3>

                    <ul>
                      <li>Lead, mentor, and manage a team of inside sales representatives, setting clear goals, providing guidance, and monitoring performance.</li>
                      <li>Develop and execute inside sales strategies to generate new leads, drive sales, and meet or exceed revenue targets.</li>
                      <li>Implement a value selling approach, ensuring the inside sales team effectively communicates the value proposition and benefits of our AI dynamic pricing solution to potential customers.</li>
                      <li>Collaborate with marketing to develop sales enablement materials, including sales scripts, presentations, product collateral, and competitive analysis.</li>
                      <li>Drive the inside sales team to proactively reach out to prospects, conduct product demonstrations, deliver presentations, and close deals.</li>
                      <li>Work closely with senior leadership to align sales objectives with overall business goals and strategies.</li>
                      <li>Monitor and analyze sales metrics, pipeline management, and conversion rates to identify areas for improvement and drive sales effectiveness.</li>
                      <li>Build and maintain strong relationships with key decision-makers and stakeholders in the travel, retail, and entertainment industries.</li>
                      <li>Stay informed about industry trends, competitor activities, and market dynamics to effectively position our solution and differentiate it from competitors.</li>
                      <li>Provide regular sales forecasts, reports, and updates to senior leadership.</li>

                    </ul>
                    <h3 className="job_subhead">
                      Qualifications
                    </h3>

                    <ul>
                      <li>Bachelor’s degree in Business, Sales, Marketing, or a related field. A relevant master’s degree is a plus.</li>
                      <li>Proven experience in inside sales roles, preferably in the B2B SaaS industry, with a focus on selling complex solutions to enterprise clients</li>
                      <li>Prior experience in a sales management or team leadership role.</li>
                      <li>Strong knowledge of value selling techniques and the ability to effectively communicate the value proposition and benefits of a software solution.</li>
                      <li>Excellent presentation and communication skills, with the ability to deliver engaging product demonstrations and presentations to potential customers.</li>
                      <li>Demonstrated track record of meeting or exceeding sales targets and driving revenue growth.</li>
                      <li>Ability to build and maintain relationships with key stakeholders, including C-level executives and senior decision-makers.</li>
                      <li>Strong analytical and problem-solving skills, with the ability to analyze sales data, identify trends, and make data-driven decisions.</li>
                      <li>Collaborative mindset, with the ability to work closely with cross-functional teams, including marketing, product, and senior leadership.</li>
                      <li>Familiarity with CRM systems and sales tools for pipeline management and reporting.</li>
                      <li>Knowledge of the travel, retail, and entertainment industries is a plus.</li>

                    </ul>

                  </div>
                  {/* col-9 end */}
                  <div className="col-md-3 col-xl-2">
                    {/* <Link to="/learning-materials/sales" target="_blank">
                      <button className="white-button mb-2">Learning Materials</button>
                    </Link> */}
                    {/* <Link to="https://apti.sciative.com/practice_test/" target="_blank">
                      <button className="white-button mb-2">Test Your Readiness</button>
                    </Link> */}
                    <div className="btn_div">
                      <button onClick={() => handleApplyNow("Business Development Manager (AI SaaS Company - AI Dynamic Pricing)")} className="yellow-button">Apply Now </button>

                    </div>
                  </div>
                  {/* col-3 end */}
                </div>


              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="12">
              <Accordion.Header>Strategic Analyst</Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  <div className="col-md-9 col-xl-10">
                    <h3 className="job_subhead">
                      Job Responsibilities
                    </h3>

                    <ul>
                      <li>Revenue Maximization and Customer Success: Identifying opportunities from revenue maximisation by quick decision-making and core business understanding</li>
                      <li>Problem identification and finding appropriate solutions: identify problems in the current infrastructure and come up with strategies to solve them.</li>
                      <li>Conduct in-depth analysis of business data, utilising various analytical techniques and tools to extract meaningful insights.</li>
                      <li>Continuously monitor and evaluate the effectiveness of implemented strategies, making adjustments as needed.</li>
                      <li>Implement research activities to analyse product performance and continuously monitor new and existing project plans and execution of desirable results.</li>
                      <li>Planning focused campaigns and health checks to ensure that our products/services are utilised effectively by the clients to ensure higher lifetime value(LTV).</li>
                      <li>Stay updated with industry trends, AI advancements, and best practices to propose innovative solutions.</li>

                    </ul>
                    <h3 className="job_subhead">
                      Qualifications
                    </h3>

                    <ul>
                      <li>Bachelor’s degree in Engineering or a related field.</li>
                      <li>Exceptional academic track record with a minimum of 90% in 10th grade and 75% in 12th grade.</li>
                      <li>Strong problem-solving abilities with a focus on data-driven decision-making.</li>
                      <li>Advanced Excel skills including data analysis, pivot tables, and complex formulas.</li>
                      <li>SQL knowledge preferred</li>
                      <li>Preferred experience or knowledge in AI modelling techniques.</li>
                      <li>Excellent communication skills to convey complex ideas and findings effectively.</li>
                      <li>Detail-oriented approach with a commitment to producing accurate and reliable results.</li>
                      <li>Collaborative team player with a proactive and positive attitude.</li>
                      <li>Passion for being a part of fast growing Saas company.</li>

                    </ul>

                  </div>
                  {/* col-9 end */}
                  <div className="col-md-3 col-xl-2">
                    {/* <Link to="/learning-materials/strategic-analyst" target="_blank">
                      <button className="white-button mb-2">Learning Materials</button>
                    </Link> */}
                    {/* <Link to="https://apti.sciative.com/practice_test/" target="_blank">
                      <button className="white-button mb-2">Test Your Readiness</button>
                    </Link> */}
                    <div className="btn_div">
                      <button onClick={() => handleApplyNow("Strategic Analyst")} className="yellow-button">Apply Now </button>

                    </div>
                  </div>
                  {/* col-3 end */}
                </div>


              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="13">
              <Accordion.Header>Junior Business Analyst (Non-Tech)</Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  <div className="col-md-9 col-xl-10">
                    <h3 className="job_subhead">
                      Job Responsibilities
                    </h3>
                    <p>As a Business Analyst (non-tech), you’ll be part of a highly results-oriented team and support the enterprise clients in the travel / retail business to drive their revenues, adoption of dynamic pricing and outcomes leading to effective on-boarding, expansion, and advocacy across your portfolio. A Business Analyst will be responsible for:</p>
                    <ul>
                      <li>Supports the Team Lead to maximize the business potential through in-depth data and business analysis of large travel companies and plan strategies for their seat-level revenue maximization</li>
                      <li>Performs necessary investigation, analysis and evaluation of pricing and revenue related parameters of client’s business</li>
                      <li>Develops and/or evaluates data and programs and suggest business strategies that would impact the overall operations of the client</li>
                      <li>Collaborates with internal and external stakeholders and communicate key insights and findings to the appropriate individuals</li>
                      <li>Supports in planning focused campaigns and health checks to ensure that our products/services are utilised effectively by the clients to ensure higher lifetime value [LTV].</li>
                      <li>Implementing research activities to analyse product performance and continuously monitor new and existing project plans and executions for desirable results.</li>
                      <li>Analyses large data sets (we deal in millions of data point every day) and pull out actionable insights, business intelligence, and reports to show the impact of all the strategies executed on client’s business</li>
                      <li>Interacting with internal and external clients, their Leadership Teams and manage project and product plans.</li>
                      <li>Works closely with your manager to ensure the business portfolio of clients are satisfied with the products and services they receive and to improve upon their adoption of the services</li>
                    </ul>
                    <h3 className="job_subhead">
                      Candidate profile
                    </h3>

                    <ul>
                      <li>1-2 years of experience in handling large data sets for business intelligence using Excel</li>
                      <li>High confident in problem solving skills: and ability to logically breakdown problems into incremental milestones</li>
                      <li>Ability to prepare powerful presentations</li>
                      <li>Strong written and oral communications skills are a must.</li>
                      <li>Self-learner: highly curious, self-starter and can work with minimum supervision and guidance</li>
                      <li>Track record of excellence in academics or non-academic areas, with significant accomplishments</li>
                      <li>Prior work experience in consumer businesses, such as retail, travel (airline, taxi, luxury buses), and hospitality preferred but not necessary. </li>
                      <li>Passion for being a part of a fast-growing SaaS company</li>

                    </ul>
                    <h3 className="job_subhead">
                      Qualifications
                    </h3>
                    <ul>
                      <li>
                        MBA / BBA / BE / BS / from a reputed institute
                      </li>

                    </ul>

                    <p><strong>NOTE THIS IS NOT A TECH ROLE. It will not require the candidate to perform any data analytics using Mysql, Python, or any other coding languages.</strong> </p>

                  </div>
                  {/* col-9 end */}
                  <div className="col-md-3 col-xl-2">
                    {/* <Link to="/learning-materials/business-analyst" target="_blank">
                      <button className="white-button mb-2">Learning Materials</button>
                    </Link> */}
                    {/* <Link to="https://apti.sciative.com/practice_test/" target="_blank">
                      <button className="white-button mb-2">Test Your Readiness</button>
                    </Link> */}
                    <div className="btn_div">
                      <button onClick={() => handleApplyNow("Junior Business Analyst (Non-Tech)")} className="yellow-button">Apply Now </button>

                    </div>
                  </div>
                  {/* col-3 end */}
                </div>

              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="14">
              <Accordion.Header>Talent Acquisition Specialist</Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  <div className="col-md-9 col-xl-10">
                    <p>Sciative is on a mission to create the future of dynamic pricing powered by artificial intelligence and big data. Our Software-as-a-Service products are used globally across various industries – retail,ecommerce, travel, entertainment and cinemas. With our amazing result-oriented product portfolio, we want to become the most customer-oriented company on the planet. To get there, we need exceptionally talented, bright, and driven people. We are looking for a dynamic, organized self-starter ‘Talent Acquisition Specialists’ to join our Human Resources department. As the Talent Acquisition Specialist, you would take charge of end-to-end recruitment by consulting with the business to support active hiring and pipelining for future capability needs to meet business growth objectives. </p>
                    <h3 className="job_subhead">
                      Job Responsibilities
                    </h3>

                    <ul>
                      <li>Build a robust organization: You will lead a team and drive end-to-end recruitment of mid and senior level leadership team members in both Tech and Business roles.</li>
                      <li>Understand organizational needs: You will support building and driving processes, policies, and workflows that are compliant, efficient, scalable, and reflect Sciative values.</li>
                      <li>Design and define relevant content for Job Descriptions with close collaboration with the hiring managers and roll-out the job descriptions on hiring portals.</li>
                      <li>Understand external talent demographics and tell stories / develop targeted marketing tactics describing our purpose, growth opportunities and impact on the world. Encouraging leaders and employees to be brand ambassadors in video, web and social.</li>
                      <li>Have the maturity to consult Hiring Manager or even senior leaders on recruitment, calibrate the gaps and push roles to close ASAP</li>
                      <li>Understanding KPIs and data to support analysis of hiring process efficiency.</li>

                    </ul>
                    <h3 className="job_subhead">
                      Candidate profile
                    </h3>

                    <ul>
                      <li>2-4 years of experience as a Talent Acquisition associate at an IT company or talent hunt company with exposure to full lifecycle recruitment for mid-career roles.</li>
                      <li>Interviewing, assessment and selection champion. Coaching leaders and HR professionals about how to select talent for “need now”, growth and future capabilities. </li>
                      <li>Experience in leveraging efficient sourcing channel to do direct sourcing & with good track record is highly preferred </li>
                      <li>Excellent persuasion and motivation skills. </li>
                      <li>Strong verbal and written communication skills </li>
                    </ul>
                    <h3 className="job_subhead">
                      Qualifications
                    </h3>
                    <ul>
                      <li>
                        MBA from a reputed institute
                      </li>
                    </ul>


                  </div>
                  {/* col-9 end */}
                  <div className="col-md-3 col-xl-2">
                    {/* <Link to="/learning-materials/talent-acquisition-specialisst" target="_blank">
                      <button className="white-button mb-2">Learning Materials</button>
                    </Link> */}
                    {/* <Link to="https://apti.sciative.com/practice_test/" target="_blank">
                      <button className="white-button mb-2">Test Your Readiness</button>
                    </Link> */}
                    <div className="btn_div">
                      <button onClick={() => handleApplyNow("Talent Acquisition Specialist")} className="yellow-button">Apply Now </button>

                    </div>
                  </div>
                  {/* col-3 end */}
                </div>

              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="15">
              <Accordion.Header>Growth Manager (SAAS)</Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  <div className="col-md-9 col-xl-10">
                    <p>SaaS growth manager will be responsible for: (a) Expanding Sciative’s business within India and in International markets (Biz Dev), (b) Identifying and communicating what’s right and what works for the customers (Product Marketing), (c) Collaborating with internal and external partners and stakeholders in delivering on customer promise (Customer Success)</p>
                    <h3 className="job_subhead">
                      Job Responsibilities
                    </h3>

                    <ul>
                      <li>Responsible for the entire sales cycle.</li>
                      <li>Educate customers on the product and value proposition.</li>
                      <li>Deliver sales pitch and conduct meetings & demos with enterprise companies.</li>
                      <li>Run a smooth process for lead generation, from initial outreach via Linkedin, email and phone, to qualification and creation of opportunities.</li>
                      <li>Prepare and customize marketing collateral for targeted customers.</li>
                      <li>Collect product feedback, pain points from client communications and transfer the learning to the product teams.</li>
                      <li>Conduct pre-sales analysis and demonstrate a compelling value proposition to the prospects.</li>
                      <li>Maintain a healthy customer pipeline of Enterprises and Fast Growing Companies to ensure over- achievement.</li>
                      <li>Build strong networks & relationships and drive business through participation in events.</li>
                      <li>Maintain data & communications in CRM.</li>
                    </ul>
                    <h3 className="job_subhead">
                      Candidate profile
                    </h3>

                    <ul>
                      <li>Should have minimum 5+ years of B2B inside sales experience, preferably selling to C-level decision makers in retail, ecommerce, digital and TV media, consumer goods, and travel related industries.</li>
                      <li>Engineering graduate. MBA is a plus</li>
                      <li>A great communicator (English-oral & written), presenter and influencer</li>
                      <li>Keen business acumen and a flair for sales</li>
                      <li>Entrepreneurial, independent, self-motivated and ability to grow and thrive in a dynamic startup environment</li>
                      <li>Someone who likes to win – You’re hungry for sales</li>

                    </ul>
                    <h3 className="job_subhead">
                      Qualifications
                    </h3>
                    <ul>
                      <li>
                        Engineering and MBA from a reputed institute
                      </li>
                    </ul>


                  </div>
                  {/* col-9 end */}
                  <div className="col-md-3 col-xl-2">
                    <Link to="/learning-materials/growth-manager" target="_blank">
                      <button className="white-button mb-2">Learning Materials</button>
                    </Link>
                    {/* <Link to="https://apti.sciative.com/practice_test/" target="_blank">
                      <button className="white-button mb-2">Test Your Readiness</button>
                    </Link> */}
                    <div className="btn_div">
                      <button onClick={() => handleApplyNow("Growth Manager (SAAS)")} className="yellow-button">Apply Now </button>

                    </div>
                  </div>
                  {/* col-3 end */}
                </div>


              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="16">
              <Accordion.Header>Project Manager</Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  <div className="col-md-9 col-xl-10">
                    <p>Project Manager will report directly to the founders and CEO of Sciative Solutions. This person will support them with strategic planning, performance tracking of different functions, project planning and tracking, new business development, and growth initiatives.</p>
                    <h3 className="job_subhead">
                      Job Responsibilities
                    </h3>

                    <ul>
                      <li>Strategic Planning: Summarize monthly performance on all business aspects, prepare reports for investors, track performance against goals and targets.</li>
                      <li>General Management: Coordinate with all department managers and functional heads, participate in milestone setting and tracking, identify project bottlenecks, advise leadership on mitigating project and program risks.</li>
                      <li>Leadership pipeline: Keep track of hiring pipeline – also including campus placements and support on boarding of new leaders in the organization.</li>
                      <li>New business development: Support founders in assessments of new business development opportunities, coordination with external stakeholders, prepare presentations and flyers, coordinate organization and /or participation in trade events or webinars.</li>
                      <li>Market tracking: Summarize key market trends and news for the senior leadership, including any news published for key accounts.</li>
                      <li>Cash flow management: Keep track of cash flow and keeping track of cashflow.</li>

                    </ul>
                    <h3 className="job_subhead">
                      Candidate profile
                    </h3>

                    <ul>
                      <li>Organized and coordinated: comfort in coordinating between different team members, set and follow timelines and priorities, hold team members accountable for timely deliveries.</li>
                      <li>Strong decision-making skills: able to set goals, handle uncertainty, and take well thought-out and planned decisions – with minimal supervision.</li>
                      <li>Energetic and versatile.</li>
                      <li>Eye for details and follow-through.</li>
                      <li>Expertise in MS OFFICE especially PPT, EXCEL & Word.</li>
                      <li>Excellent written and oral communication skills.</li>
                    </ul>
                    <h3 className="job_subhead">
                      Qualifications
                    </h3>
                    <ul>
                      <li>MBA from a reputed Institute.</li>
                      <li>1 to 3 years of work experience.</li>
                    </ul>

                  </div>
                  {/* col-9 end */}
                  <div className="col-md-3 col-xl-2">
                    {/* <Link to="/learning-materials/project-manager" target="_blank">
                      <button className="white-button mb-2">Learning Materials</button>
                    </Link> */}
                    {/* <Link to="https://apti.sciative.com/practice_test/" target="_blank">
                      <button className="white-button mb-2">Test Your Readiness</button>
                    </Link> */}
                    <div className="btn_div">
                      <button onClick={() => handleApplyNow("Project Manager")} className="yellow-button">Apply Now </button>

                    </div>
                  </div>
                  {/* col-3 end */}
                </div>


              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="17">
              <Accordion.Header>Content Writing Intern</Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  <div className="col-md-9 col-xl-10">
                    <p>Content Writing intern is a creative professional that writes copy for websites, blogs, social media whitepapers, eBooks and many other platforms. The writer works with the business to understand there tone of voice and find the best sources information.</p>
                    <h3 className="job_subhead">
                      Job Responsibilities
                    </h3>

                    <ul>
                      <li>Produce well-researched content for publication online and in print.</li>
                      <li>Develop related content for various platforms, such as websites, email marketing, product descriptions, videos, and blogs.</li>
                      <li>Create content marketing campaigns to drive leads and subscribers by use SEO best practices to generate traffic to our site.</li>
                      <li>Proofreading content for errors and inconsistencies.</li>
                      <li>Editing and polishing existing content to improve readability.</li>
                      <li>Conducting in-depth research on industry-related topics in order to develop original content.</li>
                      <li>Developing content for blogs, articles, product descriptions, social media, and the company website.</li>
                      <li>Organize writing schedules to complete drafts of content or finished projects within deadlines.</li>
                      <li>Utilize industry best practices and familiarity with the organization’s mission to inspire ideas and content.</li>
                      <li>Follow an editorial calendar, collaborating with other members of the content production team to ensure timely delivery of materials.</li>
                      <li>Analyse content marketing metrics and makes changes as needed.</li>
                      <li>Use search engine optimization (SEO) strategies in writing to maximize the online visibility of a website in search results.</li>
                    </ul>
                    <h3 className="job_subhead">
                      Candidate profile
                    </h3>

                    <ul>
                      <li>Proven record of excellent writing demonstrated.</li>
                      <li>Impeccable grasp of the English language, including idioms and current trends in slang and expressions.</li>
                      <li>Ability to work independently with little or no daily supervision.</li>
                      <li>Strong interpersonal skills and willingness to communicate with clients, colleagues, and management.</li>
                      <li>Ability to work on multiple projects with different objectives simultaneously.</li>
                      <li>Strict adherence to the style guides of each company and their policies for publication.</li>
                      <li>Good time management skills, including prioritizing, scheduling, and adapting as necessary.</li>
                      <li>Proficiency with computers, especially writing programs, such as Canva, Google Docs, Microsoft Word, Excel, Outlook, and PowerPoint.</li>
                    </ul>
                    <h3 className="job_subhead">
                      Qualifications
                    </h3>
                    <ul>
                      <li>BBA/ BMS /BMM/ BAMM from a reputed institute.</li>
                    </ul>
                    <h3 className="job_subhead">
                      Benefits
                    </h3>
                    <ul>
                      <li>Duration of the Internship: 2 months.</li>
                      <li>It will be paid Internship and students will be getting an internship completion certificate.</li>
                    </ul>

                  </div>
                  {/* col-9 end */}
                  <div className="col-md-3 col-xl-2">
                    {/* <Link to="/learning-materials/content" target="_blank">
                      <button className="white-button mb-2">Learning Materials</button>
                    </Link> */}
                    {/* <Link to="https://apti.sciative.com/practice_test/" target="_blank">
                      <button className="white-button mb-2">Test Your Readiness</button>
                    </Link> */}
                    <div className="btn_div">
                      <button onClick={() => handleApplyNow("Content Writing Intern")} className="yellow-button">Apply Now </button>

                    </div>
                  </div>
                  {/* col-3 end */}
                </div>

              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="18">
              <Accordion.Header>Technical Architect - AI powered SaaS Products</Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  <div className="col-md-9 col-xl-10">
                    <p>The primary role of Tech Architect will be to lead technical architecture of high scalable and data computation heavy SaaS products for AI powered dynamic price optimization.</p>
                    <h3 className="job_subhead">
                      Job Responsibilities
                    </h3>

                    <ul>
                      <li>Product performance: Ensure applications are scalable., fault tolerant, highly available,handle
high degree of concurrency, deliver low latency, and perform AI tasks at massive scale (5
Billion data processing per day).</li>
                      <li>Tech architecture design: Lead end to end tech design of SaaS applications.</li>
                      <li>Project management: Work with other software and full stack developers and oversee that
the developments are (a) adhering to the tech design, (b) following the software development
and documentation standards, and (c) consistently of the highest quality.Follow an agile
development process, and enable rapid solutioning to business challenges.</li>
                      <li>Plan integrations: Plan API based integrations with third party tech platforms (e.g. GDSs
operating in Travel or Hospitality industries, Seller services in marketplaces like Amazon,
FlipKart, Public Apps in Shopify, woocommerce; sales enablement platforms like SalesForce,
Microsoft Dynamics, etc.)</li>
                      <li>Oversee data engineering: Build complete understanding of data architecture, write data
processing scripts to prepare data for feeding to the AI engine(s).</li>
                      <li>AI and ML modules: Ensure seamless integrations of AI/ML modules at production scale.</li>
                      <li>App performance optimization: Enhance the data processing and tech architecture to deliver
superior computation performance and scalability of the Apps.</li>
                      <li>Product enhancements: Interact with client and business side teams to develop a roadmap for
product enhancements.</li>
                  

                    </ul>
                    <h3 className="job_subhead">
                      Candidate profile
                    </h3>

                    <ul>
                      <li>Entrepreneurial mind set with a positive attitude is a must.</li>
                      <li>Prior experience (minimum 5 years) in tech architecture designs of multi-tenant SaaS full stack application development.</li>
                      <li>Expert knowledge of new generation technologies is a must (e.g. Machine Queues, MSA architecture, PWA architecture, React, No-SQL, Serverless, Redis, Python, Django, SQL, Docker/Containers/Kubernetes, etc.).</li>
                      <li>Strong acumen in algorithmic problem solving.</li>
                      <li>Self-learner: highly curious, self-starter and can work with minimum supervision and guidance.</li>
                      <li>Track record of excellence in academics or non-academic areas, with significant accomplishments.</li>
                      <li>Excellent written &amp; oral communication and interpersonal skills, with a high degree of
comfort working in teams and making teams successful.</li>

                    </ul>
                    <h3 className="job_subhead">
                      Qualifications
                    </h3>
                    <ul>
                      <li>Toppers and Tech Geeks who are BTech / BE in Computer Science/Engineering, IT</li>
                      <li>5-7 years work experience is must in deep tech roles</li>
                    </ul>


                  </div>
                  {/* col-9 end */}
                  <div className="col-md-3 col-xl-2">
                    {/* <Link to="/learning-materials/technical-architect" target="_blank">
                      <button className="white-button mb-2">Learning Materials</button>
                    </Link> */}
                    {/* <Link to="https://apti.sciative.com/practice_test/" target="_blank">
                      <button className="white-button mb-2">Test Your Readiness</button>
                    </Link> */}
                    <div className="btn_div">
                      <button onClick={() => handleApplyNow("Technical Architect - AI powered SaaS Products")} className="yellow-button">Apply Now </button>

                    </div>
                  </div>
                  {/* col-3 end */}
                </div>


              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="19">
              <Accordion.Header>Pricing Executive (Telugu Speaking)</Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  <div className="col-md-9 col-xl-10">
                    <h3 className="job_subhead">
                      Job Responsibilities
                    </h3>

                    <ul>
                      <li>Revenue Maximization and Customer Success: Identifying opportunities for revenue maximization by quick decision-making and core business understanding.</li>
                      <li>Monitoring day-to-day business: Perform all functions in an efficient manner, flex between tasks as directed by supervisor.</li>
                      <li>Problem Identification and finding appropriate solutions: Identify problems in the current infrastructure and come up with strategies to solve them</li>
                      <li>Planning focused campaigns and health checks to ensure that our products/services are utilized effectively by the clients to ensure higher lifetime value [LTV].</li>

                    </ul>
                    <h3 className="job_subhead">
                      Candidate profile
                    </h3>

                    <ul>
                      <li>Microsoft Excel (Beginner to Intermediate)</li>
                      <li>Quick Decision maker</li>
                      <li>Decent communication abilities</li>


                    </ul>
                    <h3 className="job_subhead">
                      Qualifications
                    </h3>
                    <ul>
                      <li>B.A, B.Com, BBA</li>
                    </ul>


                  </div>
                  {/* col-9 end */}
                  <div className="col-md-3 col-xl-2">
                    {/* <Link to="/learning-materials/pricing-executive" target="_blank">
                      <button className="white-button mb-2">Learning Materials</button>
                    </Link> */}
                    {/* <Link to="https://apti.sciative.com/practice_test/" target="_blank">
                      <button className="white-button mb-2">Test Your Readiness</button>
                    </Link> */}
                    <div className="btn_div">
                      <button onClick={() => handleApplyNow("Pricing Executive (Telugu Speaking)")} className="yellow-button">Apply Now </button>

                    </div>
                  </div>
                  {/* col-3 end */}
                </div>

              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="20">
              <Accordion.Header>Pricing Executive (Tamil Speaking)</Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  <div className="col-md-9 col-xl-10">
                    <h3 className="job_subhead">
                      Job Responsibilities
                    </h3>

                    <ul>
                      <li>Revenue Maximization and Customer Success: Identifying opportunities for revenue maximization by quick decision-making and core business understanding.</li>
                      <li>Monitoring day-to-day business: Perform all functions in an efficient manner, flex between tasks as directed by supervisor.</li>
                      <li>Problem Identification and finding appropriate solutions: Identify problems in the current infrastructure and come up with strategies to solve them.</li>
                      <li>Planning focused campaigns and health checks to ensure that our products/services are utilized effectively by the clients to ensure higher lifetime value [LTV].</li>

                    </ul>
                    <h3 className="job_subhead">
                      Candidate profile
                    </h3>

                    <ul>
                      <li>Microsoft Excel (Beginner to Intermediate)</li>
                      <li>Quick Decision maker</li>
                      <li>Decent communication abilities</li>


                    </ul>
                    <h3 className="job_subhead">
                      Qualifications
                    </h3>
                    <ul>
                      <li>B.A, B.Com, BBA</li>
                    </ul>

                  </div>
                  {/* col-9 end */}
                  <div className="col-md-3 col-xl-2">
                    {/* <Link to="/learning-materials/pricing-executive" target="_blank">
                      <button className="white-button mb-2">Learning Materials</button>
                    </Link> */}
                    {/* <Link to="https://apti.sciative.com/practice_test/" target="_blank">
                      <button className="white-button mb-2">Test Your Readiness</button>
                    </Link> */}
                    <div className="btn_div">
                      <button onClick={() => handleApplyNow("Pricing Executive (Tamil Speaking)")} className="yellow-button">Apply Now </button>

                    </div>
                  </div>
                  {/* col-3 end */}
                </div>


              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="21">
              <Accordion.Header>QA Engineer</Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  <div className="col-md-9 col-xl-10">
                    <p>Sciative is on a mission to create the future of dynamic pricing powered by artificial intelligence and
                      big data. Our Software-as-a-Service products are used globally across various industries – retail,
                      ecommerce, travel, and entertainment. We are a fast growth-oriented startup, with an employee
                      strength of 60+ employees based in plush office at Navi Mumbai. With our amazing result-oriented
                      product portfolio, we want to become the most customer-oriented company on the planet. To get
                      there we are seeking for smart, diligent, and determined professional to join our team in the role of
                      Software Tester
                    </p>
                    <p>As the QA Engineer you will monitor the quality of data from which the business teams shall make
                      informed decisions. You will examine complex data to optimize the efficiency and quality of thedata
                      being collected, resolve data quality problems, and collaborate with database developers to improve
                      systems and database designs. You will develop procedures to enhance the accuracy and integrity
                      of our organization's data. You will be performing data analysis, collaborating with database
                      developers to enhance data collection and storage procedures, and preparing data analysis reports.</p>
                    <h3 className="job_subhead">
                      Job Responsibilities
                    </h3>

                    <ul>
                      <li>Performing manual ,automation and statistical tests on large datasets to determine data quality and integrity.</li>
                      <li>Evaluating system performance and design, as well as its effect on data quality.</li>
                      <li>Collaborating with database developers to improve data collection and storage processes.</li>
                      <li>Running data queries to identify coding issues and data exceptions, as well as cleaning data.</li>
                      <li>Gathering data from primary or secondary data sources to identify and interpret trends.</li>
                      <li>Reporting data analysis findings to management to inform business decisions and prioritize information system needs.</li>
                      <li>Documenting processes and maintaining data records.</li>
                      <li>Adhering to best practices in data analysis and collection.</li>
                      <li>Keeping abreast of developments and trends in data quality analysis.</li>
                    </ul>
                    <h3 className="job_subhead">
                      Candidate profile
                    </h3>

                    <ul>
                      <li>Bachelor's degree in Engineering, Statistics, Mathematics, Computer Science, Information Management, Or Similar.</li>
                      <li>At least 2 years of experience in Software Testing.</li>
                      <li>Proficiency in programming languages, including Structured Query Language (SQL) and Python.</li>
                      <li>Exceptional analytical skills.</li>
                      <li>Advanced problem-solving skills.</li>
                      <li>Knowledge of best practices in data analysis.</li>
                      <li>Excellent interpersonal and communication skills..</li>
                    </ul>


                  </div>
                  {/* col-9 end */}
                  <div className="col-md-3 col-xl-2">
                    <Link to="/learning-materials/qa" target="_blank">
                      <button className="white-button mb-2">Learning Materials</button>
                    </Link>
                    {/* <Link to="https://apti.sciative.com/practice_test/" target="_blank">
                      <button className="white-button mb-2">Test Your Readiness</button>
                    </Link> */}
                    <div className="btn_div">
                      <button onClick={() => handleApplyNow("QA Engineer")} className="yellow-button">Apply Now </button>

                    </div>
                  </div>
                  {/* col-3 end */}
                </div>


              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="22">
              <Accordion.Header>Jr Analyst</Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  <div className="col-md-9 col-xl-10">
                    <p>Sciative is on a mission to create the future of dynamic pricing powered by artificial intelligence and big data. Our
                      Software-as-a-Service products are used globally across various industries – retail, ecommerce, travel, and entertainment.
                      We are a fast growth-oriented startup, with an employee strength of 60+ employees based in a plush office at Navi Mumbai.
                      With our amazing result-oriented product portfolio, we want to become the most customer-oriented company on the planet. To
                      get there, we need exceptionally talented, bright, and driven people. If you'd like to help us build the place to find and buy
                      anything online, this is your chance to make history. We are looking for a dynamic, organized self-starter Jr Analyst to join our
                      Travel Business department.</p>
                    <h3 className="job_subhead">
                      Job Responsibilities
                    </h3>

                    <ul>
                      <li>Revenue Maximization and Customer Success: Identifying opportunities form revenue
                        maximization by quick decision-making and core business understanding.</li>
                      <li>Monitoring day-to-day business: Perform all functions in an efficient manner, flex between tasks as directed by
                        supervisor.</li>
                      <li>Problem Identification and finding appropriate solutions: Identify problems in the current
                        infrastructure and come up with strategies to solve them.</li>
                      <li>Planning focused campaigns and health checks to ensure that our products/services are utilized effectively
                        by the clients to ensure higher lifetime value [LTV].</li>
                    </ul>
                    <h3 className="job_subhead">
                      Candidate profile
                    </h3>

                    <ul>
                      <li>Microsoft Excel (Beginner to Intermediate).</li>
                      <li>Quick Decision maker</li>
                      <li>Decent communication abilities.</li>
                      <li>Self-learner, highly curious, self-starter, and can work with minimum supervision and guidance.</li>
                      <li>Entrepreneurial mind-set with a positive attitude is necessary.</li>
                      <li>Team-player</li>
                      <li>Orientation to detail.</li>
                      <li>Time Management.</li>
                      <li>Analytical skills.</li>
                      <li>Customer Behavioral Aspects.</li>
                      <li>Critical Thinking.</li>
                      <li>Business Understanding.</li>

                    </ul>

                    <h3 className="job_subhead">
                      Qualifications
                    </h3>
                    <ul>
                      <li>B.A, B.Com, BBA, MBA, MCom</li>
                    </ul>

                  </div>
                  {/* col-9 end */}
                  <div className="col-md-3 col-xl-2">
                    {/* <Link to="/learning-materials/jr-analyst" target="_blank">
                      <button className="white-button mb-2">Learning Materials</button>
                    </Link> */}
                    {/* <Link to="https://apti.sciative.com/practice_test/" target="_blank">
                      <button className="white-button mb-2">Test Your Readiness</button>
                    </Link> */}
                    <div className="btn_div">
                      <button onClick={() => handleApplyNow("Jr Analyst")} className="yellow-button">Apply Now </button>

                    </div>
                  </div>
                  {/* col-3 end */}
                </div>

              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </section>
      <section className="imgsection" data-aos="new-animation">
        <img src={gall_2} alt="Team Pictures" />
      </section>



      <Modal
        show={formModal.show}
        onHide={() => setFormModal({ show: false, role: "" })}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        dialogClassName="careers-form-modal"
      >
        <CloseIcon onClick={() => setFormModal({ show: false, role: "" })} className='modal-close-icon' />
        <Modal.Body className="modal-body px-4">
          <CareersForm
            role={formModal.role}
            recipients_group_id={3}
            handleApiResponse={handleApiResponse}
          />
        </Modal.Body>
      </Modal>

    </HelmetProvider>
  );
}

function CarrerSlider() {
  const settings = {
    fade: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: true,
    // pauseOnHover:true
  };
  return (
    <div className="slider-box ">
      <div className="slider-container">
        <Slider {...settings}>
          {/* <div className="image1">
        </div> */}
          <div className="image2">
          </div>
          {/* <div className="image3">
        </div>
        <div className="image4">
        </div>
        <div className="image5">
        </div>
        <div className="image6">
        </div> */}
        </Slider>
      </div>
    </div>
  );
}

export default Careers;