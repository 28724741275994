import Footer from "../common/footer";
import Header from "../common/header";
import Ticker from "../common/ticker";
import '../../assets/css/main.scss';
import AOS from "aos";

import "aos/dist/aos.css";
import rightarrow from '../../assets/img/right-arrow-black.png';
import rightarrowwhite from '../../assets/img/right-arrow.png';
import travelvideo from '../../assets/videos/travel_animation.mp4';
import viajeLogo from '../../assets/img/viaje-logo.png'
import { Nav, Navbar, Container, NavDropdown } from 'react-bootstrap';
import ClientBusSlider from "../travel/clientBusSlider";
import Leadership from "../retail/leadership";
import FounderVideo from "../retail/founderVideo";
import Recoginition from "../retail/recognition";
import ProductNumber from "../retail/productnumber";
import RaTalkSingle from "../retail/ratalksingle";
import TestimonialSlider from "../retail/testimonialslider";
import { useState, useEffect } from 'react';
import TravelBusInsights from "../travel/travelBusinsights";
import TravelProductNumber from "../travel/travelproductnumber";
import TestimonialSliderTravel from "../travel/testimonialslidertravel";
import CapteraAchievements from "../travel/capterra-achievvement";
import RouteReports from "../travel/routeReports";
import ProfitCalculator from '../travel/profitCalculator';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { toast } from 'react-toastify';
import Form from "../Forms/Form";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Link } from "react-router-dom";
import AllRoutes from "../../AllRoutes";
import { GlobalUrl, GlobalImg } from "../../global";
import TravelHeader from "../travel/travelHeader";
import TravelHeaderMobile from "../travel/travelHeaderMobile";

const Travel = ({ }) => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, [])
    const [showHeader1, setShowHeader1] = useState(false);
    const [showHeader2, setShowHeader2] = useState(false);
    const [open, setOpen] = useState(false);
    const props = {
        template: 1,
        form_name: "travel-book-a-demo",
        fields: [
            { name: "name", required: true },
            { name: "email", required: true },
            { name: "company", required: true },
            { name: "message", required: true },
        ],
        title: "Book a Demo",
        content_upper: "Curious to explore AI pricing or knowing more about us? Send us a short message, our experts will contact you at the earliest.",
        content_lower: "Let's Price Right!",
        recipients_group_id: 1,
        country: "IN",
        handleApiResponse: (res) => {
            toast.dismiss();
            setOpen(false)
            if (res.status == "success"){
                toast.success('Form submitted successfully!');
              } else {
                toast.error(res.data)
              }
        }
    }

    const formProps = {
        template: 4,
        form_name: 'book-a-demo-page-form',
        fields: [
            { name: 'name', required: true },
            { name: 'email', required: true },
            { name: 'company', required: true },
            { name: 'phonein', required: true },
            { name: 'message', required: true },
        ],
        country: "IN",
        recipients_group_id: 2,
        handleApiResponse: (res) => {
            toast.dismiss();
            if (res.status == "success"){
                toast.success('Form submitted successfully! We will get back to you in 2-3 days.');
              } else {
                toast.error(res.data)
              }
        }
    }

    const contactExpertForm = {
        template: 5,
        form_name: 'contact-expert-form',
        fields: [
            { name: 'name', required: true },
            { name: 'email', required: true },
            { name: 'phone', required: true },
            { name: 'company', required: true },
            { name: 'message', required: true },
        ],
        recipients_group_id: 1,
        handleApiResponse: (res) => {
            toast.dismiss();
            if (res.status == "success"){
                toast.success('Form submitted successfully! We will get back to you in 2-3 days.');
            } else {
                toast.error(res.data)
            }
        }
    }

    function scrollToKeyFeatures() {
        const routeReportElement = document.getElementById('key-features');
        if (routeReportElement) {
            routeReportElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        if (winScroll >= 80 && window.innerWidth >= 600) {
            setShowHeader2(false);
            setShowHeader1(true);
          }
          else if (winScroll >= 80 && window.innerWidth < 600) {
            setShowHeader1(false)
            setShowHeader2(true)
          }
          else {
            setShowHeader1(false)
            setShowHeader2(false)
          }
    }

    function scrollToRouteReports() {
        const routeReportElement = document.getElementById('route-reports');
        if (routeReportElement) {
            routeReportElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        if (winScroll >= 80 && window.innerWidth >= 600) {
            setShowHeader2(false);
            setShowHeader1(true);
          }
          else if (winScroll >= 80 && window.innerWidth < 600) {
            setShowHeader1(false)
            setShowHeader2(true)
          }
          else {
            setShowHeader1(false)
            setShowHeader2(false)
          }
    }

    function scrollToProfitCalculator() {
        const profitCalculatorElement = document.getElementById('profit-calculator');
        if (profitCalculatorElement) {
            profitCalculatorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        if (winScroll >= 80 && window.innerWidth >= 600) {
            setShowHeader2(false);
            setShowHeader1(true);
          }
          else if (winScroll >= 80 && window.innerWidth < 600) {
            setShowHeader1(false)
            setShowHeader2(true)
          }
          else {
            setShowHeader1(false)
            setShowHeader2(false)
          }
    }

    function scrollFunction() {
        var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        if (winScroll >= 80 && window.innerWidth >= 600) {
          setShowHeader2(false);
          setShowHeader1(true);
        }
        else if (winScroll >= 80 && window.innerWidth < 600) {
          setShowHeader1(false)
          setShowHeader2(true)
        }
        else {
          setShowHeader1(false)
          setShowHeader2(false)
        }
    }

    useEffect(() => {
        document.addEventListener('wheel', function () { scrollFunction() });
        document.addEventListener('scroll', function () { scrollFunction() });
    }, [])

    return (
        <HelmetProvider>
            <Helmet> 
                <title>AI Dynamic Pricing Software for Travel | Sciative</title>
                <meta charset="utf-8" />
                <link rel="canonical" href={`${GlobalUrl}${AllRoutes.travel}`} />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="csrf_token" content="" />
                <meta property="type" content="website" />
                <meta name="theme-color" content="#ffffff" />
                <meta name="_token" content="" />
                <meta name="robots" content="noodp" />
                <meta name='description' content="AI-powered seat level AI dynamic price optimization and repricing for transforming the bus industry. Get route reports, profit calculator, competitive pricing." />
                <meta name='keywords' content="Travel, Viaje.ai, Bus, Dynamic Pricing, automate pricing,automated pricing tool,brand protection software,competitor price tracking software,demand forecasting software,discounting software" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:title" content="AI Dynamic Pricing Software for Travel | Sciative" />
                <meta property="og:description" content="AI-powered seat level AI dynamic price optimization and repricing for transforming the bus industry. Get route reports, profit calculator, competitive pricing." />
         
                <meta property="og:hashtag" content="#ai" />
                <meta content="image/*" property="og:image:type" />
                <meta property="og:url" content={`${GlobalUrl}${AllRoutes.travel}`} />
                <meta property="og:site_name" content="Sciative Solutions - We Price Right" />
                <meta property="og:image"  content={GlobalImg} />
                <meta property="og:type" content="website" />
            </Helmet>
            <div className='travel_pages'>
            {
          showHeader1 ?
            <TravelHeader scrollToKeyFeatures={scrollToKeyFeatures} scrollToProfitCalculator={scrollToProfitCalculator} scrollToRouteReports={scrollToRouteReports} />
            :
            showHeader2 ?
              <TravelHeaderMobile scrollToKeyFeatures={scrollToKeyFeatures} scrollToProfitCalculator={scrollToProfitCalculator} scrollToRouteReports={scrollToRouteReports} />
              :
              null
        }
            <section style={{ padding: '120px 0 50px 0' }} className="banner_retail banner_2">
                <div className="container" data-aos="new-animation">
                    
                    <div className="row w-100 banner_logo_div">
                        <div className="col-md-3">
                            <img src={viajeLogo} alt="NuRetail Logo" id="banner-logo" />
                        </div>
                        <div className="col-md-9 text-md-end d-none d-md-block">
                            <ul>
                                <li><a onClick={scrollToKeyFeatures}>KEY FEATURES</a></li>
                                <li><a onClick={scrollToRouteReports}>ROUTE REPORTS</a></li>
                                <li><a onClick={scrollToProfitCalculator}>PROFIT CALCULATOR</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-7-5">
                            <h1>Harness AI Dynamic Pricing for your Bus <br /> Industry Pricing Evolution</h1>
                            <p>Cultivate a competitive advantage with precision travel pricing solutions designed for the modern bus industry</p>
                            <div>
                                <Link to={AllRoutes.travelcontact}>
                                    <button className="yellow-button">Experience Dynamic Pricing </button>
                                </Link>
                                {/* <button className="transparent-button">Get started <img src={rightarrowwhite} /></button> */}
                            </div>
                        </div>
                        <div className="col-md-4-5 pe-0 ps-0 d-none d-lg-block">
                                <Form {...contactExpertForm} />
                        </div>
                        <div className="my-0 p-0 col-12">
                                <video autoPlay loop muted playsInline>
                                <source src={travelvideo} type="video/mp4" />
                                {/* <source src="movie.ogg" type="video/ogg"> */}
                                Your browser does not support the video tag.
                                </video>
                        </div>
                         {/* video */}
                        <div className="mt-5 col-12 pe-0 ps-0 d-block d-lg-none">
                            <Form {...contactExpertForm} />
                        </div>
                    </div>
                   

                </div>
            </section>

            <div>
                {/* <TravelBusInsights /> */}
                <ClientBusSlider />
                <section className="bus_video">
                    <div className="container  text-center"   data-aos="new-animation">
                    <h2 className="bigHead  text-center mb-3">Check Out How We Impact Our Clients' Life</h2>
                        <p className="">Viaje.ai will handle all the heavy lifting when it comes to pricing bus seats.</p>
                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                            <iframe src="https://www.youtube.com/embed/ehcpuFbBkM4?controls=1&amp;mute=1&amp;loop=1&amp;playlist=ehcpuFbBkM4" title="Viaje.ai Video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen=""></iframe>
                  
                            </div>
                        </div>
                       
                         </div>
                </section>
                <CapteraAchievements props={"home_captera"} />
                <section className="why_section" id="key-features" >
                    <div className="container"   data-aos="new-animation">
                        <h2 className="bigHead  text-center mb-3">Why Choose Viaje.ai?​ </h2>
                        <p className="text-center mb-5">Viaje.ai is more than just an innovation - <br />
                            It's a powerful, fully automated AI solution designed to cater to the diverse needs of bus operators.</p>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="why_card">
                                    <div className="why_card_1">
                                        <i class="fa-solid fa-couch"></i>
                                    </div>
                                    <div className="why_card_2">
                                        <h3 className="card_head">
                                        Seat Level Pricing
                                        </h3>
                                        <p>With seat-level pricing, businesses can customise their bus pricing strategies to individual seats, maximising revenue, while the passengers enjoy a personalised experience.</p>
                                       <Link to={AllRoutes.travelcontact}>
                                        <button className="transparent-button">Try Dynamic Pricing Now <span class="material-symbols-outlined">trending_flat</span></button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            {/* col-md-6 */}
                            <div className="col-md-6">
                                <div className="why_card">
                                    <div className="why_card_1">
                                        <i class="fa-solid fa-rotate-right"></i>
                                    </div>
                                    <div className="why_card_2">
                                        <h3 className="card_head">
                                        Automatic Switch
                                        </h3>
                                        <p>Viaje.ai is designed to sense and respond to fluctuations in market demand. The model automatically switches from price maximization to occupancy maximization, depending on market behaviour.</p>
                                        <Link to={AllRoutes.travelcontact}>
                                        <button className="transparent-button">Switch to Next-Gen Pricing <span class="material-symbols-outlined">trending_flat</span></button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            {/* col-md-6 */}
                            <div className="col-md-6">
                                <div className="why_card">
                                    <div className="why_card_1">
                                        <i class="fa-solid fa-clock-rotate-left"></i>
                                    </div>
                                    <div className="why_card_2">
                                        <h3 className="card_head">
                                        Real-Time Competition Tracking
                                        </h3>
                                        <p>Viaje.ai has market research capabilities that focus on competition directly relevant to your schedules, providing more actionable insights.</p>
                                        <Link to={AllRoutes.travelcontact}>
                                        <button className="transparent-button">Track Competition Now<span class="material-symbols-outlined">trending_flat</span></button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            {/* col-md-6 */}
                            <div className="col-md-6">
                                <div className="why_card">
                                    <div className="why_card_1">
                                        <i class="fa-solid fa-route"></i>
                                    </div>
                                    <div className="why_card_2">
                                        <h3 className="card_head">
                                        Multi-Station Bookings
                                        </h3>
                                        <p>Instead of simply considering the main route, our model takes into account all the stops along the route, to ensure fair and transparent bus ticket pricing for passengers.</p>
                                        <Link to={AllRoutes.travelcontact}>
                                        <button className="transparent-button">Get the Bookings Started<span class="material-symbols-outlined">trending_flat</span></button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            {/* col-md-6 */}
                        </div>
                    </div>
                </section>
                <Recoginition page_name="home_recog" />
                <RouteReports />
                <TestimonialSliderTravel />
                <ProfitCalculator />




                {/* <TravelProductNumber /> */}
                {/* <RaTalkSingle /> */}

                {/* <section className="click_button ">
                    <div className="container text-center">
                        <h2 className="bigHead text-center mb-3 text-white">See How We Can Make a Difference to Your Business</h2>
                        <button className="yellow-button">Explore Sciative</button>
                    </div>
                </section> */}
                <section className="form-section">
                    <div className="container">
                        <div className="text-container">
                            <h2 className="bigHead mb-5">Curious to Know More About Us!</h2>
                            <p>Explore more about how our travel pricing experts can scale your revenue to soaring heights just by pricing right.</p>
                        </div>
                        <div className="form-container">
                            <Form {...formProps} />
                        </div>
                    </div>
                </section>
            </div>



            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth='md'
            >
                <DialogContent>
                    <Form {...props} />
                </DialogContent>
            </Dialog>

            </div>
        </HelmetProvider>
    );
}


export default Travel;