import React, { useState, useEffect } from 'react';

import '../../../assets/css/blogs.css';
import BlogCard from './BlogCard';
import { Link } from 'react-router-dom';
import BlueRectangleImage from '../../../assets/img/blue_rectangle.png';
import Book from '../../../assets/img/book.png';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import Form from '../../../components/Forms/Form';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';

const BlogsSection = ({ blogs, showReadMore }) => {

    const [topBlogs, setTopBlogs] = useState([]);
    const [otherBlogs, setOtherBlogs] = useState([]);
    const [pdfRequestModal, setPdfRequestModal] = useState(false);
    const [requestedFileKey, setRequestedFileKey] = useState('');

    const handlePdfRequest = (file_key) => {
        setPdfRequestModal(true);
        setRequestedFileKey(file_key);
    }

    const pdfRequestProps = {
        template: 1,
        form_name: "case-studies-pdf-request",
        fields: [
            { name: "name", required: true },
            { name: "email", required: true },
            { name: "phone", required: true }
        ],
        title: "See Sciative in Action & Drive Results",
        content_upper: "Make data-driven decisions with confidence. Dive into case studies and see how Sciative tackled challenges and delivered exceptional results. Get access to case studies and unlock exceptional insights that inspire you.",
        content_lower: "",
        recipients_group_id: 1,
        requestedFileKey: requestedFileKey,
        handleApiResponse: (res) => {
            toast.dismiss();
            if (res && res.status && res.status === "success") {
                window.open(res.data.signed_url);
                toast.success('Form submitted successfully!');
                setPdfRequestModal(false);
            }
            else {
                toast.error(res.data);
            }
        }
    }

    useEffect(() => {
        if (blogs.length) {
            setTopBlogs([blogs[0], blogs[1], blogs[2]])
            let temp = []
            for (let i = 3; i < blogs.length; i += 1) {
                temp.push(blogs[i])
            }
            setOtherBlogs(temp)
        }
    }, [blogs])

    return (
        <>
            <section className='w-100 row'>
                <div className='col-sm-12 col-md-6 p-2'>
                    {
                        typeof blogs[0] !== 'undefined' ?
                            <div className='hero-card corner d-flex flex-column justify-content-end main-blog' style={{ backgroundImage: `url(${blogs[0]['templateType'] === 'pdf' ? blogs[0]['image_key'] : blogs[0]['images']['img-main-img']['url']})` }}>
                                <div className='w-100 corner main-blog-gradient px-4 d-flex flex-column justify-content-between'>
                                    <span className='text-white'>{moment(blogs[0]['publishedDate']).format('Do MMMM, YYYY')}</span>
                                    <div>
                                        {
                                            blogs[0]['templateType'] === 'pdf' ?
                                                <h3 className='bold text-white'><a style={{ cursor: 'pointer' }} onClick={() => handlePdfRequest(blogs[0]['file_key'])}>
                                                    {blogs[0]['inputs']['caseStudyTitle'].length > 70 ? blogs[0]['inputs']['caseStudyTitle'].slice(0, 57) + '...' : blogs[0]['inputs']['caseStudyTitle']}
                                                </a></h3>
                                                :
                                                <Link className='d-inline-block' to={`/case-studies/${blogs[0]['url']}`}>
                                                    <h3 className='bold text-white'>{blogs[0]['title'].length > 70 ? blogs[0]['title'].slice(0, 67) + '...' : blogs[0]['title']}</h3>
                                                </Link>
                                        }

                                    </div>
                                    <div className='small-2 mb-3 text-white'>
                                        <span className='mr-2 '><i className="fa-solid fa-book mr-1"></i> 10 min read <span className=''>|</span></span>
                                        {blogs[0]['categories'].map((cat, index) => (
                                            <React.Fragment key={index}>
                                                <a className='text-white mr-1' href={`/case-studies/category/${cat}`}>{cat}</a>
                                                {index !== blogs[0]['categories'].length - 1 && ', '}
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            :
                            null
                    }
                </div>
                <div className='col-sm-12 col-md-3 p-2'>
                    <BlogCard blog={blogs[1]} />
                </div>
                <div className='col-sm-12 col-md-3 p-2'>
                    <BlogCard blog={blogs[2]} />
                </div>
            </section>
            <section className='w-100 row my-2'>
                {
                    showReadMore && otherBlogs.length > 7 ?
                        otherBlogs.slice(0, 7).map(blog => (
                            <div className='col-sm-12 col-md-3 p-1'>
                                <BlogCard blog={blog} />
                            </div>
                        ))
                        :
                        otherBlogs.map(blog => (
                            <div className='col-sm-12 col-md-3 p-1'>
                                <BlogCard blog={blog} />
                            </div>
                        ))
                }
                {
                    showReadMore && otherBlogs.length > 7 &&
                    <div className='col-sm-12 col-md-3 p-1'>
                        <div className='p-1'>
                            <Link to={`/case-studies/category/all`}>
                                <div className='corner read-more-card d-flex flex-column justify-content-center align-items-center gap-3'>
                                    <img className='icon-img' src={Book} alt="book" />
                                    <span className='text-white'>Read all Case Studies</span>
                                </div>
                            </Link>
                        </div>
                    </div>
                }
            </section>
            <Modal
                show={pdfRequestModal}
                onHide={() => setPdfRequestModal(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                contentClassName="remove-white-bg"
                backdrop="static"
                centered
            >
                <Modal.Body className="p-0">
                    <CloseIcon onClick={() => setPdfRequestModal(false)} className='modal-close-icon' />
                    <Form {...pdfRequestProps} />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default BlogsSection;