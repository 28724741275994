import AllRoutes from "../../AllRoutes";
import InnerCard from "../retail/innercard";
import img1 from "../../assets/img/retail/automated-pricing/1.png";
import img2 from "../../assets/img/retail/automated-pricing/2.png";
import img3 from "../../assets/img/retail/automated-pricing/3.png";
import bggoal from "../../assets/img/store/goal-driven-pricing.png";
import bgunified from "../../assets/img/store/unified-pricing.png";
import bgautomated from "../../assets/img/store/automated-pricing.png";
import { GlobalUrl, GlobalImg } from "../../global";

import { Link } from "react-router-dom";
import CapteraAchievements from "../travel/capterra-achievvement";
import RetailClient from "../retail/retailCLient";
import AOS from "aos";
import { useEffect, useState } from "react";
import "aos/dist/aos.css";
import InnerHeroRetail from "../retail/innerHeroRetail";
import RetailInsideNumber from "../retail/retailInsideNumber";
import MarketPlaceIntegration from "../retail/marketPlaceIntegration";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Form from "../Forms/Form";
import { toast } from "react-toastify";
import NavigationSection from "../retail/NavigationSection";
import BenefitSection from "../retail/BenefitSection";
import b1 from "../../assets/img/icon/pricecontrol.png";
import b2 from "../../assets/img/icon/CustomizableRules.png";
import b3 from "../../assets/img/icon/integration.png";
import b4 from "../../assets/img/icon/multicurrency.png";
import b5 from "../../assets/img/icon/dataanalysis_1.png";
import b6 from "../../assets/img/icon/customalert.png";
import pimg1 from "../../assets/img/picon/1.png";
import pimg2 from "../../assets/img/picon/2.png";
import pimg3 from "../../assets/img/picon/3.png";
import pimg4 from "../../assets/img/picon/4.png";
import pimg5 from "../../assets/img/picon/5.png";
import ProblemSection from "../retail/ProblemSection";


const PricingAutomation = ({ }) => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])
  const end_point = "pricing-automation";

  const qa_list = [
    "Have you experienced challenges in adjusting prices quickly in response to competitor pricing changes?",
    "Do you find it difficult to track and monitor pricing data across multiple products and categories?",
    "Have you missed opportunities to optimize prices based on real-time market demand and trends?",
    "Is it challenging to ensure pricing consistency across different sales channels and locations?",
  ];

  const formProps = {
    template: 4,
    form_name: 'retail-inside-pages',
    fields: [
      { name: 'name', required: true },
      { name: 'email', required: true },
      { name: 'company', required: true },
      { name: 'phone', required: true },
      { name: 'message', required: true },
    ],
    recipients_group_id: 1,
    handleApiResponse: (res) => {
      toast.dismiss();
      if (res.status == "success"){
        toast.success('Form submitted successfully! We will get back to you in 2-3 days.');
    } else {
        toast.error(res.data)
    }
    }
  }
  const navigation_section_props = {
    id: "pricing_automation",
    heading: "Industry Problems That Our Unified Pricing Management Software Solves",
    tabs: [
        {
          title: "Inconsistent pricing across multiple channels.",
          img: pimg1 ,
          alt: "Inconsistent pricing across multiple channels.",
          correcttitle: "BRIO ends channel chaos! One-click pricing across all platforms ensures consistency. "
        },
        {
          title: "Lack of data driven and actionable insights.",
          img: pimg2,
          alt: "Lack of data driven and actionable insights.",
          correcttitle: "BRIO unlocks insights, empowers action! Real-time data fuels smart decisions for your success."
        },
        {
          title: "Difficulty in monitoring product availability.",
          img: pimg3,
          alt: "Difficulty in monitoring product availability.",
          correcttitle: "BRIO spots stockouts before they happen! Market intel & listing health keep products available."
        },
        {
          title: "Inadequate inventory management leading to stock outs or overstock.",
         
          img: pimg4,
          alt: "Inadequate inventory management leading to stock outs or overstock.",
          correcttitle: "BRIO predicts demand, avoids stock issues! Perfect inventory levels, every single time."
        },
        {
          title: "Slow response to market trends and consumer demand shifts.",
         
          img: pimg5,
          alt: "Slow response to market trends and consumer demand shifts.",
          correcttitle: "BRIO reacts in real-time! Market trends & customer shifts drive agile strategy with AI."
        },
    ]
  }
  const benefit_props = {
    id: "automated_pricing",
    heading: "Benefits of Using BRIO",
    tabs:[
      {
        title: "Centralized Price Control and Real-Time Updates",
        desc: "A centralized control hub built with real-time price synchronization capabilities, ensures competitive pricing and is up-to-date across multiple platforms.",
        img: b1 ,
        alt: "Centralized Price Control and Real-Time Updates Image" 
      },
      {
        title: "Customizable Rules and Automated Adjustments",
        desc: "Customize your pricing strategy with flexible rules, supported by AI data intelligence and real-time adjustments.",
        img: b2 ,
        alt: "Customizable Rules and Automated Adjustments" 
      },
      {
        title: "Marketplace Integration",
        desc: "Our plug-and-play integration with major platforms like Amazon, eBay, and Shopify, alongside automated pricing tool and inventory management tools, streamlines your revenue generation process.",
        img: b3 ,
        alt: "Marketplace Integration" 
      },
      {
        title: "Multi-Currency Support",
        desc: "Our global tech stack enables multi-currency pricing, facilitating cross-border transactions and broadening customer appeal.",
        img: b4 ,
        alt: "Multi-Currency Support" 
      },
      {
        title: "Centralized Data Analysis",
        desc: "Our automated pricing tool analyzes data to gain valuable insights into customer behaviour, purchasing patterns, and market trends to make a 360 informed decision.",
        img: b5 ,
        alt: "Centralized Data Analysis" 
      },
      {
        title: "Custom Alerts",
        desc: "Never miss a revenue opportunity with these actionable alerts and notifications enabling you to stay proactive and responsive to market dynamics.",
        img: b6 ,
        alt: "Custom Alerts" 
      },

    ]
  }
  const navigation_section_props_2 = {
    id: "automated_pautomation",
    heading: "How Does BRIO's Pricing Engine Transforms Multichannel Retail Operations?",
    tabs: [
        {
          title: "Maintain consistency across channels",
          desc: "With our unified pricing engine, we ensure consistent pricing across various sales channels, physical stores, e-commerce websites, mobile apps, and social media platforms. You name the channel and it's unified reducing your time and effort and increasing brand trust.",
          img: img1 ,
          alt: "Maintain consistency across channels" 
        },
        {
          title: "Grab on cross-selling and up-selling opportunities",
          desc: "Consistent pricing information allows brands to implement targeted promotions and recommendation strategies seamlessly across thereby increasing your cart value by % and customer satisfaction.",
          img: img2,
          alt: "Grab on cross-selling and up-selling opportunities"
        },
        {
          title: "Efficiently manage promotion/ discount",
          desc: "Centrally manage and execute promotions, discounts, and offers across all channels thereby reducing human errors and ensuring a 30 % improvement in operational efficiency.",
          img: img3,
          alt: "Efficiently manage promotion/ discount"
        },
        {
          title: "Enhance your inventory management",
          desc: "Understocking and overstocking are no longer a problem with our pricing engine as its integration with inventory management systems tracks your inventory on a real-time basis across all channels thereby giving an 80% reduction in NPA inventory.",
          img: img3,
          alt: "Enhance your inventory management"
        }
    ]
  }
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Dynamic Pricing for Marketplaces Solution  | Sciative</title>
          <link rel="canonical" href={`${GlobalUrl}${AllRoutes.pricingautomation}`} />
          <meta charset="utf-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta name="csrf_token" content="" />
          <meta property="type" content="website" />
          <meta name="theme-color" content="#ffffff" />
          <meta name="_token" content="" />
          <meta name="robots" content="noodp" />
          <meta name='description' content="Optimize Marketplace Pricing: Utilize AI-driven solutions for peak profits and real-time competition. Explore our ecommerce solutions for detailed insights" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:title" content="Dynamic Pricing for Marketplaces Solution  | Sciative" />
          <meta property="og:description" content="Optimize Marketplace Pricing: Utilize AI-driven solutions for peak profits and real-time competition. Explore our ecommerce solutions for detailed insights" />
         
          <meta property="og:hashtag" content="#ai" />
          <meta content="image/*" property="og:image:type" />
          <meta property="og:url" content={`${GlobalUrl}${AllRoutes.pricingautomation}`} />
          <meta property="og:site_name" content="Sciative Solutions - We Price Right" />
          <meta property="og:image"  content={GlobalImg} />
          <meta property="og:type" content="website" />
          <script type="application/ld+json">
              {`
              {
                "@context": "https://schema.org/", 
                "@type": "BreadcrumbList", 
                "itemListElement": [{
                  "@type": "ListItem", 
                  "position": 1, 
                  "name": "Home",
                  "item": "https://sciative.com"  
                },{
                  "@type": "ListItem", 
                  "position": 2, 
                  "name": "Retail",
                  "item": "https://sciative.com/retail"  
                },{
                  "@type": "ListItem", 
                  "position": 3, 
                  "name": "solution",
                  "item": "https://sciative.com/retail/pricing-automation"  
                }]
              }
                
              `}
            </script>
        </Helmet>

        <InnerHeroRetail end_point={end_point} page_name="pricingautomation" page_heading="Automated Pricing Across Channels" page_desc1="Master Unified Pricing with Ease: One Solution, " page_desc2="Many Platforms, Total Control." button_name="Explore Automated Pricing" />
        <RetailClient />

   
       <ProblemSection  {...navigation_section_props} />

        <section className="client_det inside_det">
          <div className="container" data-aos="new-animation">
            <h2 className="bigHead mb-5">We Deliver, Our Customers ‘ Speak </h2>
            <div className="card">

              <p className="client_test">BRIO's unified pricing has revolutionised our electronics brand. With a 30% improvement in operational efficiency and a remarkable 20% increase in revenue, we've experienced firsthand how this solution caters specifically to the complexities of the electronics industry. The ability to swiftly adjust prices based on market demand for gadgets and devices has kept us ahead of the curve, ensuring our products remain competitive and profitable. Thanks to the automated pricing tool, we've unlocked new levels of success in the ever-evolving electronics market.</p>
              <div className="flex_card">
                <div className="card_1">
                  {/* <img src={clientimg} alt="Rupesh Patil" /> */}
                  <div className="client_desc">
                    <p className="namec">Private Label Fashion Brand</p>
                    <p className="named">North America</p>
                  </div>
                </div>

                {/* <img src={kkimg} alt="" className="kk_img" /> */}
              </div>
            </div>
            <div className="text-center mt-5">
              <p className="bold">If they could, even your brand can!</p>
              <Link to={`/retail/book-a-demo?navigation=${end_point}`}>
                <button class="yellow-button">Connect now</button>
              </Link>
            </div>
          </div>
        </section>
        <RetailInsideNumber end_point={end_point} />
        {/* <RetailBenefits /> */}
       <BenefitSection {...benefit_props} />


        {/* <section className="client_det auto_price">
            <div className="container">
                <h2 className="bigHead mb-5">We Deliver, Our Customers ‘ Speak </h2>
                <div className="card">
                    <div className="flex_card">
                        <div className="card_1">
                            <img src={clientimg} alt="Rupesh Patil" />
                            <div className="client_desc">
                                <p className="namec">Rupesh Patil,</p>
                                <p className="named">Senior Manager MIS, Costing & Business Development</p>
                            </div>
                        </div>
                       
                        <img src={kkimg} alt="" className="kk_img" />
                    </div>
                    <p className="client_test">What I liked best about working with Sciative is their quest to understand the Customer’s need rather than pushing any of their products (which are great, by the way). The team is absolutely professional and the output work is very commendable. It has helped our business take key timely decisions.</p>
                </div>
                <div className="text-center client_det_para">
                        <p>If they could, even your brand can!</p>
                        <Link to='/retail/book-a-demo' className="yellow-button">Connect Now</Link>
                </div>
            </div>
        </section> */}
        <CapteraAchievements props={"brio_inside"} />
        <NavigationSection {...navigation_section_props_2} />
        <MarketPlaceIntegration  end_point={end_point} />
        {/* <section className="retail_bg_cta">
          <div className="container" data-aos="new-animation">
            <div className="card ap_card">
              <p className="card_text">Let Your Numbers Also Speak for Your Brand Success</p>
              <Link to={`/retail/book-a-demo?navigation=${end_point}`}>
                <button className="yellow-button">Book a Demo Now</button>
              </Link>
            </div>
          </div>
        </section> */}
        <section className="qa_store">
          <div className="container" data-aos="new-animation">
            <h2 className="bigHead text-center mb-5">What all is in store for you ?</h2>
            <div className="row">
            <div className="col-md-4">
                <InnerCard headImg={bgautomated} pageheading="Automated Price Management" pagelink={AllRoutes.automatedpricingpage} />

              </div>
              {/* col-4 end */}
              <div className="col-md-4">
                <InnerCard headImg={bgunified} pageheading="Unified Pricing Engine" pagelink={AllRoutes.unifiedpricingpage} />

              </div>
              {/* col-4 end */}
              <div className="col-md-4">
                <InnerCard headImg={bggoal} pageheading="Goal Driven Pricing" pagelink={AllRoutes.goalbasedpage} />

              </div>
              {/* col-4 end */}
            </div>
          </div>
        </section>
       
        <section className="form-section">
          <div className="container" data-aos="new-animation">
            <div className="text-container">
              <h2 className="bigHead mb-5">Curious to know more about us!</h2>
              <p>Explore more about how our pricing experts  can scale your revenue to soaring heights just by pricing right.</p>
            </div>
            <div className="form-container">
              <Form {...formProps} />
            </div>
          </div>
        </section>
      </HelmetProvider>
    </>
  )
}
export default PricingAutomation;