import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import Form from '../../../components/Forms/Form';
import { toast } from 'react-toastify';
import moment from 'moment';

const BlogCard = ({ blog }) => {
    const [pdfRequestModal, setPdfRequestModal] = useState(false);
    const [requestedFileKey, setRequestedFileKey] = useState('');

    if (typeof blog === 'undefined') return null

    

    const handlePdfRequest = (file_key) => {
        setPdfRequestModal(true);
        setRequestedFileKey(file_key);
    }

    const pdfRequestProps = {
        template: 1,
        form_name: "case-studies-pdf-request",
        fields: [
            { name: "name", required: true },
            { name: "email", required: true },
            { name: "phone", required: true }
        ],
        title: "See Sciative in Action & Drive Results",
        content_upper: "Make data-driven decisions with confidence. Dive into case studies and see how Sciative tackled challenges and delivered exceptional results. Get access to case studies and unlock exceptional insights that inspire you.",
        content_lower: "",
        recipients_group_id: 1,
        requestedFileKey: requestedFileKey,
        handleApiResponse: (res) => {
            toast.dismiss();
            if (res && res.status && res.status === "success") {
                window.open(res.data.signed_url);
                toast.success('Form submitted successfully!');
                setPdfRequestModal(false);
            }
            else {
                toast.error(res.data);
            }
        }
    }
    return (
        <div className='blog-card p-1'>
            <div className='d-flex flex-column justify-content-between'>
                {
                    blog['templateType'] === 'pdf' ?
                    <a style={{cursor: 'pointer'}} onClick={() => handlePdfRequest(blog['file_key'])}>
                        <div className='w-100 corner blog-card-img' style={{backgroundImage: `url(${blog['templateType'] === 'pdf' ? blog['image_key'] : blog['images']['img-main-img']['url']})`}} ></div>
                    </a>
                    :
                    <Link to={`/case-studies/${blog['url']}`}>
                        <div className='w-100 corner blog-card-img' style={{backgroundImage: `url(${blog['templateType'] === 'pdf' ? blog['image_key'] : blog['images']['img-main-img']['url']})`}} ></div>
                    </Link>
                }
                
                <div className='p-1'>
                    <div className='mt-1'>
                        <span className='small-2 text-muted'>{moment(blog['publishedDate']).format('Do MMMM, YYYY')}</span>
                    </div>
                    <div className='mt-2'>
                        {
                            blog['templateType'] === 'pdf' ?
                                <a style={{cursor: 'pointer'}} onClick={() => handlePdfRequest(blog['file_key'])} className="bold text-black">
                                    {blog['inputs']['caseStudyTitle'].length > 50 ? blog['inputs']['caseStudyTitle'].slice(0, 57) + '...' : blog['inputs']['caseStudyTitle']}
                                </a>
                                :
                                <Link className='d-inline-block' to={`/case-studies/${blog['url']}`}>
                                    <h6 className='bold text-black'>
                                        {blog['title'].length > 50 ? blog['title'].slice(0, 57) + '...' : blog['title']}
                                    </h6>
                                </Link>
                        }
                    </div>
                    <div className='w-100 d-flex flex-wrap'>
                        
                        <div className='small-2'>
                        <span className='mr-2 '><i className="fa-solid fa-book mr-1"></i> 10 min read <span className=''>|</span></span>
                            {blog['categories'].map((cat, index) => (
                                <React.Fragment key={index}>
                                    <a className='text-black' href={`/case-studies/category/${cat}`}>{cat}</a>
                                    {index !== blog['categories'].length - 1 && ', '}
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                show={pdfRequestModal}
                onHide={() => setPdfRequestModal(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                contentClassName="remove-white-bg"
                backdrop="static"
                centered
            >
                <Modal.Body className="p-0">
                    <CloseIcon onClick={() => setPdfRequestModal(false)} className='modal-close-icon' />
                    <Form {...pdfRequestProps} />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default BlogCard;