import { useEffect, useState } from 'react';

const ShortStories = () => {

    return (
        <div className='w-100 container d-flex flex-column align-items-center justify-content-center'>
            <h4 className='bold'>Short Industry Tales</h4>

            <div className='mt-4 w-100 row'>
                <div className='col-sm-12 col-md-6 col-lg-3 my-2 d-flex justify-content-center'>
                    <iframe className='yt-shorts'
                        src="https://www.youtube.com/embed/B7gWZQIYQ7c"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen />
                </div>
                <div className='col-sm-12 col-md-6 col-lg-3 my-2 d-flex justify-content-center'>
                    <iframe className='yt-shorts'
                        src="https://www.youtube.com/embed/lZgR3OCYAXA"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen />
                </div>
                <div className='col-sm-12 col-md-6 col-lg-3 my-2 d-flex justify-content-center'>
                    <iframe className='yt-shorts'
                        src="https://www.youtube.com/embed/41OqeipsazU"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen />
                </div>
                <div className='col-sm-12 col-md-6 col-lg-3 my-2 d-flex justify-content-center'>
                    <iframe className='yt-shorts'
                        src="https://www.youtube.com/embed/qK94bG-41no"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen />
                </div>
            </div>
        </div>
    )
}

export default ShortStories;