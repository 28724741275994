import { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Form from '../Forms/Form';
import NewsCard from '../common/NewsCard';

import yourStory from '../../assets/img/media/your-story.png'
import travelWorld from '../../assets/img/media/travel-world.png'
import indianRetailer from '../../assets/img/media/indian-retailer.png'
import indianExpress from '../../assets/img/media/indian-express.png'
import dataquest from '../../assets/img/media/dataquest.png'

import news from '../../data/news';
import { GlobalUrl, GlobalImg } from "../../global";
import AllRoutes from "../../AllRoutes";
import { Helmet, HelmetProvider } from "react-helmet-async";
import AOS from "aos";
import "aos/dist/aos.css";
import { toast } from 'react-toastify';

const News = ({ }) => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, [])
    const props = {
        template: 2,
        form_name: "news-page", // Page name
        fields: [{
            name: "email",
            required: true
        }],
        recipients_group_id: 1,
        handleApiResponse: (res) => {
            toast.dismiss();
            if (res.status == "success"){
                toast.success('Form Submitted successfully')
              } else {
                toast.error(res.data)
              }
        }
    }

    return (
        <HelmetProvider>
        <Helmet> 
       <title>Latest News, Updates & Business Insights | Sciative</title>
       <link rel="canonical" href={`${GlobalUrl}${AllRoutes.news}`} />
       <meta charset="utf-8" />
       <meta http-equiv="X-UA-Compatible" content="IE=edge" />

       <meta property="type" content="website" />
       <meta name="theme-color" content="#ffffff" />
       <meta name="_token" content="" />
       <meta name="robots" content="noodp" />

       <meta name='description' content="Use AI tech to understand the market, plan your inventory and ad sales. Do demand forecasting and inventory management for media, and entertainment industry." />
       <meta property="og:locale" content="en_US" />
       <meta property="og:title" content="Latest News, Updates & Business Insights | Sciative" />
       <meta property="og:description" content="Use AI tech to understand the market, plan your inventory and ad sales. Do demand forecasting and inventory management for media, and entertainment industry." />
       
       <meta property="og:hashtag" content="#ai" />
       <meta content="image/*" property="og:image:type" />
       <meta property="og:url" content={`${GlobalUrl}${AllRoutes.news}`} />
       <meta property="og:site_name" content="Sciative Solutions - We Price Right" />

       <meta property="og:image"  content={GlobalImg} />
       <meta property="og:type" content="website" />
       </Helmet>
            <section className="smallBanner news_bg">
                <div className="container"  data-aos="new-animation">
                <p className="small_head">Sciative in Media</p>
                    <h1 className="about_para w-80 ">
                    Everything You Need to Know About Sciative's News & Insights
                    </h1>
                    <p className="about_para_2">
                    Stay informed, stay ahead: your source for the latest news and business insights on Sciative.
                    </p>
                    <div className='mt-5 col-sm-12 col-md-8 col-lg-6'>
                        <p className="bold about_para_2 mb-4">
                        Subscribe to our weekly research articles for more data-driven insights.
                        </p>
                        <Form {...props} />
                    </div>
                </div>
            </section>
            <section className="career_img news_img">
                <div className="container d-flex flex-column align-items-center"  data-aos="new-animation">
                    <div className='w-100 d-flex justify-content-center align-items-center'>
                        <h2 className='bigHead text-center'>Sciative Chronicles: Latest Updates & Insights</h2>
                    </div>

                    <div className='mt-4 w-100 row'>
                        <div className='col-md-6 offset-lg-2 col-lg-4 d-flex'>
                            <NewsCard type='news'
                                url={news[0]}
                                img={yourStory}
                                date={'15 August 2021'}
                                title={"Decoding iconic brand Archies' survival strategies to survive COVID-19 and other top stories of the week"}
                            />
                        </div>
                        <div className='col-md-6 col-lg-4 d-flex '>
                            <NewsCard type='news'
                                url={news[1]}
                                img={travelWorld}
                                date={'12 August 2021'}
                                title={"AI-powered dynamic pricing: Ensuring sustainability in the travel industry"}
                            />
                        </div>
                    </div>

                    <div className=' w-100 row'>
                        <div className=' col-md-6 col-lg-4 d-flex'>
                            <NewsCard url={news[2]} type='news' img={indianRetailer} date={'5 August 2021'} title={"Tweet & Buy: What will Twitter's Shop Module Bring for India's Retail Industry"} />
                        </div>
                        <div className=' col-md-6 col-lg-4 d-flex'>
                            <NewsCard url={news[3]} type='news' img={indianExpress} date={'16 July 2021'} title={"Dissecting the relevance of flash sales"} />
                        </div>
                        <div className=' col-md-6 col-lg-4 d-flex'>
                            <NewsCard url={news[4]} type='news' img={dataquest} date={'31 May 2021'} title={"Why e-Commerce industry necessitates a healthy mix of predictive and prescriptive analytics models?"} />
                        </div>
                    </div>
                </div>
            </section>
        </HelmetProvider>
    )
}

export default News;