import { Routes, Route } from 'react-router-dom';

import UserManagement from './UserManagement/UserManagement';

import AdminLogin from './AdminLogin';
import BlogsAdmin from './BlogsAdmin/BlogsAdmin';
import CreateBlogs from './BlogsAdmin/CreateBlogs';
import EditBlog from './BlogsAdmin/EditBlog';
import AdminLogs from '../AdminLogs';

import CaseStudiesAdmin from './CaseStudiesAdmin/CaseStudiesAdmin';
import CreateCaseStudies from './CaseStudiesAdmin/CreateCaseStudies';
import EditCaseStudy from './CaseStudiesAdmin/EditCaseStudy';

import AdminLayout from '../../components/Layouts/AdminLayout';

import RATalksManagement from './RoadAheadTalks_Admin/RATalksManagement';
import ExpertManagement from './RoadAheadTalks_Admin/ExpertManagement';
import CreateWebinar from './RoadAheadTalks_Admin/CreateWebinar';
import EditWebinar from './RoadAheadTalks_Admin/EditWebinar';
import CreateRecordedVideo from './RoadAheadTalks_Admin/CreateRecordedVideo';
import AddFeaturedExpert from './RoadAheadTalks_Admin/AddFeaturedExpert';
import EditExpert from './RoadAheadTalks_Admin/EditExpert';

import FormsManagement from './FormsManagement';
import ViewForm from './ViewForm';

import { RequireToken } from '../../Auth';
import CreateForm from './FormsAdmin/CreateForm';


const Admin = ({ match }) => {

  return(
      <Routes>
          <Route path="login" element={<AdminLogin />} />

          {/* user management routes (only for super admin) */}
          <Route path="user-management" element={<RequireToken><AdminLayout><UserManagement /></AdminLayout></RequireToken>} />

          {/* blog admin routes */}
          <Route path="blogs-admin" element={<RequireToken><AdminLayout><BlogsAdmin /></AdminLayout></RequireToken>} />
          <Route path="create-blog" element={<RequireToken><AdminLayout><CreateBlogs /></AdminLayout></RequireToken>} />
          <Route path="edit-blog/:blogId" element={<AdminLayout><EditBlog /></AdminLayout>} />
          <Route path="admin-logs" element={<AdminLayout><AdminLogs /></AdminLayout>} />

          {/* case studies admin routes */}
          <Route path="case-studies-admin" element={<RequireToken><AdminLayout><CaseStudiesAdmin /></AdminLayout></RequireToken>} />
          <Route path="create-case-study" element={<RequireToken><AdminLayout><CreateCaseStudies /></AdminLayout></RequireToken>} />
          <Route path="edit-case-study/:caseStudyId" element={<AdminLayout><EditCaseStudy /></AdminLayout>} />

          {/* roadahead talks routes */}
          <Route path="road-ahead-talks-management" element={<RequireToken><AdminLayout><RATalksManagement /></AdminLayout></RequireToken>} />
          <Route path="expert-management" element={<RequireToken><AdminLayout><ExpertManagement /></AdminLayout></RequireToken>} />
          <Route path="create-webinar" element={<RequireToken><AdminLayout><CreateWebinar /></AdminLayout></RequireToken>} />
          <Route path="edit-webinar/:url" element={<RequireToken><AdminLayout><EditWebinar /></AdminLayout></RequireToken>} />
          <Route path="create-recorded-video" element={<RequireToken><AdminLayout><CreateRecordedVideo /></AdminLayout></RequireToken>} />
          <Route path="add-expert" element={<RequireToken><AdminLayout><AddFeaturedExpert /></AdminLayout></RequireToken>} />
          <Route path="edit-expert/:id" element={<RequireToken><AdminLayout><EditExpert /></AdminLayout></RequireToken>} />

          {/* form management routes */}
          <Route path="forms-management" element={<AdminLayout><FormsManagement /></AdminLayout>} />
          <Route path="forms-management/:form_name" element={<AdminLayout><ViewForm /></AdminLayout>} />

          <Route path="create-form" element={<AdminLayout><CreateForm /></AdminLayout>} />

      </Routes>
  )
};

export default Admin;