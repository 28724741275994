import { useEffect, useState } from 'react';
import Footer from "../common/footer";
import Header from "../common/header";
import Ticker from "../common/ticker";
import '../../assets/css/main.scss';
import brioLogo from '../../assets/img/brio.png'
import rightarrow from '../../assets/img/right-arrow.png';
import { GlobalUrl, GlobalImg } from "../../global";
import retailvideo from '../../assets/videos/retail_video.mp4';

import { Tab, Col, Nav, Navbar, Container, NavDropdown, Button, Row } from "react-bootstrap";
import InnerCard from "../retail/innercard";
import MarketPlaceIntegration from "../retail/marketPlaceIntegration";
import ClientSlider from "../retail/clientSlider";
import Leadership from "../retail/leadership";
import FounderVideo from "../retail/founderVideo";
import Recoginition from "../retail/recognition";
import ProductNumber from "../retail/productnumber";
import RaTalkSingle from "../retail/ratalksingle";
import TestimonialSlider from "../retail/testimonialslider";
import { Helmet, HelmetProvider } from "react-helmet-async";
import RetailHeader from "../retail/retailHeader";
import RetailHeaderMobile from "../retail/retailHeaderMobile";
import RetailClient from "../retail/retailCLient";
import RetailNumbers from "../retail/retailNumbers";
import CapteraAchievements from "../travel/capterra-achievvement";
import RetailTestimonial from "../retail/retailTestimonial";
import RetailDatapoint from "../retail/retaiDatapoint";
import img10 from "../../assets/img/retail/automated-pricing/10.jpg";
import img11 from "../../assets/img/retail/automated-pricing/11.jpg";
import img12 from "../../assets/img/retail/automated-pricing/12.jpg";
import AutomatedPricingTab from "../retail/automatedPricingTab";
import MarketIntelligenceTab from "../retail/marketIntelligenceTab";
import SalesInventoryTab from "../retail/salesInventoryTab";
import DiscountManagementTab from "../retail/discountManagement";
import imgauto from "../../assets/img/retail/automated-pricing/automated-pricing.png";
import imguni from "../../assets/img/retail/automated-pricing/unified_price.png";
import imgoth from "../../assets/img/retail/other-drivers/keep-tab-on-sales.png";
import automationdahboard from "../../assets/img/retail/automated-pricing/dsahboard.png";
import marketinteldahboard from "../../assets/img/retail/market-intelligence/dashboard.png";
import customerdatadahboard from "../../assets/img/retail/customer-data/dashboard.png";
import digitalshelfdahboard from "../../assets/img/retail/digital-shlef/dashboard.png";
import discountmgmt from "../../assets/img/retail/discount-marketing/dashboard.png";
import bggoal from "../../assets/img/retail/goalbased-pricing/goal-based-pricing.jpg";
import bgunified from "../../assets/img/retail/unified-pricing/unified-pricing.jpg";
import bgcomp from "../../assets/img/retail/competition-intelligence/assortment_availability.png";
import bgautomated from "../../assets/img/retail/automated-pricing/automated-pricing.png";
import Form from "../Forms/Form";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import AllRoutes from "../../AllRoutes";
import NavigationBody from '../../components/retail/NavigationBody';
import AOS from "aos";
import "aos/dist/aos.css";
const Retail = ({ }) => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])
  const [showHeader1, setShowHeader1] = useState(false);
  const [showHeader2, setShowHeader2] = useState(false);
  const formProps = {
    template: 4,
    form_name: 'retail-inside-pages',
    fields: [
      { name: 'name', required: true },
      { name: 'email', required: true },
      { name: 'company', required: true },
      { name: 'phone', required: true },
      { name: 'message', required: true },
    ],
    recipients_group_id: 1,
    handleApiResponse: (res) => {
      toast.dismiss();
      if (res.status == "success"){
        toast.success('Form submitted successfully! We will get back to you in 2-3 days.');
      } else {
        toast.error(res.data)
      }
    }
  }

  const contactExpertForm = {
    template: 5,
    form_name: 'contact-expert-form',
    fields: [
        { name: 'name', required: true },
        { name: 'email', required: true },
        { name: 'phone', required: true },
        { name: 'company', required: true },
        { name: 'message', required: true },
    ],
    recipients_group_id: 1,
    handleApiResponse: (res) => {
        toast.dismiss();
        if (res.status == "success"){
            toast.success('Form submitted successfully! We will get back to you in 2-3 days.');
        } else {
            toast.error(res.data)
        }
    }
}



  const end_point = "main";

  function scrollFunction() {
    var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    if (winScroll >= 80 && window.innerWidth >= 600) {
      setShowHeader2(false);
      setShowHeader1(true);
    }
    else if (winScroll >= 80 && window.innerWidth < 600) {
      setShowHeader1(false)
      setShowHeader2(true)
    }
    else {
      setShowHeader1(false)
      setShowHeader2(false)
    }
  }

  useEffect(() => {
    document.addEventListener('wheel', function () { scrollFunction() });
    document.addEventListener('scroll', function () { scrollFunction() });
    // window.addEventListener('wheel', function () { scrollFunction() });
    // window.addEventListener('scroll', function () { scrollFunction() });
  }, [])

  const navigationBodyProps = {
    heading: "Automated Pricing",
    desc: "BRIO leads the way with its cutting-edge retail automated pricing tool, offering advanced price optimizations that seamlessly navigates market fluctuations. By harnessing innovation and machine learning, it provides e-commerce businesses with strategic and adaptive pricing strategy for maximum profit.",
    img: automationdahboard,
    alt: "Automated Pricing",
    btn_link: AllRoutes.automatedpricingpage,
    btn_text: "Go To Dashboard"
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>AI Automated Pricing Tool for Retail Business | Sciative</title>
          <link rel="canonical" href={`${GlobalUrl}${AllRoutes.retail}`} />
          <meta charset="utf-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta name="csrf_token" content="" />
          <meta property="type" content="website" />
          <meta name="theme-color" content="#ffffff" />
          <meta name="_token" content="" />
          <meta name="robots" content="noodp" />
          <meta name='description' content="Make repricing easy with our retail automated pricing tool. Tackle MAP violations, ensure pricing consistency across channels, and stay ahead of competitors." />
          {/* <meta name='keywords' content="Travel, Viaje.ai, Bus, Dynamic Pricing, automate pricing,automated pricing tool,brand protection software,competitor price tracking software,demand forecasting software,discounting software" /> */}
          <meta property="og:locale" content="en_US" />
          <meta property="og:title" content="AI Automated Pricing Tool for Retail Business | Sciative" />
          <meta property="og:description" content="Make repricing easy with our retail automated pricing tool. Tackle MAP violations, ensure pricing consistency across channels, and stay ahead of competitors." />
         
          <meta property="og:hashtag" content="#ai" />
          <meta content="image/*" property="og:image:type" />
          <meta property="og:url" content={`${GlobalUrl}${AllRoutes.retail}`} />
          <meta property="og:site_name" content="Sciative Solutions - We Price Right" />
          <meta property="og:image"  content={GlobalImg} />
          <meta property="og:type" content="website" />
        </Helmet>
        <div className='retail_pages'>
        {/* <section className="this_div">
            <img src={img11} alt="Brio Logo" id="banner-logo" />
            </section> */}
        {
          showHeader1 ?
            <section style={{ padding: '0', borderBottom: '1px solid #fff', width: '100%', position: 'fixed', top: '43px', height: '4.5em', zIndex: 100 }} className='banner_retail main_banner bg-black'>
              <div className='container'>
                <RetailHeader end_point={end_point} />
              </div>
            </section>
            :
            showHeader2 ?
                <RetailHeaderMobile />
              :
              null
        }

        <section className="banner_retail main_banner banner_2">
          <div className="container">
            {/* <img src={nuRetailLogo} alt="NuRetail Logo" id="banner-logo" /> */}
            <RetailHeader end_point={end_point} />
            <div className="row">
              <div className="col-md-7-5">
                <h1>Dynamic Pricing Software for Retailers: <br/>Win Customers' Hearts and Wallets with AI</h1>
                <p>Proven next-gen AI powered dynamic pricing software.</p>
                <div>
                  <Link to={`/retail/book-a-demo?navigation=${end_point}`}>
                    <Button className="yellow-button">Get Started</Button>
                  </Link>
                </div>
              </div>
              <div className="col-md-4-5 pe-0 ps-0 d-none d-lg-block">
                <Form {...contactExpertForm} />
              </div>
              <div className="my-0 p-0 col-12">
                <video autoPlay loop muted playsInline>
                  <source src={retailvideo} type="video/mp4" />
                  {/* <source src="movie.ogg" type="video/ogg"> */}
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className="mt-5 col-12 pe-0 ps-0 d-block d-lg-none">
                <Form {...contactExpertForm} />
              </div>
            </div>
            
            

          </div>
        </section>
        <RetailClient page_name="retail_page_client" page_heading="Brands who Leverage Sciative to Stay Ahead" />
        <section className="main_edge retail_main">
          <div className="container">
            <h2 className="text-center">Solutions Curated to meet Diverse Industry Demand</h2>
            <div className="retail_main_tab">
              <Tab.Container defaultActiveKey="mainsecond" >
                <Row id="r_main_one">
                  <Col sm={12}>
                    <Nav variant="pills" className="r_main_pills">
                      <Nav.Item>
                        <Nav.Link eventKey="mainsecond">For Retailers</Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link eventKey="mainthird">For Marketplaces</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="mainfirst">For Brands and Sellers</Nav.Link>
                      </Nav.Item>

                    </Nav>
                  </Col>
                  <Col sm={12}>
                    <Tab.Content className="investor_edge">
                      <Tab.Pane eventKey="mainfirst">
                       
                        <Tab.Container defaultActiveKey="marketfirst1" >
                          <h2 className="text-center bigHead mb-5">Market Intelligence</h2>
                          <Row className="">
                            <Col xl={5}>
                              <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                  <Nav.Link eventKey="marketfirst1">
                                    <p>BRIO’s most robust competition tracking system stays leaps ahead by monitoring not just competitor prices, but also their assortment and sales rank positioning in near-real time. With BRIO, your marketing and category teams can easily track and compare the share of search and content quality scores, ensuring a holistic view of the competitive landscape.</p>
                                    <div className="investor_edge_div res_img">
                                      <img src={marketinteldahboard} alt="Market Intelligence" className=" " />
                                    </div>
                                    <Link to={AllRoutes.competitionintpage} className="tab_link_btn">
                                      <button className="transparent-button">Deep Dive: Market Intelligence<span class="material-symbols-outlined">east</span></button>
                                    </Link>

                                  </Nav.Link>
                                </Nav.Item>

                              </Nav>
                            </Col>
                            <Col xl={7} className="second_div">
                              <Tab.Content>
                                <Tab.Pane eventKey="marketfirst1">
                                  <div className="investor_edge_div">
                                    <img src={marketinteldahboard} alt="Market Intelligence" />
                                  </div>
                                </Tab.Pane>

                              </Tab.Content>
                            </Col>
                          </Row>
                        </Tab.Container>
                        <Tab.Container defaultActiveKey="customerfirst1" >
                          <h2 className="text-center bigHead mt-7 mb-5">Customer Data Analytics</h2>

                          <Row className="">
                            <Col xl={5}>
                              <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                  <Nav.Link eventKey="customerfirst1">
                                    <p>BRIO's customer data analytics module delivers in-depth insights into consumer behaviour and preferences, utilising advanced modeling tools to enable businesses to customise their growth and customer satisfaction strategies. This precise targeting and understanding foster a more personalised customer experience, driving loyalty and competitive advantage.</p>
                                    <div className="investor_edge_div res_img">
                                      <img src={customerdatadahboard} alt="Customer Data" className=" " />
                                    </div>
                                    <Link to={AllRoutes.otherdriver} className="tab_link_btn">
                                      <button className="transparent-button">Deep Dive: Customer Data Analytics <span class="material-symbols-outlined">east</span></button>
                                    </Link>

                                  </Nav.Link>
                                </Nav.Item>

                              </Nav>
                            </Col>
                            <Col xl={7} className="second_div">
                              <Tab.Content>
                                <Tab.Pane eventKey="customerfirst1">
                                  <div className="investor_edge_div">
                                    <img src={customerdatadahboard} alt="Customer Data" />
                                  </div>
                                </Tab.Pane>

                              </Tab.Content>
                            </Col>
                          </Row>
                        </Tab.Container>
                        <Tab.Container defaultActiveKey="digitalfirst1" >
                          <h2 className="text-center bigHead mt-7 mb-5">Digital Shelf Analytics</h2>
                          <Row >
                            <Col xl={5}>
                              <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                  <Nav.Link eventKey="digitalfirst1">
                                    <p>BRIO transforms your approach to digital shelf management by offering comprehensive analytics solutions, revolutionising how you optimise your online presence. This shift allows for a more informed and strategic approach enabling you to tailor your digital footprint precisely to meet consumer demands and stand out in a crowded online marketplace.</p>
                                    <div className="investor_edge_div res_img">
                                      <img src={digitalshelfdahboard} alt="Digital Shelf" className=" " />
                                    </div>
                                    <Link to={AllRoutes.otherdriver} className="tab_link_btn">
                                      <button className="transparent-button">Deep Dive: Digital Shelf Analytics<span class="material-symbols-outlined">east</span></button>
                                    </Link>

                                  </Nav.Link>
                                </Nav.Item>

                              </Nav>
                            </Col>
                            <Col xl={7} className="second_div">
                              <Tab.Content>
                                <Tab.Pane eventKey="digitalfirst1">
                                  <div className="investor_edge_div">
                                    <img src={digitalshelfdahboard} alt="Digital Shelf" />
                                  </div>
                                </Tab.Pane>

                              </Tab.Content>
                            </Col>
                          </Row>
                        </Tab.Container>
                        {/* first accordian  for retailers end */}
                      </Tab.Pane>
                      <Tab.Pane eventKey="mainthird">
                        {/* automated pricing start */}
                        <Tab.Container defaultActiveKey="marthird" >
                          <h2 className="text-center bigHead mb-5">Discount Management</h2>
                          <Row className="">
                            <Col xl={5}>
                              <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                  <Nav.Link eventKey="marthird">
                                    <p>Empower your strategy with BRIO's comprehensive discount management system, allowing you to monitor competitor prices, assortment, and sales rank positioning in real time. BRIO equips your marketing and category teams to track the share of search and content quality score, providing a complete competitive landscape analysis.</p>
                                    <div className="investor_edge_div res_img">
                                      <img src={discountmgmt} alt="Discount Management" className=" " />
                                    </div>
                                    <Link to={AllRoutes.otherdriver} className="tab_link_btn">
                                      <button className="transparent-button">Deep Dive: Discount Management <span class="material-symbols-outlined">east</span></button>
                                    </Link>

                                  </Nav.Link>
                                </Nav.Item>

                              </Nav>
                            </Col>
                            <Col xl={7} className="second_div">
                              <Tab.Content>
                                <Tab.Pane eventKey="marthird">
                                  <div className="investor_edge_div">
                                    <img src={discountmgmt} alt="Discount Management" />
                                  </div>
                                </Tab.Pane>

                              </Tab.Content>
                            </Col>
                          </Row>
                        </Tab.Container>
                        <Tab.Container defaultActiveKey="marfirst">
                          <h2 className="text-center bigHead mt-5  mb-5">Automated Pricing</h2>
                          <Row>
                            <Col xl={5}>
                              <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                  <Nav.Link eventKey="marfirst">
                                    <p>BRIO leads the way with its cutting-edge retail automated pricing tool, offering advanced price optimizations that seamlessly navigates market fluctuations. By harnessing innovation and machine learning, it provides e-commerce businesses with strategic and adaptive pricing strategy for maximum profit.</p>
                                    <div className="investor_edge_div res_img">
                                      <img src={automationdahboard} alt="Automated Pricing" className=" " />
                                    </div>
                                    <Link to={AllRoutes.automatedpricingpage} className="tab_link_btn">
                                      <button className="transparent-button">Deep Dive: Automated Pricing <span class="material-symbols-outlined">east</span></button>
                                    </Link>

                                  </Nav.Link>
                                </Nav.Item>

                              </Nav>
                            </Col>
                            <Col xl={7} className="second_div">
                              <Tab.Content>
                                <Tab.Pane eventKey="marfirst">
                                  <div className="investor_edge_div">
                                    <img src={automationdahboard} alt="Automated Pricing" />
                                  </div>
                                </Tab.Pane>

                              </Tab.Content>
                            </Col>
                          </Row>
                        </Tab.Container>
                        <Tab.Container defaultActiveKey="marsec" >
                          <h2 className="text-center bigHead mt-7 mb-5">Market Intelligence</h2>
                          <Row className="">
                            <Col xl={5}>
                              <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                  <Nav.Link eventKey="marsec">
                                    <p>BRIO’s most robust competition tracking system stays leaps ahead by monitoring not just competitor prices, but also their assortment and sales rank positioning in near-real time. With BRIO, your marketing and category teams can easily track and compare the share of search and content quality scores, ensuring a holistic view of the competitive landscape.</p>
                                    <div className="investor_edge_div res_img">
                                      <img src={marketinteldahboard} alt="Market Intelligence" className=" " />
                                    </div>
                                    <Link to={AllRoutes.competitionintpage} className="tab_link_btn">
                                      <button className="transparent-button">Deep Dive: Market Intelligence <span class="material-symbols-outlined">east</span></button>
                                    </Link>

                                  </Nav.Link>
                                </Nav.Item>

                              </Nav>
                            </Col>
                            <Col xl={7} className="second_div">
                              <Tab.Content>
                                <Tab.Pane eventKey="marsec">
                                  <div className="investor_edge_div">
                                    <img src={marketinteldahboard} alt="Market Intelligence" />
                                  </div>
                                </Tab.Pane>

                              </Tab.Content>
                            </Col>
                          </Row>
                        </Tab.Container>
                      
                        {/* market intelligence end */}
                        {/* first accordian  for brands end */}
                      </Tab.Pane>
                      <Tab.Pane eventKey="mainsecond">
                        {/* for retailers start */}
                        <Tab.Container defaultActiveKey="first2">
                          <h2 className="text-center bigHead  mb-5">Automated Pricing</h2>
                          <Row>
                            <Col xl={5}>
                              <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                  <Nav.Link eventKey="first2">
                                    <p>BRIO leads the way with its cutting-edge retail automated pricing tool, offering advanced price optimizations that seamlessly navigates market fluctuations. By harnessing innovation and machine learning, it provides e-commerce businesses with strategic and adaptive pricing strategy for maximum profit.</p>
                                    <div className="investor_edge_div res_img">
                                      <img src={automationdahboard} alt="Automated Pricing" className=" " />
                                    </div>
                                    <Link to={AllRoutes.automatedpricingpage} className="tab_link_btn">
                                      <button className="transparent-button">Deep Dive: Automated Pricing<span class="material-symbols-outlined">east</span></button>
                                    </Link>

                                  </Nav.Link>
                                </Nav.Item>

                              </Nav>
                            </Col>
                            <Col xl={7} className="second_div">
                              <Tab.Content>
                                <Tab.Pane eventKey="first2">
                                  <div className="investor_edge_div">
                                    <img src={automationdahboard} alt="Automated Pricing" />
                                  </div>
                                </Tab.Pane>

                              </Tab.Content>
                            </Col>
                          </Row>
                        </Tab.Container>
                        <Tab.Container defaultActiveKey="marketfirst2" >
                          <h2 className="text-center bigHead mt-7 mb-5">Market Intelligence</h2>
                          <Row className="">
                            <Col xl={5}>
                              <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                  <Nav.Link eventKey="marketfirst2">
                                    <p>With BRIO’s most robust competition tracking system stay leaps ahead by monitoring not just competitor prices, but also their assortment and sales rank positioning in near-real time. With BRIO, your marketing and category teams can easily track and compare share of search and content quality score, ensuring a holistic view of the competitive landscape.</p>
                                    <div className="investor_edge_div res_img">
                                      <img src={marketinteldahboard} alt="Market Intelligence" className=" " />
                                    </div>
                                    <Link to={AllRoutes.competitionintpage} className="tab_link_btn">
                                      <button className="transparent-button">Deep Dive: Market Intelligence<span class="material-symbols-outlined">east</span></button>
                                    </Link>

                                  </Nav.Link>
                                </Nav.Item>

                              </Nav>
                            </Col>
                            <Col xl={7} className="second_div">
                              <Tab.Content>
                                <Tab.Pane eventKey="marketfirst2">
                                  <div className="investor_edge_div">
                                    <img src={marketinteldahboard} alt="Market Intelligence" />
                                  </div>
                                </Tab.Pane>

                              </Tab.Content>
                            </Col>
                          </Row>
                        </Tab.Container>
                        <Tab.Container defaultActiveKey="salesfirst" >
                          <h2 className="text-center bigHead mt-7 mb-5">Sales and Inventory Analytics</h2>
                          <Row className="">
                            <Col xl={5}>
                              <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                  <Nav.Link eventKey="salesfirst">
                                    <p>This module provides businesses with real-time visibility into sales performance and inventory status across multiple channels. With advanced forecasting capabilities and channel-specific insights, businesses can optimize inventory levels, anticipate demand fluctuations, and make data-driven decisions to drive growth effectively.</p>
                                    <div className="investor_edge_div res_img">
                                      <img src={customerdatadahboard} alt="Sales and Inventory Analytics" className=" " />
                                    </div>
                                    <Link to={AllRoutes.otherdriver} className="tab_link_btn">
                                      <button className="transparent-button">Deep Dive: Sales and Inventory Analytics<span class="material-symbols-outlined">east</span></button>
                                    </Link>

                                  </Nav.Link>
                                </Nav.Item>

                              </Nav>
                            </Col>
                            <Col xl={7} className="second_div">
                              <Tab.Content>
                                <Tab.Pane eventKey="salesfirst">
                                  <div className="investor_edge_div">
                                    <img src={customerdatadahboard} alt="Sales and Inventory Analytics" />
                                  </div>
                                </Tab.Pane>

                              </Tab.Content>
                            </Col>
                          </Row>
                        </Tab.Container>
                        {/* for retailers end */}
                      </Tab.Pane>

                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>


          </div>
        </section>
        <CapteraAchievements props={"whyus_captera"} />
        <RetailDatapoint />
        <Recoginition page_name="home_recog" />


        <ProductNumber />
        <MarketPlaceIntegration props={"ret_page"} end_point={end_point} />
        <RetailTestimonial />
        {/* <TestimonialSlider /> */}

        <section className="qa_store">
          <div className="container">
            <h2 className="bigHead text-center mb-5">What all is in store for you ?</h2>
            <div className="row">
              <div className="col-md-4">
                <InnerCard headImg={bgautomated} pageheading="Automated Pricing Engine" pagelink={AllRoutes.automatedpricingpage} />
              </div>
              {/* col-4 end */}
              <div className="col-md-4">
                <InnerCard headImg={bgcomp} pageheading="Competition Intelligence" pagelink={AllRoutes.competitionintpage} />

              </div>
              {/* col-4 end */}
              <div className="col-md-4">
                <InnerCard headImg={bggoal} pageheading="Goal-Based Pricing" pagelink={AllRoutes.goalbasedpage} />

              </div>
            </div>
          </div>
        </section>
        <section className="form-section retail_form">
          <div className="container">
            <div className="text-container">
              <h2 className="bigHead mb-5">Curious to know more about us!</h2>
              <p>Explore more about how our pricing experts  can scale your revenue to soaring heights just by pricing right.</p>
            </div>
            <div className="form-container">
              <Form {...formProps} />
            </div>
          </div>
        </section>
        </div>
      </HelmetProvider>
    </>
  );
}


export default Retail;