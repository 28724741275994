import Form from 'react-bootstrap/Form';
import "../../../assets/css/road_ahead_talks/create_webinar.css";
import { Button, CircularProgress } from '@mui/material';
import DraftsIcon from '@mui/icons-material/Drafts';
import PublishIcon from '@mui/icons-material/Publish';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Table from 'react-bootstrap/Table';
import { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import axios from 'axios';
import isUrl from 'is-url';
import { useNavigate } from "react-router-dom";
import DraftEditor from '../../../components/TextEditor/Editor';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { useParams } from 'react-router-dom';
import { stateToHTML } from 'draft-js-export-html';
import Tooltip from '@mui/material/Tooltip';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { fetchToken } from '../../../Auth';
import { adminURL } from '../../../global';

function EditWebinar() {

    const navigate = useNavigate();

    const { url } = useParams();

    const [showLoading, setShowLoading] = useState(true);

    const [webinarData, setWebinarData] = useState({
        id: null,
        title: "",
        publish_date: "",
        publish_time: "",
        reg_link: null,
        link_end_time: "",
        category: "",
        featured_experts: [],
        talks_recommendations: ["", "", ""],
        pre_img_alt_text: "",
        post_img_alt_text: "",
        youtube_link: "",
        spotify_link: "",
        page_title: "",
        pageURL: "",
        tags: "",
        meta_keywords: "",
        meta_desc: ""
    });

    const [webinarState, setWebinarState] = useState(null);

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const [preImage, setPreImage] = useState({
        reader: null,
        file: null
    });
    const [postImage, setPostImage] = useState({
        reader: null,
        file: null
    });

    const [imageChanged, setImageChanged] = useState({
        pre_img: false,
        post_img: false
    });

    const [featuredExpert, setFeaturedExpert] = useState({
        id: "",
        type: ""
    })

    const [featuredExperts, setFeaturedExperts] = useState([]);
    const [talksRecommendations, setTalksRecommendations] = useState([]);
    const get_featured_experts = async () => {
        try {
            const response = await axios.get(`/ra_talks/get_featured_experts`, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (response.status === 200 && response.data !== undefined) {
                setFeaturedExperts(response.data)
                console.log(response.data);
            }
        } catch (error) {
            if (error.response && error.response.data) {
                alert(error.response.data.detail);
            }
            else {
                alert("Unrecognised error occurred. Please try again.");
            }
        }
    }
    const get_talks_recommendations = async () => {
        try {
            const response = await axios.get(`/ra_talks/get_talks_recommendations`, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (response.status === 200 && response.data !== undefined) {
                setTalksRecommendations(response.data);
            }
        } catch (error) {
            if (error.response && error.response.data) {
                alert(error.response.data.detail);
            }
            else {
                alert("Unrecognised error occurred. Please try again.");
            }
        }
    }

    const [selectedTalks, setSelectedTalks] = useState({
        0: "",
        1: "",
        2: "",
    });

    const [talksRecommendationsCheck, setTalksRecommendationsCheck] = useState('auto');
    const [webinarRegCheck, setWebinarRegCheck] = useState('none');

    const displayRecOptions = [
        { name: 'Auto', value: 'auto' },
        { name: 'Manual', value: 'manual' }
    ];
    const webinarRegOptions = [
        { name: 'None', value: 'none' },
        { name: 'i-Frame URL', value: 'i-frame-url' }
    ];

    useEffect(() => {
        get_featured_experts();
        get_talks_recommendations();
    }, [])

    useEffect(() => {
        if (talksRecommendations.length > 0) {
            getWebinar(url);
        }
    }, [talksRecommendations])

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setWebinarData({ ...webinarData, [name]: value });
    }

    const handlePreImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreImage({ reader: reader.result, file: file });
            };
            reader.readAsDataURL(file);
        }
        setImageChanged({ ...imageChanged, pre_img: true });
    }
    const handlePostImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPostImage({ reader: reader.result, file: file });
            };
            reader.readAsDataURL(file);
        }
        setImageChanged({ ...imageChanged, post_img: true });
    }

    const handleAddFeaturedExpert = () => {
        if (featuredExpert.id === "") {
            return alert("Select an expert");
        }
        else if (featuredExpert.type === "") {
            return alert("Select type of expert");
        }

        // validation for already added expert
        const isAlreadyAdded = webinarData.featured_experts.some(e => e.id === featuredExpert.id);
        if (isAlreadyAdded) {
            return alert("Selected expert is already added");
        }

        // push new featured expert item into array
        const newWebinarData = { ...webinarData };
        const new_featured_experts = [...newWebinarData.featured_experts];
        featuredExpert.id = parseInt(featuredExpert.id);
        new_featured_experts.push(featuredExpert);
        newWebinarData.featured_experts = new_featured_experts
        setWebinarData(newWebinarData);

        // make select empty
        setFeaturedExpert({
            id: "",
            type: ""
        })
    }

    const handleRemoveExpert = (id) => {
        const newWebinarData = { ...webinarData };
        const new_featured_experts = newWebinarData.featured_experts.filter(e => e.id !== id);
        newWebinarData.featured_experts = new_featured_experts;
        setWebinarData(newWebinarData);
    }

    const handleCategorySelect = (fieldNumber, value) => {
        setSelectedTalks(prevState => ({
            ...prevState,
            [fieldNumber]: value,
        }));
    };

    const handleOnTalksRecommendationChange = (index, value) => {
        const newWebinarData = { ...webinarData };
        const new_talks_recommendations = [...newWebinarData.talks_recommendations];
        new_talks_recommendations[index] = value;
        newWebinarData.talks_recommendations = new_talks_recommendations;
        setWebinarData(newWebinarData);
    }

    const getWebinar = async (url) => {
        try {
            const response = await axios.get(`/ra_talks/get_webinar/${url}`, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                const response_data = response.data;

                const id = response_data.id;
                const title = response_data.title;
                const publish_date = response_data.publish_date;
                const publish_time = response_data.publish_time;
                const reg_link = response_data.reg_link;
                const link_end_time = response_data.link_end_time;
                const category = response_data.category;
                const featured_experts = response_data.featured_experts;
                const talks_recommendations = response_data.talks_recommendations == null ? ["", "", ""] : response_data.talks_recommendations;
                const pre_img_alt_text = response_data['pre-img']['alt'];
                const post_img_alt_text = response_data['post-img']['alt'];
                const youtube_link = response_data.video_links.youtube_link;
                const spotify_link = response_data.video_links.spotify_link;
                const page_title = response_data.seo.page_title;
                const pageURL = response_data.seo.pageURL;
                const tags = response_data.seo.tags.join(", ");
                const meta_keywords = response_data.seo.meta_keywords.join(", ");
                const meta_desc = response_data.seo.meta_desc;
                const updated_webinar_data = {
                    id: id,
                    title: title,
                    publish_date: publish_date,
                    publish_time: publish_time,
                    reg_link: reg_link,
                    link_end_time: link_end_time,
                    category: category,
                    featured_experts: featured_experts,
                    talks_recommendations: talks_recommendations,
                    pre_img_alt_text: pre_img_alt_text,
                    post_img_alt_text: post_img_alt_text,
                    youtube_link: youtube_link,
                    spotify_link: spotify_link,
                    page_title: page_title,
                    pageURL: pageURL,
                    tags: tags,
                    meta_keywords: meta_keywords,
                    meta_desc: meta_desc
                }
                setWebinarData(updated_webinar_data);

                // set registration link
                if (response_data.reg_link == null) {
                    setWebinarRegCheck('none');
                }
                else {
                    setWebinarRegCheck('i-frame-url');
                }

                // set description
                const contentState = stateFromHTML(response_data.desc);
                const editorStateFromHTML = EditorState.createWithContent(contentState);
                setEditorState(editorStateFromHTML);

                // set recommended road ahead talks
                if (response_data.talks_recommendations == null) {
                    setTalksRecommendationsCheck('auto');
                }
                else {
                    setTalksRecommendationsCheck('manual');
                }

                // set images
                const pre_img = response_data['pre-img']['pre-img-key'];
                setPreImage({ reader: pre_img });
                const post_img = response_data['post-img']['post-img-key'];
                setPostImage({ reader: post_img });

                if (talks_recommendations != null) {
                    const categories = {};
                    talksRecommendations.forEach(item => {
                        categories[item.id] = item.category;
                    });

                    const categoriesAssociated = talks_recommendations.map(id => categories[id]);

                    setSelectedTalks({
                        0: categoriesAssociated[0],
                        1: categoriesAssociated[1],
                        2: categoriesAssociated[2]
                    });
                }

                setWebinarState(response_data.state);
                setShowLoading(false);

            }
        } catch (error) {
            if (error.response && error.response.data) {
                alert(error.response.data.detail);
            }
            else {
                alert("Unrecognised error occurred. Please try again.");
            }
        }
    }

    const handleCancelImage = (image) => {
        if (image === 'pre') {
            setPreImage({ reader: null, file: null });
            document.getElementById('pre_img').value = '';
        }
        else {
            setPostImage({ reader: null, file: null });
            document.getElementById('post_img').value = '';
        }
    }

    const numericalValidator = (str) => {
        const numericalRegex = /^[0-9]+$/;
        return numericalRegex.test(str);
    };

    const handlePublish = async () => {

        // client side validations
        const title = webinarData.title.trim();
        if (title === "") {
            return alert("Webinar title is required");
        }
        if (title.length < 4) {
            return alert("Minimum 4 characters are required in webinar title");
        }
        if (numericalValidator(title)) {
            return alert("Webinar title cannot be numerical");
        }

        const publish_date = webinarData.publish_date;
        if (publish_date === "") {
            return alert("Webinar publish date is required");
        }

        const publish_time = webinarData.publish_time;
        if (publish_time === "") {
            return alert("Webinar publish time is required");
        }

        let reg_link = null;
        let link_end_time = null;
        if (webinarRegCheck === "i-frame-url") {
            reg_link = webinarData.reg_link.trim();
            if (reg_link === "") {
                return alert("Registration link is required");
            }
            if (numericalValidator(reg_link)) {
                return alert("Registration link cannot be numerical");
            }
            link_end_time = webinarData.link_end_time;
            if (link_end_time === "") {
                return alert("Link end time is required");
            }
        }

        const category = webinarData.category;
        if (category === "") {
            return alert("Webinar category is required");
        }

        const webinarDescContent = editorState.getCurrentContent();
        const webinarDescPlainText = webinarDescContent.getPlainText();
        if (webinarDescPlainText.trim() == "") {
            return alert("Webinar description is required");
        }
        if (numericalValidator(webinarDescPlainText)) {
            return alert("Webinar description cannot be numerical");
        }
        const webinarDescHTML = stateToHTML(webinarDescContent);

        const featured_experts = webinarData.featured_experts;
        if (featured_experts.length === 0) {
            return alert("At least one featured expert is required");
        }

        let talks_recommendations = null;
        if (talksRecommendationsCheck === "manual") {
            talks_recommendations = webinarData.talks_recommendations;
            const nonEmptyArr = talks_recommendations.filter(talk => talk !== '');
            if (nonEmptyArr.length !== talks_recommendations.length) {
                return alert("Select all recommended road ahead talks");
            }
            const set = new Set(nonEmptyArr);
            const isRepeated = nonEmptyArr.length !== set.size;
            if (isRepeated) {
                return alert("Duplicate entry for recommended road ahead talks");
            }
        }

        const pre_img_file = preImage.file;
        if (imageChanged.pre_img && pre_img_file === null) {
            return alert("Image for pre-webinar display is required");
        }
        const pre_img_alt_text = webinarData.pre_img_alt_text.trim();
        if (pre_img_alt_text === "") {
            return alert("Alt text for pre-webinar image is required");
        }
        if (numericalValidator(pre_img_alt_text)) {
            return alert("Alt text for pre-webinar image cannot be numerical");
        }

        const post_img_file = postImage.file;
        const post_img_alt_text = webinarData.post_img_alt_text.trim();

        const youtube_link = webinarData.youtube_link.trim();
        if (youtube_link !== "" && !isUrl(youtube_link)) {
            return alert("Invalid YouTube video link")
        }

        const spotify_link = webinarData.spotify_link.trim();
        // if (spotify_link !== "" && !isUrl(spotify_link)) {
        //     return alert("Invalid Spotify link")
        // }

        const pageURL = webinarData.pageURL.trim();
        if (pageURL === "") {
            return alert("Page URL is required");
        }
        if ((/\s/).test(pageURL)) {
            return alert("Page URL should not contain any spaces");
        }
        if (numericalValidator(pageURL)) {
            return alert("Page URL cannot be numerical");
        }

        const page_title = webinarData.page_title.trim();
        if (page_title === "") {
            return alert("Webinar page title is required");
        }
        if (numericalValidator(page_title)) {
            return alert("Webinar page title cannot be numerical");
        }

        if (webinarData.tags.trim() === "") {
            return alert("Webinar tags are required");
        }
        const tags = webinarData.tags.split(',').map(tag => tag.trim());

        let hasNumericalValue = tags.some(tag => numericalValidator(tag));
        if (hasNumericalValue) {
            return alert("Tags cannot be numerical");
        }

        if (webinarData.meta_keywords.trim() === "") {
            return alert("Webinar meta keywords are required");
        }
        const meta_keywords = webinarData.meta_keywords.split(',').map(keyword => keyword.trim());

        hasNumericalValue = meta_keywords.some(keyword => numericalValidator(keyword));
        if (hasNumericalValue) {
            return alert("Webinar meta keywords cannot be numerical");
        }

        const meta_desc = webinarData.meta_desc.trim();
        if (meta_desc === "") {
            return alert("Webinar meta desription is requried");
        }
        if(numericalValidator(meta_desc)) {
            return alert("Webinar meta description cannot be numerical");
        }

        const currentDate = new Date();
        // if past talk => post webinar display fields are required
        const dateTimeString = `${publish_date}T${publish_time}:00`;
        const WebinarDateTime = new Date(dateTimeString);
        // if (WebinarDateTime < currentDate && (youtube_link == "" || spotify_link == "" || postImage.reader == null || post_img_alt_text == "")) {
        //     return alert("Post webinar display fields are required for past webinar");
        // }

        // prepare data
        let webinarPostData = {
            id: webinarData.id,
            title: title,
            state: "publish",
            publish_date: publish_date,
            publish_time: publish_time,
            reg_link: reg_link,
            link_end_time: link_end_time,
            category: category,
            desc: webinarDescHTML,
            featured_experts: featured_experts,
            talks_recommendations: talks_recommendations,
            pre_img_alt_text: pre_img_alt_text,
            post_img_alt_text: post_img_alt_text,
            video_links: {
                youtube_link: youtube_link,
                spotify_link: spotify_link
            },
            seo: {
                page_title: page_title,
                pageURL: pageURL,
                tags: tags,
                meta_keywords: meta_keywords,
                meta_desc: meta_desc
            },
            created_at: currentDate.toString()
        }
        const formData = new FormData();
        imageChanged.pre_img && pre_img_file != null && formData.append('pre_img', pre_img_file);
        imageChanged.post_img && post_img_file != null && formData.append('post_img', post_img_file);
        formData.append('data', JSON.stringify(webinarPostData));
        try {
            const response = await axios.put(`/ra_talks/edit_webinar/${url}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${fetchToken('nuprice_access_token')}`
                },
            });
            if (response.status === 200 && response.data !== undefined) {
                alert('Webinar Updated Successfully');
                navigate(`/niyantran-panel/${adminURL}/road-ahead-talks-management`);
            }
        } catch (error) {
            if (error.response && error.response.data) {
                alert(error.response.data.detail);
            }
            else {
                alert("Unrecognised error occurred. Please try again.");
            }
        }
    }

    const handleSaveDraft = async () => {
        const title = webinarData.title.trim();
        if (title === "") {
            return alert("Webinar title is required");
        }
        if (title.length < 4) {
            return alert("Minimum 4 characters are required in webinar title");
        }

        const publish_date = webinarData.publish_date;
        // if (publish_date === "") {
        //     return alert("Webinar publish date is required");
        // }

        const publish_time = webinarData.publish_time;
        // if (publish_time === "") {
        //     return alert("Webinar publish time is required");
        // }

        let reg_link = null;
        if (webinarRegCheck === "i-frame-url") {
            reg_link = webinarData.reg_link.trim();
            // if (reg_link === "") {
            //     return alert("Registration link is required");
            // }
        }

        const category = webinarData.category;
        // if (category === "") {
        //     return alert("Webinar category is required");
        // }

        const webinarDescContent = editorState.getCurrentContent();
        const webinarDescPlainText = webinarDescContent.getPlainText();
        // if (webinarDescPlainText.trim() == "") {
        //     return alert("Webinar description is required");
        // }
        const webinarDescHTML = stateToHTML(webinarDescContent);

        const featured_experts = webinarData.featured_experts;
        // if (featured_experts.length === 0) {
        //     return alert("At least one featured expert is required");
        // }

        let talks_recommendations = null;
        if (talksRecommendationsCheck === "manual") {
            talks_recommendations = webinarData.talks_recommendations;
            const nonEmptyArr = talks_recommendations.filter(talk => talk !== '');
            // if (nonEmptyArr.length !== talks_recommendations.length) {
            //     return alert("Select all recommended road ahead talks");
            // }
            const set = new Set(nonEmptyArr);
            const isRepeated = nonEmptyArr.length !== set.size;
            if (isRepeated) {
                return alert("Duplicate entry for recommended road ahead talks");
            }
        }

        const pre_img_file = preImage.file;
        // if (imageChanged.pre_img && pre_img_file === null) {
        //     return alert("Image for pre-webinar display is required");
        // }
        const pre_img_alt_text = webinarData.pre_img_alt_text.trim();
        // if (pre_img_alt_text === "") {
        //     return alert("Alt text for pre-webinar image is required");
        // }

        const post_img_file = postImage.file;
        const post_img_alt_text = webinarData.post_img_alt_text.trim();

        const youtube_link = webinarData.youtube_link.trim();
        if (youtube_link !== "" && !isUrl(youtube_link)) {
            return alert("Invalid YouTube video link")
        }

        const spotify_link = webinarData.spotify_link.trim();
        if (spotify_link !== "" && !isUrl(spotify_link)) {
            return alert("Invalid Spotify link")
        }

        const pageURL = webinarData.pageURL.trim();
        if (pageURL === "") {
            return alert("Page URL is required");
        }
        if ((/\s/).test(pageURL)) {
            return alert("Page URL should not contain any spaces");
        }

        const page_title = webinarData.page_title.trim();
        // if (page_title === "") {
        //     return alert("Webinar page title is required");
        // }

        // if (webinarData.tags.trim() === "") {
        //     return alert("Webinar tags are required");
        // }
        const tags = webinarData.tags.split(',').map(tag => tag.trim());

        // if (webinarData.meta_keywords.trim() === "") {
        //     return alert("Webinar meta keywords are required");
        // }
        const meta_keywords = webinarData.meta_keywords.split(',').map(keyword => keyword.trim());

        const meta_desc = webinarData.meta_desc.trim();
        // if (meta_desc === "") {
        //     return alert("Webinar meta desription is requried");
        // }

        const currentDate = new Date();
        // if past talk => post webinar display fields are required
        // const dateTimeString = `${publish_date}T${publish_time}:00`;
        // const WebinarDateTime = new Date(dateTimeString);
        // if (WebinarDateTime < currentDate && (youtube_link == "" || spotify_link == "" || post_img_file == null || post_img_alt_text == "")) {
        //     return alert("Post webinar display fields are required for past webinar");
        // }

        // prepare data
        let webinarPostData = {
            title: title,
            state: "draft",
            publish_date: publish_date,
            publish_time: publish_time,
            reg_link: reg_link,
            category: category,
            desc: webinarDescHTML,
            featured_experts: featured_experts,
            talks_recommendations: talks_recommendations,
            pre_img_alt_text: pre_img_alt_text,
            post_img_alt_text: post_img_alt_text,
            video_links: {
                youtube_link: youtube_link,
                spotify_link: spotify_link
            },
            seo: {
                page_title: page_title,
                pageURL: pageURL,
                tags: tags,
                meta_keywords: meta_keywords,
                meta_desc: meta_desc
            },
            created_at: currentDate.toString()
        }
        const formData = new FormData();
        imageChanged.pre_img && pre_img_file != null && formData.append('pre_img', pre_img_file);
        imageChanged.post_img && post_img_file != null && formData.append('post_img', post_img_file);
        formData.append('data', JSON.stringify(webinarPostData));
        try {
            const response = await axios.put(`/ra_talks/edit_webinar/${url}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${fetchToken('nuprice_access_token')}`
                },
            });
            if (response.status === 200 && response.data !== undefined) {
                alert('Webinar Updated Successfully');
                navigate(`/niyantran-panel/${adminURL}/road-ahead-talks-management`);
            }
        } catch (error) {
            if (error.response && error.response.data) {
                alert(error.response.data.detail);
            }
            else {
                alert("Unrecognised error occurred. Please try again.");
            }
        }
    }

    return (
        <>
            <section className='w-100 row p-2'>
                <div className='admin-card corner row p-3 shadow-2 w-100'>
                    <div className='col-1'>
                        <IconButton onClick={() => navigate(`/niyantran-panel/${adminURL}/road-ahead-talks-management`)}>
                            <ArrowBackIcon />
                        </IconButton>
                    </div>
                    <div className='col-8'>
                        <h1 className='bold '>Edit Webinar</h1>
                    </div>
                </div>
                {
                    showLoading ?
                        <div>
                            <CircularProgress className='position-absolute top-50 start-50' />
                        </div>
                        :
                        <>
                            <div className="row mt-4">
                                <div className="col-8 d-flex flex-column gap-3">
                                    <div className="white-bg rounded py-2 px-3 shadow-sm">
                                        <Form.Group className="mb-3" controlId="title">
                                            <Form.Label className="med-font ml-1">Webinar Title</Form.Label>
                                            <Tooltip title="Webinar's topic or theme" arrow placement="right">
                                                <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                                            </Tooltip>
                                            <Form.Control
                                                type="text"
                                                name="title"
                                                value={webinarData.title}
                                                onChange={handleOnChange}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="white-bg rounded py-2 px-3 shadow-sm">
                                        <Form.Group className="mb-3" controlId="date">
                                            <Form.Label className="med-font ml-1">Publish Date</Form.Label>
                                            <Tooltip title="Choose webinar publish date. A countdown timer will be displayed until the webinar starts." arrow placement="right">
                                                <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                                            </Tooltip>
                                            <Form.Control
                                                type="date"
                                                name="publish_date"
                                                value={webinarData.publish_date}
                                                onChange={handleOnChange}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="white-bg rounded py-2 px-3 shadow-sm">
                                        <Form.Group className="mb-3" controlId="publish_time">
                                            <Form.Label className="med-font ml-1">Publish Time</Form.Label>
                                            <Tooltip title="Choose webinar publish time. A countdown timer will be displayed until the webinar starts." arrow placement="right">
                                                <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                                            </Tooltip>
                                            <Form.Control
                                                type="time"
                                                name="publish_time"
                                                value={webinarData.publish_time}
                                                onChange={handleOnChange}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="white-bg rounded py-2 px-3 shadow-sm">
                                        <Form.Group name="end_time"
                                            value={webinarData.end_time}
                                            onChange={handleOnChange} className="mb-3" controlId="reg_link">
                                            <Form.Label className="med-font ml-1">Webinar Registration Link</Form.Label>
                                            <Tooltip title="Select 'i-frame URL' to include a registration link displayed within an embedded frame on the webinar page. The link's visibility can be controlled by setting the end time." arrow placement="right">
                                                <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                                            </Tooltip>
                                            <div>
                                                <ButtonGroup className='w-30 mb-2'>
                                                    {webinarRegOptions.map((option, idx) => (
                                                        <ToggleButton
                                                            key={idx}
                                                            id={`radio-reg-link-${idx}`}
                                                            type="radio"
                                                            name="web_reg_link_radio"
                                                            value={option.value}
                                                            checked={webinarRegCheck === option.value}
                                                            onChange={(e) => setWebinarRegCheck(e.currentTarget.value)}
                                                        >
                                                            {option.name}
                                                        </ToggleButton>
                                                    ))}
                                                </ButtonGroup>
                                                {
                                                    webinarRegCheck === 'i-frame-url' &&
                                                    <>
                                                        <Form.Control
                                                            className='mb-2'
                                                            type="text"
                                                            name="reg_link"
                                                            placeholder='Link'
                                                            value={webinarData.reg_link}
                                                            onChange={handleOnChange}
                                                        />
                                                        <span>Link end time</span>
                                                        <Form.Control
                                                            type="time"
                                                            name="link_end_time"
                                                            value={webinarData.link_end_time}
                                                            onChange={handleOnChange}
                                                        />
                                                    </>
                                                }
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="white-bg rounded py-2 px-3 shadow-sm">
                                        <Form.Group className="mb-3" controlId="category">
                                            <Form.Label className="med-font ml-1">Webinar Category</Form.Label>
                                            <Tooltip title="Choose the category that best describes your webinar's topic or focus" arrow placement="right">
                                                <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                                            </Tooltip>
                                            <Form.Select
                                                aria-label="select_category"
                                                name="category"
                                                value={webinarData.category}
                                                onChange={handleOnChange}
                                            >
                                                <option value="">Select Category</option>
                                                <option value="air-travel">Air - Travel</option>
                                                <option value="bus-travel">Bus - Travel</option>
                                                <option value="retail">Retail</option>
                                                <option value="customer-success">Customer Success</option>
                                                <option value="pricing">Pricing</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                    <div className="white-bg rounded py-2 px-3 shadow-sm">
                                        <Form.Group className="mb-3" controlId="page_URL">
                                            <Form.Label className="med-font ml-1">Webinar Description</Form.Label>
                                            <Tooltip title="Provide a detailed description of your webinar" arrow placement="right">
                                                <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                                            </Tooltip>
                                            <DraftEditor editorState={editorState} setEditorState={setEditorState} />
                                        </Form.Group>
                                    </div>
                                    <div className="white-bg rounded py-2 px-3 shadow-sm">
                                        <Form.Group className="mb-3" controlId="page_URL">
                                            <Form.Label className="med-font ml-1">Add Featured Experts</Form.Label>
                                            <Tooltip title="Choose experts to feature them in your webinar as speakers or panelists. Multiple experts can be added." arrow placement="right">
                                                <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                                            </Tooltip>
                                            <div className="row">
                                                <div className="col">
                                                    <Form.Select
                                                        aria-label="select_featured_expert"
                                                        name="featured_expert_id"
                                                        value={featuredExpert.id}
                                                        onChange={(e) => setFeaturedExpert({ ...featuredExpert, id: e.target.value })}
                                                    >
                                                        <option value="">Select Expert</option>
                                                        {
                                                            featuredExperts.map(featuredExpert => (
                                                                <option key={featuredExpert.id} value={featuredExpert.id}>
                                                                    {featuredExpert.name}
                                                                </option>
                                                            ))
                                                        }
                                                    </Form.Select>
                                                </div>
                                                <div className="col">
                                                    <Form.Select
                                                        aria-label="select_featured_expert"
                                                        name="featured_expert_type"
                                                        value={featuredExpert.type}
                                                        onChange={(e) => setFeaturedExpert({ ...featuredExpert, type: e.target.value })}
                                                    >
                                                        <option value="">Select Type</option>
                                                        <option value="speaker">Speaker</option>
                                                        <option value="panelist">Panelist</option>
                                                        <option value="host">Host</option>
                                                    </Form.Select>
                                                </div>
                                                <div className="col-2">
                                                    <Button
                                                        variant="contained"
                                                        onClick={handleAddFeaturedExpert}
                                                    >
                                                        Add
                                                    </Button>
                                                </div>
                                            </div>
                                            {
                                                webinarData.featured_experts
                                                &&
                                                webinarData.featured_experts.length > 0
                                                &&
                                                <Table bordered hover className='mt-2 text-center'>
                                                    <thead>
                                                        <tr>
                                                            <th>No.</th>
                                                            <th>Expert</th>
                                                            <th>Type</th>
                                                            <th>Remove</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {webinarData.featured_experts.map((featured_expert, row) => {
                                                            const expert = featuredExperts.find(f => f.id === featured_expert.id);
                                                            // Check if expert exists before accessing its properties
                                                            if (expert) {
                                                                return (
                                                                    <tr key={featured_expert.id}>
                                                                        <td>{row + 1}</td>
                                                                        <td>{expert.name}</td>
                                                                        <td>{featured_expert.type}</td>
                                                                        <td>
                                                                            <DeleteIcon
                                                                                onClick={() => handleRemoveExpert(featured_expert.id)}
                                                                                sx={{ color: '#ff3333', cursor: 'pointer' }}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            } else {
                                                                // Handle the case where expert is undefined or not found
                                                                return null; // or display a placeholder or loading indicator
                                                            }
                                                        })}
                                                    </tbody>
                                                </Table>
                                            }
                                        </Form.Group>
                                    </div>
                                    <div className="white-bg rounded py-2 px-3 shadow-sm">
                                        <Form.Group className="mb-3">
                                            <Form.Label className="med-font ml-1">Display Recommended Road Ahead Talks</Form.Label>
                                            <Tooltip title="Choose between 'Auto' to display the three most recent webinars from the same category as your webinar, or 'Manual' to manually select any three webinars regardless of their categories to feature as recommendations." arrow placement="right">
                                                <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                                            </Tooltip>
                                            <div>
                                                <ButtonGroup className='w-30 mb-2'>
                                                    {displayRecOptions.map((radio, idx) => (
                                                        <ToggleButton
                                                            key={idx}
                                                            id={`radio-rec-display-${idx}`}
                                                            type="radio"
                                                            name="display_rec_radio"
                                                            value={radio.value}
                                                            checked={talksRecommendationsCheck === radio.value}
                                                            onChange={(e) => setTalksRecommendationsCheck(e.currentTarget.value)}
                                                        >
                                                            {radio.name}
                                                        </ToggleButton>
                                                    ))}
                                                </ButtonGroup>
                                                {
                                                    talksRecommendationsCheck === 'manual' &&
                                                    <div className="manual-display-section d-flex flex-column gap-2">
                                                        <div className="row">
                                                            <div className="col-1 d-flex justify-content-center align-items-center">
                                                                <span>#1</span>
                                                            </div>
                                                            <div className="col">
                                                                <Form.Select
                                                                    aria-label="select_category"
                                                                    name="recommended_talks_category_1"
                                                                    value={selectedTalks[0]}
                                                                    onChange={(e) => handleCategorySelect(0, e.target.value)}
                                                                >
                                                                    <option value="">Select Category</option>
                                                                    <option value="bus-travel">Travel - Bus</option>
                                                                    <option value="air-travel">Travel - Air</option>
                                                                    <option value="retail">Retail</option>
                                                                    <option value="customer-success">Customer Success</option>
                                                                    <option value="pricing">Pricing</option>
                                                                </Form.Select>
                                                            </div>
                                                            <div className="col">
                                                                <Form.Select
                                                                    aria-label="select_title"
                                                                    name="recommended_talks_title_1"
                                                                    value={webinarData.talks_recommendations[0]}
                                                                    onChange={(e) => handleOnTalksRecommendationChange(0, e.target.value)}
                                                                >
                                                                    <option>Select Talk</option>
                                                                    {talksRecommendations
                                                                        .filter(talk => talk.category === selectedTalks[0])
                                                                        .map(filteredTalk => (
                                                                            <option key={filteredTalk.id} value={filteredTalk.id}>
                                                                                {filteredTalk.title}
                                                                            </option>
                                                                        ))}
                                                                </Form.Select>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-1 d-flex justify-content-center align-items-center">
                                                                <span>#2</span>
                                                            </div>
                                                            <div className="col">
                                                                <Form.Select
                                                                    aria-label="select_category"
                                                                    name="recommended_talks_category_2"
                                                                    value={selectedTalks[1]}
                                                                    onChange={(e) => handleCategorySelect(1, e.target.value)}
                                                                >
                                                                    <option value="">Select Category</option>
                                                                    <option value="bus-travel">Travel - Bus</option>
                                                                    <option value="air-travel">Travel - Air</option>
                                                                    <option value="retail">Retail</option>
                                                                    <option value="customer-success">Customer Success</option>
                                                                    <option value="pricing">Pricing</option>
                                                                </Form.Select>
                                                            </div>
                                                            <div className="col">
                                                                <Form.Select
                                                                    aria-label="select_title"
                                                                    name="recommended_talks_title_2"
                                                                    value={webinarData.talks_recommendations[1]}
                                                                    onChange={(e) => handleOnTalksRecommendationChange(1, e.target.value)}
                                                                >
                                                                    <option>Select Talk</option>
                                                                    {talksRecommendations
                                                                        .filter(talk => talk.category === selectedTalks[1])
                                                                        .map(filteredTalk => (
                                                                            <option key={filteredTalk.id} value={filteredTalk.id}>
                                                                                {filteredTalk.title}
                                                                            </option>
                                                                        ))}
                                                                </Form.Select>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-1 d-flex justify-content-center align-items-center">
                                                                <span>#3</span>
                                                            </div>
                                                            <div className="col">
                                                                <Form.Select
                                                                    aria-label="select_category"
                                                                    name="recommended_talks_category_3"
                                                                    value={selectedTalks[2]}
                                                                    onChange={(e) => handleCategorySelect(2, e.target.value)}
                                                                >
                                                                    <option value="">Select Category</option>
                                                                    <option value="bus-travel">Travel - Bus</option>
                                                                    <option value="air-travel">Travel - Air</option>
                                                                    <option value="retail">Retail</option>
                                                                    <option value="customer-success">Customer Success</option>
                                                                    <option value="pricing">Pricing</option>
                                                                </Form.Select>
                                                            </div>
                                                            <div className="col">
                                                                <Form.Select
                                                                    aria-label="select_title"
                                                                    name="recommended_talks_title_3"
                                                                    value={webinarData.talks_recommendations[2]}
                                                                    onChange={(e) => handleOnTalksRecommendationChange(2, e.target.value)}
                                                                >
                                                                    <option>Select Talk</option>
                                                                    {talksRecommendations
                                                                        .filter(talk => talk.category === selectedTalks[2])
                                                                        .map(filteredTalk => (
                                                                            <option key={filteredTalk.id} value={filteredTalk.id}>
                                                                                {filteredTalk.title}
                                                                            </option>
                                                                        ))}
                                                                </Form.Select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="white-bg rounded py-2 px-3 shadow-sm">
                                        <Form.Group className="mb-3">
                                            <Form.Label className="med-font ml-1">Image for Pre-Webinar Display</Form.Label>
                                            <Tooltip title="Upload an image that will be displayed on the webinar page before the scheduled publish date and time." arrow placement="right">
                                                <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                                            </Tooltip>
                                            <Form.Control
                                                type="file"
                                                id="pre_img"
                                                name="pre_img"
                                                accept="image/png, image/jpeg"
                                                onChange={handlePreImageChange}
                                            />
                                            <Form.Label className="med-font ml-1 mt-3">Alt text for image</Form.Label>
                                            <Tooltip title="Enter alternative text (alt text) for the image." arrow placement="right">
                                                <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                                            </Tooltip>
                                            <Form.Control
                                                type="text"
                                                name="pre_img_alt_text"
                                                value={webinarData.pre_img_alt_text}
                                                onChange={handleOnChange}
                                            />
                                        </Form.Group>
                                        {
                                            preImage.reader &&
                                            <div className='d-flex'>
                                                <img src={preImage.reader} alt="" width={500} />
                                                <Tooltip title="Remove image" arrow placement="right">
                                                    <CancelRoundedIcon role="button" onClick={() => handleCancelImage('pre')} />
                                                </Tooltip>
                                            </div>
                                        }
                                    </div>
                                    <div className="white-bg rounded py-2 px-3 shadow-sm">
                                        <Form.Group className="mb-3">
                                            <div className='d-flex justify-content-between mb-1'>
                                                <div>
                                                    <Form.Label className="med-font ml-1 mb-0">Image for Post-Webinar Display</Form.Label>
                                                    <Tooltip title="Upload an image that will be displayed on the webinar page after the scheduled publish date and time." arrow placement="right">
                                                        <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                                                    </Tooltip>
                                                </div>
                                                <span className='tip py-1 px-2'>Post-Webinar Display</span>
                                            </div>
                                            <Form.Control
                                                type="file"
                                                id="post_img"
                                                name="post_img"
                                                accept="image/png, image/jpeg"
                                                onChange={handlePostImageChange}
                                            />
                                            <Form.Label className="med-font ml-1 mt-3">Alt text for image</Form.Label>
                                            <Tooltip title="Enter alternative text (alt text) for the image." arrow placement="right">
                                                <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                                            </Tooltip>
                                            <Form.Control
                                                type="text"
                                                name="post_img_alt_text"
                                                value={webinarData.post_img_alt_text}
                                                onChange={handleOnChange}
                                            />
                                        </Form.Group>
                                        {
                                            postImage.reader &&
                                            <div className='d-flex'>
                                                <img src={postImage.reader} alt="" width={500} />
                                                <Tooltip title="Remove image" arrow placement="right">
                                                    <CancelRoundedIcon role="button" onClick={() => handleCancelImage("post")} />
                                                </Tooltip>
                                            </div>
                                        }
                                    </div>
                                    <div className="white-bg rounded py-2 px-3 shadow-sm">
                                        <Form.Group className="mb-3" controlId="youtube_link">
                                            <div className='d-flex justify-content-between mb-1'>
                                                <div>
                                                    <Form.Label className="med-font ml-1 mb-0">Webinar YouTube Link</Form.Label>
                                                    <Tooltip title="Add a YouTube link that will be accessible to users after the webinar is published" arrow placement="right">
                                                        <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                                                    </Tooltip>
                                                </div>
                                                <span className='tip py-1 px-2'>Post-Webinar Display</span>
                                            </div>
                                            <Form.Control
                                                type="text"
                                                name="youtube_link"
                                                value={webinarData.youtube_link}
                                                onChange={handleOnChange}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="white-bg rounded py-2 px-3 shadow-sm">
                                        <Form.Group className="mb-3" controlId="spotify_link">
                                            <div className='d-flex justify-content-between mb-1'>
                                                <div>
                                                    <Form.Label className="med-font ml-1 mb-0">Webinar Spotify Link</Form.Label>
                                                    <Tooltip title="Add a Spotify link that will be accessible to users after the webinar is published" arrow placement="right">
                                                        <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                                                    </Tooltip>
                                                </div>
                                                <span className='tip py-1 px-2'>Post-Webinar Display</span>
                                            </div>
                                            <Form.Control
                                                type="text"
                                                name="spotify_link"
                                                value={webinarData.spotify_link}
                                                onChange={handleOnChange}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="col-4 d-flex flex-column gap-3">
                                    <div className="grey-blue-bg rounded py-2 px-3 shadow-sm">
                                        <Form.Group className="mb-3" controlId="page_title">
                                            <Form.Label className="med-font ml-1">SEO Title</Form.Label>
                                            <Tooltip title="This title will appear at the top of the browser tab and in search engine results." arrow placement="right">
                                                <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                                            </Tooltip>
                                            <Form.Control
                                                type="text"
                                                name="page_title"
                                                value={webinarData.page_title}
                                                onChange={handleOnChange}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="grey-blue-bg rounded py-2 px-3 shadow-sm">
                                        <Form.Group className="mb-3" controlId="pageURL">
                                            <Form.Label className="med-font ml-1">Page URL</Form.Label>
                                            <Tooltip title="Specify the endpoint for your webinar page. This is the unique identifier that forms part of the URL where users can access your webinar." arrow placement="right">
                                                <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                                            </Tooltip>
                                            <Form.Control
                                                type="text"
                                                name="pageURL"
                                                value={webinarData.pageURL}
                                                onChange={handleOnChange}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="grey-blue-bg rounded py-2 px-3 shadow-sm">
                                        <Form.Group className="mb-3" controlId="tags">
                                            <Form.Label className="med-font ml-1">Tags</Form.Label>
                                            <Tooltip title="Enter tags separated by commas to describe the key topics or themes of your webinar." arrow placement="right">
                                                <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                                            </Tooltip>
                                            <Form.Control
                                                type="text"
                                                name="tags"
                                                value={webinarData.tags}
                                                onChange={handleOnChange}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="grey-blue-bg rounded py-2 px-3 shadow-sm">
                                        <Form.Group className="mb-3" controlId="meta_keywords">
                                            <Form.Label className="med-font ml-1">Meta Keywords</Form.Label>
                                            <Tooltip title="Enter meta keywords separated by commas to improve search engine visibility. These keywords should reflect the main topics and content of your webinar." arrow placement="right">
                                                <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                                            </Tooltip>
                                            <Form.Control
                                                type="text"
                                                name="meta_keywords"
                                                value={webinarData.meta_keywords}
                                                onChange={handleOnChange}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="grey-blue-bg rounded py-2 px-3 shadow-sm">
                                        <Form.Group className="mb-3" controlId="meta_desc">
                                            <Form.Label className="med-font ml-1">Meta Description</Form.Label>
                                            <Tooltip title="Write a concise meta description that summarizes webinar's content. This description will appear in search engine results." arrow placement="right">
                                                <i className="fa-solid fa-circle-info ml-2 color-grey"></i>
                                            </Tooltip>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                name="meta_desc"
                                                value={webinarData.meta_desc}
                                                onChange={handleOnChange}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                            </div>
                            <div className="grey-blue-bg rounded d-flex justify-content-center gap-4 p-4 shadow-sm my-3">
                                {
                                    webinarState === 'draft' &&
                                    <Button
                                        variant="contained"
                                        startIcon={<DraftsIcon />}
                                        onClick={handleSaveDraft}
                                    >
                                        Save Draft
                                    </Button>
                                }
                                <Button
                                    variant="contained"
                                    startIcon={<PublishIcon />}
                                    onClick={handlePublish}
                                >
                                    Publish
                                </Button>
                            </div>
                        </>
                }
            </section>
        </>
    );
}

export default EditWebinar;