import { useEffect, useState } from "react";
import Footer from "../common/footer";
import Header from "../common/header";
import Ticker from "../common/ticker";
import { Button } from 'react-bootstrap';
import Slider from "react-slick";
import { toast } from 'react-toastify';
import sciativevideo from '../../assets/videos/Home_Sciative_Header-Video-1-1.mp4';
import aretza from "../../assets/img/retail-client/arteza.png";
import avis from "../../assets/img/retail-client/avis.png";
import clubmahindra from "../../assets/img/retail-client/club-mahindra.png";
import coupang from "../../assets/img/retail-client/coupang.png";
import croma from "../../assets/img/retail-client/croma.png";
import kisankonnect from "../../assets/img/retail-client/kisan-konnect.png";
import licious from "../../assets/img/retail-client/licious.png";
import logitech from "../../assets/img/retail-client/logitech.png";
import nykaaf from "../../assets/img/retail-client/nykaa-fashions.png";
import nykaa from "../../assets/img/retail-client/nykaa.png";
import shoppers from "../../assets/img/retail-client/shoppers-stop.png";
import star from "../../assets/img/retail-client/star.png";
import vijaysales from "../../assets/img/retail-client/vijay-sales.png";
import wonderchef from "../../assets/img/retail-client/wonderchef.png";

import essar from "../../assets/img/travel-client/essar.png";
import gotour from "../../assets/img/travel-client/gotour.png";
import jabbartravels from "../../assets/img/travel-client/jabbar-travels.png";
import kanker from "../../assets/img/travel-client/kanker.png";
import laxmiholidays from "../../assets/img/travel-client/laxmi-holidays.png";
import limoliner from "../../assets/img/travel-client/limoliner.png";
import neetabus from "../../assets/img/travel-client/neetabus.png";
import nuego from "../../assets/img/travel-client/nuego.png";
import rajeshtransport from "../../assets/img/travel-client/rajesh-transport.png";
import rajkalpana from "../../assets/img/travel-client/rajkalpana.png";
import shyamoli from "../../assets/img/travel-client/shyamoli.png";
import spsluxury from "../../assets/img/travel-client/sps-luxury.png";
import svkdt from "../../assets/img/travel-client/svkdt.png";
import tsrtc from "../../assets/img/travel-client/tsrtc.png";
import vigneshtravels from "../../assets/img/travel-client/vignesh-tat.png";
import yolobus from "../../assets/img/travel-client/yolobus.png";
import brio_img from "../../assets/img/home/retail.png";
import travel_img from "../../assets/img/home/travel.png";
import brio from "../../assets/img/home/brio.png";
import viaje from "../../assets/img/home/viaje.png";
import CapteraAchievements from '../travel/capterra-achievvement';
import FounderVideo from "../retail/founderVideo";
import Recoginition from "../retail/recognition";
// import gallery1 from '../../assets/img/about/gallery/1.png'
// import gallery2 from '../../assets/img/about/gallery/2.png'
// import gallery3 from '../../assets/img/about/gallery/3.png'
// import gallery4 from '../../assets/img/about/gallery/4.png'
// import gallery5 from '../../assets/img/about/gallery/5.png'
// import gallery6 from '../../assets/img/about/gallery/6.png'
import int1 from '../../assets/img/partner/integration_2/bitla.png'
import int2 from '../../assets/img/partner/integration_2/amazon.png'
import int3 from '../../assets/img/partner/integration_2/maven_tech_labs.png'
import int4 from '../../assets/img/partner/integration_2/flipkart.png'
import int5 from '../../assets/img/partner/integration_2/abhibus.png'
import int6 from '../../assets/img/partner/integration_2/shopify.png'
import int7 from '../../assets/img/partner/integration_2/yolobus.png'
import int8 from '../../assets/img/partner/integration_2/infinity.png'
import int9 from '../../assets/img/partner/integration_2/qwikbus_logo.webp'
import WhatMakesSciativeUnique from "../common/whatMakesSciativeUnique";
import casebg from "../../assets/img/home/casee-bg.jpg";

import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import RetailTestimonial from "../retail/retailTestimonial";
import Form from "../Forms/Form";
import ContactTemplate from "../common/contact";

import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import CloseIcon from '@mui/icons-material/Close';
import ClientTestimonial from "../ClientTestimonial";
import AllRoutes from "../../AllRoutes";
import AOS from "aos";
import '../../assets/css/home.css'
import "aos/dist/aos.css";

import { GlobalUrl, GlobalImg } from "../../global";
import AllClientSlider from "../common/allClientSlider";
import templates from "../../data/templates";
const Home = ({ }) => {
    useEffect(() => {
        fetch_all_case_studies();
        AOS.init();
        AOS.refresh();
       
    }, [])
    const gallery_1 = {
        className: "center",
        centerMode: true,
        dots: false,
        arrow: false,
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 4,
        // speed: 500,
        loop: true,
        speed: 5000,
        autoplay: true,
        autoplaySpeed: 5000,
        cssEase: "linear",
        rtl: true,
        // nextArrow: <SampleNextArrow />,
        // prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,

                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 2,

                }
            },
        ]

    };
    const retail_client = [
        {
            "image": avis,
            "alttext": "Avis rent a Car"
        },
        {
            "image": aretza,
            "alttext": "Arteza"
        },
        {
            "image": coupang,
            "alttext": "Coupang"
        },
        {
            "image": logitech,
            "alttext": "Logitech"
        },
        {
            "image": clubmahindra,
            "alttext": "Club Mahindra"
        },
        {
            "image": star,
            "alttext": "Star"
        },
        {
            "image": nykaaf,
            "alttext": "Nykaa Fashions"
        },
        {
            "image": shoppers,
            "alttext": "Shoppers Stop"
        },
        {
            "image": licious,
            "alttext": "Licious"
        },
        {
            "image": croma,
            "alttext": "Croma"
        },
        {
            "image": vijaysales,
            "alttext": "Vijay Sales"
        },
        {
            "image": nykaa,
            "alttext": "Nykaa"
        },
        {
            "image": kisankonnect,
            "alttext": "Kisan Konnect"
        },
        {
            "image": wonderchef,
            "alttext": "WonderChef"
        },


    ];
    const travel_client = [
        {
            "image": nuego,
            "alttext": "Nuego"
        },
        {
            "image": yolobus,
            "alttext": "YoloBus"
        },
        {
            "image": tsrtc,
            "alttext": "TSRTC"
        },
        {
            "image": laxmiholidays,
            "alttext": "Laxmi Holidays"
        },
        {
            "image": jabbartravels,
            "alttext": "Jabbar Travels"
        },
        {
            "image": neetabus,
            "alttext": "Neeta Bus"
        },
        {
            "image": essar,
            "alttext": "Essar"
        },
        {
            "image": shyamoli,
            "alttext": "Shyamoli"
        },
        {
            "image": spsluxury,
            "alttext": "SPS Luxury Travels"
        },
        {
            "image": kanker,
            "alttext": "Kanker Roadways"
        },
        {
            "image": rajeshtransport,
            "alttext": "Rajesh Transport"
        },
        {
            "image": rajkalpana,
            "alttext": "Raj Kalpana"
        },
        {
            "image": gotour,
            "alttext": "GoTour"
        },
        {
            "image": svkdt,
            "alttext": "SVKDT"
        },
        {
            "image": vigneshtravels,
            "alttext": "Vignesh TAT"
        },
        {
            "image": limoliner,
            "alttext": "Limoliner"
        },


    ];
    const all_clients = [...retail_client, ...travel_client]

    const formProps = {
        template: 4,
        form_name: 'book-a-demo-page-form',
        fields: [
            { name: 'name', required: true },
            { name: 'email', required: true },
            { name: 'company', required: true },
            { name: 'phone', required: true },
            { name: 'message', required: true },
        ],
        recipients_group_id: 1,
        handleApiResponse: (res) => {
            toast.dismiss();
            if (res.status == "success"){
                toast.success('Form submitted successfully! We will get back to you in 2-3 days.');
            } else {
                toast.error(res.data)
            }
        }
    }

    const [caseStudies, setCaseStudies] = useState([]);

    const fetch_all_case_studies = async () => {
        try {
            const response = await axios.get(`/fetch_all_case_studies`, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (response.status == 200 && response.data !== undefined) {
                setCaseStudies(response.data);
            }
        } catch (err) {

        }
    }

    const [pdfRequestModal, setPdfRequestModal] = useState(false);
    const [requestedFileKey, setRequestedFileKey] = useState('');

    const handlePdfRequest = (file_key) => {
        setPdfRequestModal(true);
        setRequestedFileKey(file_key);
    }

    const pdfRequestProps = {
        template: 1,
        form_name: "case-studies-pdf-request",
        fields: [
            { name: "name", required: true },
            { name: "email", required: true },
            { name: "phone", required: true }
        ],
        title: "See Sciative in Action & Drive Results",
        content_upper: "Make data-driven decisions with confidence. Dive into case studies and see how Sciative tackled challenges and delivered exceptional results. Get access to case studies and unlock exceptional insights that inspire you.",
        content_lower: "",
        recipients_group_id: 1,
        requestedFileKey: requestedFileKey,
        handleApiResponse: (res) => {
            toast.dismiss();
            if (res && res.status && res.status === "success") {
                window.open(res.data.signed_url);
                toast.success('Form submitted successfully!');
                setPdfRequestModal(false);
            }
            else {
                toast.error(res.data);
            }
        }
    }

    const contactExpertForm = {
        template: 5,
        form_name: 'contact-expert-form',
        fields: [
            { name: 'name', required: true },
            { name: 'email', required: true },
            { name: 'phone', required: true },
            { name: 'company', required: true },
            { name: 'message', required: true },
        ],
        recipients_group_id: 1,
        handleApiResponse: (res) => {
            toast.dismiss();
            if (res.status == "success"){
                toast.success('Form submitted successfully! We will get back to you in 2-3 days.');
            } else {
                toast.error(res.data)
            }
        }
    }
  
  
    const allclientsettings = {
        dots: false,
        infinite: true,
        slidesToShow: 7,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 1995,
        cssEase: "linear",
        pauseOnHover: false,
        responsive: [
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 3,
               
              }
            },
            {
                breakpoint: 1023,
                settings: {
                  slidesToShow: 5,
                 
                }
              },
        ]
      };

    return (
        <>
            <HelmetProvider>
                <Helmet> 
                    <title>Future of Pricing: AI Tech for Every Industry | Sciative</title>
                    <link rel="canonical" href={`${GlobalUrl}${AllRoutes.home}`} />
                    <meta charset="utf-8" />
                    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                    <meta name="csrf_token" content="" />
                    <meta property="type" content="website" />
                    <meta name="theme-color" content="#ffffff" />
                    <meta name="_token" content="" />
                    <meta name="robots" content="noodp" />
                    <meta name='description' content="Take charge of your pricing with real-time Ai pricing optimization. Sciative's Pricing Software with dynamic repricing tools are easy to deploy and use." />
                    <meta property="og:locale" content="en_US" /> 
                    <meta property="og:title" content="Future of Pricing: AI Tech for Every Industry | Sciative" />
                    <meta property="og:description" content="Take charge of your pricing with real-time Ai pricing optimization. Sciative's Pricing Software with dynamic repricing tools are easy to deploy and use. " />
         
                    <meta property="og:hashtag" content="#ai" />
                    <meta content="image/*" property="og:image:type" />
                    <meta property="og:url" content={`${GlobalUrl}${AllRoutes.home}`} />
                    <meta property="og:site_name" content="Sciative Solutions - We Price Right" />
                    <meta property="og:image"  content={GlobalImg} />
                    <meta property="og:type" content="website" />

                
                </Helmet>
                <section  className="banner_retail banner_home">
                    <div className="container" data-aos="new-animation">
                            <div className="row">
                                <div className="col-md-7-5">
                                <h1>Dynamic Pricing Software: Achieve the Right Prices with AI</h1>
                                <p>Take charge of your pricing today with real-time AI pricing optimization. Sciative's AI dynamic pricing tools are easy to deploy and use.</p>
                                <div>
                                    <Link to='/contact'>
                                        <button className="transparent-button">Curious to Know How We Do It <span class="material-symbols-outlined">trending_flat</span></button>
                                    </Link>
                                </div>
                                </div>
                                <div className="col-md-4-5 pe-0 ps-0 d-none d-lg-block">
                                    <Form {...contactExpertForm} />
                                </div>
                                <div className="my-0 p-0 text-center col-12">
                                    <video autoPlay loop muted playsInline>
                                        <source src={sciativevideo} type="video/mp4" />
                                        {/* <source src="movie.ogg" type="video/ogg"> */}
                                        Your browser does not support the video tag.
                                    </video>
                                <div className="mt-3 col-12 pe-0 ps-0 d-block d-lg-none">
                                    <Form {...contactExpertForm} />
                                </div>
                            </div>
                        </div>
                        
                        

                    </div>
                </section>
                <div className={"retail_slider  "}>
                <div className="inner_ret_slider "  >
                    <h2 className="bigHead text-center mb-3" data-aos="new-animation">Our Clients from Across the Globe</h2>
                    <Slider {...allclientsettings}>
                        {all_clients.map((item, index) => (
                            <div key={index.alttext} className="slider_card">
                            
                            <img src={item.image} alt={item.alttext}  />
                            </div>
                        ))}
                        </Slider>
                </div>
            </div>
                {/* <section className="all_client_home">
                 
                        <h2 className="bigHead text-center">
                            Trusted by Companies Around the Globe
                        </h2>
                        {/* <div className="row home_img_slider">
 
                            {all_clients.map(obj => (
                                <div key={obj.alttext} className="col-4 col-sm-2 col-xxl d-flex align-items-center justify-content-center">
                                    <img src={obj.image} alt={obj.alttext} />
                                </div>
                            ))}


                        </div>
                        <Slider {...allclientsettings}>
                        {all_clients.map((item, index) => (
                            <div key={index.alttext} className="slider_card">
                            
                            <img src={item.image} alt={item.alttext}  />
                            </div>
                        ))}
                        </Slider>
                   
                </section> */}
                {/* <AllClientSlider/> */}
                <section className="home_product" data-aos="new-animation">
                    <div className="container">
                        <h2 className="bigHead text-center mb-5">
                        World's 1st AI Dynamic Pricing <br className="d-none d-md-block" />Company Empowering 5 Billion Price Optimisations Daily Across Industries
                        </h2>
                        <div className="row">
                            <div className="col-md-6">
                                <Link to="/retail">
                                    <div className="card">
                                        <div className="bg_card bg_card_retail" >
                                            <p>Providing Retail Solutions Across All Channels: From Retailers, E-commerce, Brands, Marketplaces, D2C to Omni-Channel Sellers & Beyond</p>
                                        </div>
                                        <div className="info_area">
                                            <div className="image-text-container d-flex gap-3">
                                                <img src={brio} alt="" />
                                                <span className="d-flex align-items-center fw-bold text-center">for Retail Industry</span>
                                            </div>
                                            <Link to="/retail">Visit the Future of Retail</Link>
                                        </div>
                                    
                                    </div>
                                </Link>
                            </div>
                            {/* col-md-6 end */}
                            <div className="col-md-6">
                                 <Link to="/travel">
                                    <div className="card">
                                        
                                        <div className="bg_card bg_card_travel" >
                                            <p>Elevate your Passenger Experience: Dynamic Pricing Solutions </p>
                                        </div>
                                        <div className="info_area travel_area long-text">
                                            <div className="image-text-container d-flex gap-3">
                                                <img src={viaje} alt="" />
                                                <span className="d-flex align-items-center fw-bold text-center">for Intercity Bus Coaches</span>
                                            </div>

                                            <Link to="/travel">Future of Travel is here</Link>
                                        </div>
                                
                                    </div>
                                </Link>
                            </div>
                            {/* col-md-6 end */}
                        </div>
                    </div>
                </section>

                <CapteraAchievements props={"home_captera"} />
                <section className="black-bg founder_video_home">
                    <div className='founderVideo' data-aos="new-animation">

                        <div className="container">
                            <p className="bigHead video_header text-center">Hear From Our Founder: Dr. Anshu Jalora</p>
                            <p className="video_desc text-center">Dr. Anshu Jalora (founder & MD of Sciative Solutions) talks about what sets Sciative apart in the market, the scientific rigor and research that went behind our automated pricing tool.</p>
                            <div className="text-center">
                                <iframe src="https://www.youtube.com/embed/VgWF2N2E6Gg?si=oJZN7LGzc06XVyw1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>

                        </div>
                    </div>
                </section>
            
                {
                    caseStudies.length > 0 &&
                    <section className="case_study">
                        <div className="container" data-aos="new-animation">
                            <h2 className="bigHead">The Proof Is in the Results: Explore Sciative's Client Success Stories</h2>
                            <div className="row">
                                <div className="col-md-12 cs_1">
                                    <div className="card">
                                        {/* <div className="card_bg" style={{ backgroundImage: ` linear-gradient(rgb(0 0 0 / 65%), rgb(0 0 0 / 83%)), url(${caseStudies[0]['templateType'] === 'pdf' ? caseStudies[0]['image_key'] : caseStudies[0]['images']['img-main-img']['url']})` }}> */}
                                        <div className="card_bg" style={{ backgroundImage: ` linear-gradient(rgb(0 0 0 / 65%), rgb(0 0 0 / 83%)), url(${casebg})` }}>
                                        
                                            <div className="card_date">
                                                <p className="date">
                                                    {caseStudies[0]['inputs']['published']}
                                                </p>
                                                <div className="card_2">
                                                    {
                                                        caseStudies[0]['templateType'] === 'pdf' ?
                                                            <a onClick={() => handlePdfRequest(caseStudies[0]['file_key'])} className="case_study_head bigHead text-white hover-text">
                                                                {caseStudies[0]['inputs']['caseStudyTitle']}
                                                            </a>
                                                            :
                                                            <Link to={`/case-studies/${caseStudies[0]['url']}`}>
                                                                <a className="case_study_head bigHead text-white hover-text">
                                                                    {caseStudies[0]['inputs']['caseStudyTitle']}
                                                                </a>
                                                            </Link>
                                                    }
                                                    <div className="case_det">
                                                        <span class="material-symbols-outlined">book_4</span>
                                                        <span className="read">10 min read</span>
                                                        {
                                                            caseStudies[0]['categories'].map((category, index) => {
                                                                return (
                                                                    <Link key={index} to={`/case-studies/category/${category}`}>
                                                                        <span className="category hover-text">
                                                                            {category}
                                                                        </span>
                                                                    </Link>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            caseStudies[0]['customCategories'].map((category, index) => {
                                                                return (
                                                                    <Link key={index} to={`/case-studies/category/${category}`}>
                                                                        <span className="category">
                                                                            {category}
                                                                        </span>
                                                                    </Link>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>

                                {
                                    caseStudies.length > 1 &&
                                    caseStudies.slice(1, 3).map((caseStudy, index) => {
                                        return (
                                            <div key={index} className="col-md-6 cs_2">
                                                <div className="card">
                                                    <div className="read">15min Read</div>
                                                    <div className="card_cat d-flex gap-2">
                                                        {
                                                            caseStudy['categories'].map((category, index) => {
                                                                return (
                                                                    <Link key={index} to={`/case-studies/category/${category}`} className="cat">{category}</Link>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            caseStudy['customCategories'].map((category, index) => {
                                                                return (
                                                                    <Link key={index} to={`/case-studies/category/${category}`} className="cat">{category}</Link>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    {
                                                        caseStudy['templateType'] === 'pdf' ?
                                                            <div className="_">
                                                                <a onClick={() => handlePdfRequest(caseStudy['file_key'])} className="hover-text">{caseStudy['inputs']['caseStudyTitle']}</a>
                                                            </div>
                                                            :
                                                            <div className="_">
                                                                <Link to={`case-studies/${caseStudy['url']}`}>
                                                                    <a className="hover-text">{caseStudy['inputs']['caseStudyTitle']}</a>
                                                                </Link>
                                                            </div>
                                                    }

                                                    <span className="date">{caseStudy['inputs']['published']}</span>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                {/* col-6 */}
                                <div className="col-md-12 text-center cs_3">
                                    <p>Uncover the Secrets of AI Dynamic Pricing: Download Exclusive Whitepapers & Research.</p>
                                    <Link to={AllRoutes.casestudies}>
                                        <Button className="yellow-button">Explore Sciative Insights<span class="material-symbols-outlined">chevron_right</span></Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </section>
                }

                <Recoginition page_name="home_recog" page_heading="Awards & Recognitions" />

                {/* <section className="abt_people home_people">
                    <div className="container">
                        <h2 className="bigHead text-center mb-5 ">Meet Our Research Team</h2>

                    </div>
                    <div className="gal_1">


                        <Slider {...gallery_1}>
                            <div className="img_people">

                                <img src={gallery1} alt="" />
                            </div>
                            <div className="img_people">

                                <img src={gallery2} alt="" />
                            </div>
                            <div className="img_people">

                                <img src={gallery3} alt="" />
                            </div>
                            <div className="img_people">

                                <img src={gallery4} alt="" />
                            </div>
                            <div className="img_people">

                                <img src={gallery5} alt="" />
                            </div>
                            <div className="img_people">

                                <img src={gallery6} alt="" />
                            </div>
                        </Slider>
                    </div>

                </section> */}
                
                <section className="explore_sci">
                    <div className="container" data-aos="new-animation">
                        <div className="row">
                            <div className="col-md-10">
                                <h2 className="bigHead">
                                Let's Talk Growth: Get a Free Consultation & See How Sciative Can Benefit You.
                                </h2>
                            </div>
                            <div className="col-md-2 text-md-end">
                                <Link to='/contact'>
                                    <button className="black-button">Explore Sciative</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="investor-result home-result">
                    <div className="container" data-aos="new-animation">
                        <h2 className="bigHead text-center mb-5">Stretching the Limits of Scale & Speed</h2>
                        <div className="row">
                            <div className="col-md-4 num_border">
                                <div className="num_div">
                                    <p className="number">
                                        +5 Billion
                                    </p>
                                    {/* <p className="num_desc">
                  Repeat Purchase Growth

                  </p> */}
                                </div>
                                <p className="num_desc_2">
                                    Price optimizations daily at lightning speed.
                                </p>
                            </div>
                            {/* col-4 end */}
                            <div className="col-md-4 num_border">
                                <div className="num_div">
                                    <p className="number">
                                        1.5 Billion
                                    </p>
                                    {/* <p className="num_desc">
                  Forecast Accuracy

                  </p> */}
                                </div>
                                <p className="num_desc_2">
                                    Products priced right
                                </p>
                            </div>
                            {/* col-4 end */}
                            <div className="col-md-4 num_border">
                                <div className="num_div">
                                    <p className="number">
                                        1800
                                    </p>
                                    {/* <p className="num_desc">
                  Topline Growth

                  </p> */}
                                </div>
                                <p className="num_desc_2">
                                    Categories served across industries
                                </p>
                            </div>
                            {/* col-4 end */}
                        </div>
                        <div className="row inv_res_2">
                            <div className="col-md-4 num_border">
                                <div className="num_div">
                                    <p className="number">
                                        10 Second
                                    </p>
                                    {/* <p className="num_desc">
                  Topline Growth

                  </p> */}
                                </div>
                                <p className="num_desc_2">
                                    Real-time refresh rate
                                </p>
                            </div>
                            {/* col-4 end */}
                            <div className="col-md-4 num_border">
                                <div className="num_div">
                                    <p className="number">
                                        65 Million
                                    </p>
                                    {/* <p className="num_desc">
                  Topline Growth

                  </p> */}
                                </div>
                                <p className="num_desc_2">
                                    Lives touched with right pricing
                                </p>
                            </div>
                            {/* col-4 end */}
                            <div className="col-md-4 num_border">
                                <div className="num_div">
                                    <p className="number">
                                        $19 Billion
                                    </p>
                                    {/* <p className="num_desc">
                  Topline Growth

                  </p> */}
                                </div>
                                <p className="num_desc_2">
                                    Worth business priced by Sciative's AI solutions
                                </p>
                            </div>
                            {/* col-4 end */}
                        </div>
                    </div>
                </section>
                <WhatMakesSciativeUnique />
                <section className="integration_home">
                    <div className="container" data-aos="new-animation">
                        <h2 className="bigHead text-center">
                            Our Integrations
                        </h2>
                        <div className="row int_1 d-none d-xl-flex">
                            <div className="col-6 col-md">
                                <div className="card">
                                    <img src={int1} alt="BITLA logo" />
                                </div>
                            </div>
                            {/* col-6  */}
                            <div className="col-6 col-md">
                                <div className="card">
                                    <img src={int2} alt="Amazon logo" />
                                </div>
                            </div>
                            {/* col-6  */}
                            <div className="col-6 col-md">
                                <div className="card">
                                    <img src={int3} alt="MavenTech logo" />
                                </div>
                            </div>
                            {/* col-6  */}
                            <div className="col-6 col-md">
                                <div className="card">
                                    <img src={int4} alt="Flipkart logo" />
                                </div>
                            </div>
                            {/* col-6  */}

                            <div className="col-6 col-md">
                                <div className="card">
                                    <img src={int5} alt="Abhibus logo" />
                                </div>
                            </div>
                            {/* col-6  */}


                        </div>
                        <div className="row  int_2 d-none d-xl-flex">

                            <div className="col-6 col-md-2">
                                <div className="card">
                                    <img src={int6} alt="Shopfiy logo" />
                                </div>
                            </div>
                            {/* col-6  */}
                            <div className="col-6 col-md-2">
                                <div className="card">
                                    <img src={int7} alt="Yolobus logo" />
                                </div>
                            </div>
                            {/* col-6  */}
                            <div className="col-6 col-md-2">
                                <div className="card">
                                    <img src={int8} alt="Infinity logo" />
                                </div>
                            </div>
                            {/* col-6  */}
                            <div className="col-6 col-md-2">
                                <div className="card">
                                    <img src={int9} alt="Quikbus logo" />
                                </div>
                            </div>
                            {/* col-6  */}
                        </div>
                        <div className="row int_2 d-xl-none">
                            <div className="col-6 col-md-2">
                                <div className="card">
                                    <img src={int1} alt="Bitla logo" />
                                </div>
                            </div>
                            {/* col-6  */}
                            <div className="col-6 col-md-2">
                                <div className="card">
                                    <img src={int2} alt="Amazon logo" />
                                </div>
                            </div>
                            {/* col-6  */}
                            <div className="col-6 col-md-2">
                                <div className="card">
                                    <img src={int3} alt="Maventech logo" />
                                </div>
                            </div>
                            {/* col-6  */}
                            <div className="col-6 col-md-2">
                                <div className="card">
                                    <img src={int4} alt="Flipkart logo" />
                                </div>
                            </div>
                            {/* col-6  */}

                            <div className="col-6 col-md-2">
                                <div className="card">
                                    <img src={int5} alt="Abhibus logo" />
                                </div>
                            </div>
                            {/* col-6  */}
                            <div className="col-6 col-md-2">
                                <div className="card">
                                    <img src={int6} alt="Shopify logo" />
                                </div>
                            </div>
                            {/* col-6  */}
                            <div className="col-6 col-md-2">
                                <div className="card">
                                    <img src={int7} alt="Yolobus logo" />
                                </div>
                            </div>
                            {/* col-6  */}
                            <div className="col-6 col-md-2">
                                <div className="card">
                                    <img src={int8} alt="Infinity logo" />
                                </div>
                            </div>
                            {/* col-6  */}
                            <div className="col-6 col-md-2">
                                <div className="card">
                                    <img src={int9} alt="Qwikbus logo" />
                                </div>
                            </div>
                            {/* col-6  */}

                        </div>
                    </div>
                </section>
                {/* <RetailTestimonial /> */}
                <ClientTestimonial />
                <section className="form-section" >
                    <div className="container" data-aos="new-animation">
                        <div className="text-container">
                            <h2 className="bigHead mb-5 " >Curious to Know More About Us!</h2>
                            <p>Explore more about how our pricing experts  can scale your revenue to soaring heights just by pricing right.</p>
                        </div>

                        <div className="row">
                            <div className="col-md-6 offset-md-3">

                            <Form {...formProps} />
                            </div>
                        </div>
                    </div>
                </section>


            </HelmetProvider>
            <Modal
                show={pdfRequestModal}
                onHide={() => setPdfRequestModal(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                contentClassName="remove-white-bg"
                backdrop="static"
                centered
            >
                <Modal.Body className="p-0">
                    <CloseIcon onClick={() => setPdfRequestModal(false)} className='modal-close-icon' />
                    <Form {...pdfRequestProps} />
                </Modal.Body>
            </Modal>
        </>
    );
}


export default Home;